import React, {useEffect} from 'react';

import Banner from '../components/About/Banner';
import ContactForm from '../components/ContactForm/ContactForm';
import AboutPrimary from '../components/About/AboutPrimary';
import AboutSecondary from '../components/About/AboutSecondary';
import {HeadlineP} from "../components/Fonts.Style";
import {useTranslation} from "react-i18next";
import useWindowSize from "../hooks/useWindowSize";

const About = () => {
    const {t} = useTranslation();
    useEffect(() => window.scrollTo(0, 0), []);
    const screenSize = useWindowSize().width;

    return (
        <div>
            {screenSize > 500 &&
                <HeadlineP style={{marginTop: screenSize > 500 ? "11rem" : "7rem"}}>
                    {t("about_us_headline")}
                </HeadlineP>
            }
            <AboutPrimary />
            <Banner />
            <AboutSecondary />
            <ContactForm />
        </div>
    );
};

export default About;
