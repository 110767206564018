import React, {useState} from "react";
import styled from "styled-components";
import ButtonFormSubmit from "../Buttons/ButtonFormSubmit";
import { inputsData, inputsEmailData } from "../../data/formData";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import * as yup from "yup";
import {useFormik} from "formik";
import useDirection from "../../hooks/useDirection";
const FormStyled = styled.section`
  display: flex;
  justify-content: flex-end;
  width: ${(props) => (props.page === "position" ? "50%" : "35%")};

  .message {
    color: var(--purple1);
    font-size: 1.125rem;
    width: 100%;
    text-align: center;
  }
  .form-wrap {
    width: 100%;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  input,
  textarea {
    padding: 8px 24px;
    width: ${(props) => (props.page === "position" ? "38.5rem" : "28.125rem")};
    height: 54px;
    margin-bottom: 20px;
    border: 1.5px solid var(--purple1);
    border-radius: 8px;
    font-weight: unset;
    font-size: 1.125rem;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: var(--purple1);
    outline-color: var(--purple1);
    width: 100%;
    font-family: "bar", sans-serif;
  }

  input:nth-last-child(2) {
    padding-bottom: 70px;
  }

  textarea {
    height: 90px;
    resize: none;
  }

  input:nth-last-child(3) {
    display: ${(props) => props.page === "home" && "none"};
  }

  input:focus,
  textarea:focus {
    outline: 2px solid;
  }
  
  button {
    margin-left: ${({isRtl}) => isRtl ? 'auto' : '0'};
    margin-right: ${({isRtl}) => isRtl ? '0' : 'auto'};
  }

  @media (max-width: 960px) {
    width: 100%;
    margin-top: 20px;
    align-items: center;
    flex-direction: column;

    input,
    textarea {
      padding: 0 15px;
    }
  }
`;

const Form = ({ page }) => {
  const [showSentMessage, setShowSentMessage] = useState(false);
  const {isRtl} = useDirection();
  /**method="POST"
          action="https://formsubmit.co/6fe46927859dc0d5740febd8287039a8" */
  const mailer = (formData) => {
    formData.preventDefault();
    emailjs
      .sendForm(
        "service_84or5v6",
        "template_i15jv29",
        formData.target,
        "ONBBsB7eih4KGtI5f"
      )
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((someErr) => console.error(someErr));
  };
  const { t } = useTranslation();

  const contactSchema =  yup.object({
    email: yup
        .string()
        .required(),
    phone: yup
        .string()
        .required(),
    name: yup
        .string()
        .required(),
  })

  const handleSubmit = async (values = null) => {
    try {
      const response = await fetch('https://hook.eu1.make.com/6ihp6nlk5jxhfgtyo8kyye9gfjd267wc', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      if (!response.ok) {
        console.log(`Error sending webhook, response not ok1`, response);
        return;
      }
      setShowSentMessage(true);
      ContactFormik.resetForm();
      console.log("Form submited", values, response)
    } catch (error) {
      console.log(`Error sending webhook: ${error.message}`);
    }
  }

  const ContactFormik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
    },
    validationSchema: contactSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    }
  });

  return (
    <FormStyled page={page} isRtl={isRtl}>
      <div
        className={
          page === "position"
            ? "form-wrap flex justify-end"
            : "form-wrap flex justify-center"
        }
      >
        <form onSubmit={ContactFormik.handleSubmit} name={"ContactUsProductive"} id={"ContactUsProductive"} className={"ContactUsProductive contact-form"}>
          {/*{inputsEmailData.map((input, indx) => (*/}
          {/*  <input*/}
          {/*    type="hidden"*/}
          {/*    name={input.name}*/}
          {/*    value={input.value}*/}
          {/*    key={indx}*/}
          {/*  />*/}
          {/*))}*/}

          {inputsData.map((input, indx) => (
            <div key={indx}>
              {/*{indx === inputsData.length - 1 ? (*/}
              {/*  <textarea*/}
              {/*    placeholder={t(`placeholders.${input.name}`)}*/}
              {/*    name={input.name}*/}
              {/*    value={ContactFormik.values[input.name]}*/}
              {/*    onChange={ContactFormik.handleChange}*/}
              {/*    onBlur={ContactFormik.handleBlur}*/}
              {/*    key={indx}*/}
              {/*    required*/}
              {/*  />*/}
              {/*) : (*/}
              {/*  <input*/}
              {/*    type={input.type}*/}
              {/*    placeholder={t(`placeholders.${input.name}`)}*/}
              {/*    name={input.name}*/}
              {/*    value={ContactFormik.values[input.name]}*/}
              {/*    onChange={ContactFormik.handleChange}*/}
              {/*    onBlur={ContactFormik.handleBlur}*/}
              {/*    required*/}
              {/*  />*/}
              {/*)}*/}
              <input
                  type={input.type}
                  placeholder={t(`placeholders.${input.name}`)}
                  name={input.name}
                  value={ContactFormik.values[input.name]}
                  onChange={ContactFormik.handleChange}
                  onBlur={ContactFormik.handleBlur}
                  required
              />
            </div>
          ))}
          <ButtonFormSubmit page={page} />
          {showSentMessage && (
              <p className={"message"}>Thanks for your request, we will contact you soon</p>
          )}
        </form>
      </div>
    </FormStyled>
  );
};

export default Form;
