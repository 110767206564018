import React from 'react';
import styled from "styled-components";

import CareersInfo from './CareersInfo';
import CardsList from '../Cards/CardsList';
import { careersCards } from '../../data/careersCards';
import {HeadlineP, HeadlineH1} from '../Fonts.Style';
import {useTranslation} from "react-i18next";
import useWindowSize from "../../hooks/useWindowSize";

const CareersStyled = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 9rem auto 8rem;
  .subheading {
    font-weight: 900;
    font-size: 20px;
    margin-bottom: 24px;
    padding: 0 30px;
  }
    .heading {
      width: 50%;
      min-width: 700px;
      margin: 0 auto 70px;
    }
    .info-container {
      width: 100%;
      //max-width: 1400px;
      padding: 0 120px;
      margin: 0 auto;
    }

    @media screen and (max-width: 960px) {
      .info-container {
        padding: 0 30px;
      }
      .heading {
        width: 100%;
        min-width: unset;
        margin: 0;
        font-size: 48px;
        color: #9146FF;
        text-align: left;
        padding: 30px;
      }
      .info-container {
        flex-direction: column;
      }
      .icon-wrap {
        margin: 0 0 10px;
      }
    }
  
    @media screen and (max-width: 500px) {
      margin: 5rem auto 4rem;
      h1.heading {
        font-size: 28px;
        padding-top: 15px;
      }
    }
`;


const Careers = () => {

    const {i18n, t} = useTranslation();
    const screenSize = useWindowSize().width;
    const renderCards = careersCards[i18n.language.substring(0,2)] || careersCards.en;

    return (
        <CareersStyled>
            {screenSize > 960 && <HeadlineP style={{margin:'50px auto 70px'}}>{t("careers.title")}</HeadlineP>}
            {screenSize < 960 &&<HeadlineH1 comp="careers" className={"heading"}>{t("careers.title")}</HeadlineH1>}
            {screenSize < 960 && <p className="subheading">{t("careers.heading")}</p>}
            <section className="flex column info-container">
                <CareersInfo />
                <CardsList cards={renderCards} page={'careers'}/>
            </section>
        </CareersStyled>
    )
}

export default Careers
