import win from '../assets/img/careers/win.svg';
import php from '../assets/img/careers/php.svg';
import aws from '../assets/img/careers/aws.svg';
import java from '../assets/img/careers/java.svg';
import react from '../assets/img/careers/React.svg';
import apple from '../assets/img/careers/apple.svg';
import android from '../assets/img/careers/Android.svg';
import wordpress from '../assets/img/careers/wordpress.svg';


export const technologies = [
    {
        img: apple,
        title: 'iOS',
        description:''
    },
    {
        img: aws,
        title: 'Aws cloud',
        description:''
    },
    {
        img: java,
        title: 'Java',
        description:''
    },
    {
        img: android,
        title: 'Android',
        description:''
    },
    {
        img: react,
        title: 'React',
        description:''
    },
    {
        img: php,
        title: 'Php',
        description:''
    },
    {
        img: win,
        title: 'Windows',
        description:''
    },
    {
        img: wordpress,
        title: 'Wordpress',
        description:''
    },

];