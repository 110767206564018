import React from 'react';
import styled from "styled-components";

import ButtonMain from '../Buttons/ButtonMain';
import { GreyHeadline } from '../Fonts.Style';
import {useTranslation} from "react-i18next";
import useWindowSize from "../../hooks/useWindowSize";
import TheTeamMobile from "../MeetTheTeam/TheTeamMobile";
import TeamCarousel from "./TeamCarousel";

const TeamCareersStyled = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 96px;
  .mob-wrap {
    margin: 0;
    overflow: hidden;
    padding: 4rem 0;
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 40px;
  }
  
`;

const TeamCareers = () => {

    const {t} = useTranslation();
    const screenSize = useWindowSize().width;

    return (
        <TeamCareersStyled>
            <GreyHeadline style={{ marginBottom: screenSize > 500 ? '3.125rem' : '0' }}>{t("meet_the_team")}</GreyHeadline>
            {screenSize > 500 ? <TeamCarousel/> : <div className="mob-wrap"><TheTeamMobile /></div>}
            {screenSize > 500 &&
                <ButtonMain
                    title={t("btn.see_full_team")}
                    fontSize="15px"
                    width="228px"
                    height="52px"
                    notBold={true}
                />}
        </TeamCareersStyled>
    )
}

export default TeamCareers
