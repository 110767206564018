import React from 'react';
import styled from "styled-components";

import { team } from '../../data/TeamData';
import Member from './Member';
import {useTranslation} from "react-i18next";

const TheTeamContainer = styled.section`
    width: 63%;
    max-width: 1220px;
    padding: 0 20px;
    margin: 0 auto 50px;
    height: 450px;
    justify-content: space-between;
    position: relative;

    .team-item{
        width: 140px;
        max-width: 18%;
        text-align: center;
        display: flex;
        flex-direction: column;

        img{
            border-radius: 50%;
            border: 3px solid var(--purple1);
            background-color: var(--purple1);
            margin-bottom: 1rem ;
        }
        h3{
            font-weight: bold;
            font-size: 1.125rem;
            line-height: 120%;
            letter-spacing: -0.02em;
            color: var(--grey1);
            margin-bottom: 5px;
        }
        .p-position, a{
            font-weight: normal;
            font-size: .85rem;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: var(--grey2);
        }
        a{
            color: var(--purple1);
        }
    }
    .team-item:nth-child(3){
        justify-content: center;
        padding-bottom: 10%;
    }
    .team-item:nth-child(even){
        justify-content: end;
    }
  .ball {
    background: #9146FF;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    position: absolute;
    z-index: -1;
    &.one {
      width: 80px;
      left: -11%;
      top: -22%;
    }
    &.two {
      width: 55px;
      left: 14.5%;
      top: 99%;
    }
    &.three {
      width: 55px;
      left: 35.5%;
      top: -7%;
    }
    &.four {
      width: 80px;
      left: 85%;
      top: 89%;
    }
    &.five {
      width: 84px;
      left: 105%;
      top: -19%;
    }
  }
`;

const TheTeam = () => {

    const{i18n} = useTranslation();
    const renderTeam = team[i18n.language.substring(0, 2)] || team.en;

    return (
        <TheTeamContainer className="flex">
            {renderTeam.israel.team.map((teamMember, index) => (
                index < 5 &&
                <Member teamMember={teamMember} key={index}/>
            ))}
            <div className="balls">
                <div className="ball one"/>
                <div className="ball two"/>
                <div className="ball three"/>
                <div className="ball four"/>
                <div className="ball five"/>
            </div>
        </TheTeamContainer>
    )
}

export default TheTeam
