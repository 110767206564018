import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import CardsList from '../Cards/CardsList';
import BuildInfo from './BuildInfo';
import SwitchBtn from './SwitchBtn';
import {buildCards} from '../../data/buildTeamCards';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import teamImg from "../../assets/img/ServicesRight.png";
import productImg from "../../assets/img/servicesLeft.png";


const LetsBuildStyled = styled.main`
    display: flex;
    width: 100%;
    max-width: 1600px;
    padding: 0 120px;
    flex-direction: column;
    align-items: center;
    margin: 8rem auto 176px;
    .info-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    @media screen and (max-width: 960px) {
      padding: 0 30px;
      .info-container {
        flex-direction: column;
      }
    }
    @media screen and (max-width: 500px) {
      margin: 7rem auto 80px;
    }
`;

const HeadlineP = styled.p`
    font-weight: bold;
    font-size: 20px;
    line-height: 67%;
    text-transform: uppercase;
    color: var(--grey00);
    margin-bottom: 2.75rem;
  @media screen and (max-width: 768px) {
    font-weight: 400;
    font-size: 16px;
  }
`;

const LetsBuild = () => {

    const {tab} = useParams();
    const [isTeam, setIsTeam] = useState(tab !== "product");
    const {i18n, t} = useTranslation();
    const renderBuildCards = buildCards[i18n.language.substring(0,2)] || buildCards.en;
    const screenSize = useWindowSize().width;

    useEffect(() => {
        setIsTeam(tab !== "product");
    }, [tab])
    
    return (
        <LetsBuildStyled>
            <HeadlineP>{t("lets_build")}</HeadlineP>
            <SwitchBtn isTeam={isTeam}/>
            <section className="flex info-container">
                <BuildInfo isTeam={isTeam}/>
                <CardsList cards={isTeam ? renderBuildCards.team : renderBuildCards.products} page={"services"}/>
                {screenSize < 960 && <img src={isTeam ? teamImg : productImg} alt="" />}
            </section>
        </LetsBuildStyled>
    );
};

export default LetsBuild;
