import React from 'react';
import styled from "styled-components";
import ServiceIcon from "./ServiceIcon";


const TopWrapper = styled.section`
  margin-bottom: 4rem;
  width: 100%;
  height: 75px;
  position: relative;

  .arc {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      aspect-ratio: 386 / 233;
      bottom: 0;
      border: 4px solid #9146FF;
      border-radius: 100%;
    }
  }

  &.left {
    .arc:before {
      animation: .5s linear 0s normal down;
    }
  }
  
  &.right .icon {
    animation: .5s linear 0s normal icon;
  }

  @keyframes down {
    0% {
      bottom: 200%;
    }
    100% {
      bottom: 0;
    }
  }
  @keyframes icon {
    0% {
      left: -50%;
      top: -50%;
    }
    100% {
      left: 50%;
      top: 100%;
    }
  }
`;

const TopMobile = ({icon, side}) => {
    return (
        <TopWrapper className={side}>
            <div className="arc"></div>
            <ServiceIcon icon={icon} side={"top"}></ServiceIcon>
        </TopWrapper>
    )
}

export default TopMobile
