import React from 'react';
import styled from 'styled-components/macro';

import {footerData} from '../../data/footerData';
import {Link} from "react-router-dom";
import facebook from "../../assets/img/facebook.svg";
import instagram from "../../assets/img/instagram.svg";
import linkedin from "../../assets/img/linkedin.svg";
import ButtonMain from "../Buttons/ButtonMain";
import {useTranslation} from "react-i18next";

const FooterContentStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .top {
    margin: 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .nav {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    > * {
      margin: 12px 0;
      font-size: 18px;
      text-decoration: none;
    }
  }
  
  .social-icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
      margin: 12px 0;
      width: 24px;
      height: 24px;
    }
  }
  
  .bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    .contacts {
      font-weight: bold;
      font-size: 16px;
      line-height: 2;
      color: #BBBBBB;
    }
    .privacy {
      margin-top: 48px;
      a {
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #A9A9A9;
        margin: 0 20px;
        text-align: center;
      }
    }
  }
  
  .row {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
  }
  
  .center {
    justify-content: center;
  }
  
  .divider {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 0 16px;
    background: #BBBBBB;
  }
  .rights {
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    color: #7A3BD6;
    margin: 50px auto;
    text-align: center;
  }
`;

const FooterMobileContent = () => {

    const {i18n, t} = useTranslation();
    const renderFooterData = footerData[i18n.language.substring(0,2)] || footerData.en;

    return (
        <FooterContentStyled>
            <div className="top">
                <div className="nav">
                    <Link   to="/about">
                        <li>{renderFooterData.content[0].content[0]}</li>
                    </Link>
                    <Link   to="/clients">
                        <li>{renderFooterData.content[0].content[3]}</li>
                    </Link>
                    <Link   to="/portfolio">
                        <li>{renderFooterData.content[0].content[4]}</li>
                    </Link>
                    <Link   to="/services">
                        {renderFooterData.content[1].content[2]}
                    </Link>
                    <a href="https://p.productive.co.il/blog" target="_blank" rel="noreferrer">
                        {renderFooterData.content[0].content[6]}
                    </a>
                </div>
                <div className="social-icons">
                    <a href={renderFooterData.contactDetails.facebook}><img src={facebook} alt=""/></a>
                    <a href={renderFooterData.contactDetails.instagram}><img src={instagram} alt=""/></a>
                    <a href={renderFooterData.contactDetails.linkedin}><img src={linkedin} alt=""/></a>
                </div>
            </div>
            <div className="bottom">
                <div className="row contacts">
                    <a href={`tel:${renderFooterData.contactDetails.phoneNumber}`}>{renderFooterData.contactDetails.phoneNumber}</a>
                    <span className="divider"></span>
                    <a href={`mailto:${renderFooterData.contactDetails.email}`}>{renderFooterData.contactDetails.email}</a>
                </div>
                <div className="row contacts">
                    <p>{renderFooterData.contactDetails.address}</p>
                </div>
                <div className="row center privacy">
                    <a href={renderFooterData.contactDetails.terms}>{renderFooterData.content[2].content[2]}</a>
                    <a href={renderFooterData.contactDetails.privacy}>{renderFooterData.content[2].content[3]}</a>
                </div>
                {/*<Link   to="/positions" className="flex center">
                    <ButtonMain title={t("btn.see_openings")} height={'64px'} marginTop={'24px'}/>
                </Link>*/}
                <p className="rights">All rights reserved to productive</p>
            </div>
        </FooterContentStyled>
    );
};

export default FooterMobileContent;
