import { useState, useEffect } from "react";
import {useTranslation} from "react-i18next";

function useDirection() {
    const [isRtl, setIsRtl] = useState(false);
    const {i18n} = useTranslation();
    useEffect(() => {
        setIsRtl(i18n.dir() === "rtl");
        document.dir = i18n.dir();
    }, [i18n.language]);
    return {isRtl};
}

export default useDirection;
