import Monday from "../../assets/img/InformationSystem/sub/Monday/img.svg";
import Salesforce from "../../assets/img/InformationSystem/sub/Saleforce/img.svg";
import TopAppServices from "../../assets/img/InformationSystem/top_apps_services.svg";
import Data from "../../assets/img/InformationSystem/sections/aboutus/data.svg";

export const ru = {
    translation: {
        "hero_title": {
            0: "Мы строим цифровые <highlight>решения</highlight> для последующих <highlight>вызовов</highlight> вашего бизнеса",
            1: "Мы <highlight>масшатабируем</highlight> вашу команду для ваших будующих <highlight>проектов</highlight>",
            2: "Мы верим в <highlight>продуктивную </highlight> работу иновационных <highlight>продуктов</highlight>",
        },
        "our_work": "Наши работы",
        "our_process": "Процесс",
        "about_us_headline": "О нас",
        "meet_the_team": "Наша команда",
        "testimonials": "Отзывы",
        "team_slogan": "Люди, благодаря которым все происходит",
        "team_part_slogan": "Хотите стать частью команды?",
        "technologies": "Технологии",
        "benefits": "Преимущества",
        "our_offices": "Наши офисы",
        "lets_build": "Давайте создадим",
        "description": "Описание",
        "our_friends": "Наши друзья",
        "scroll_more": "проскролль чтобы увидеть больше",
        "see_more": "увидеть больше",
        "hire_heading": "Нанять",
        "hire": {
            "heading": "Нанять",
            "headingSpan": "Разработчиков",
            "subHeading": "Наймите эксперта, удаленного разработчика быстро",
            "conditions1": "Заполняя эту форму вы соглашаетесь с",
            "conditions2": "Условиями использования, Политикой конфиденциальности и использования файлов cookie",
            "takes_time": "Занимает 4 минуты",
            "error": "Пожалуйста сделайте выбор",
            "doneHeading": "Спасибо!",
            "doneSubHeading": "Мы скоро с вами свяжемся",
            "doneText": "Мы с нетерпением ждем возможности подобрать для вас специалистов, которые нужны вашей компании.",
            "multiple": "Выберите все верные варианты",
            "stepNames": {
                1: "Сколько человек сейчас работает в вашей компании?",
                2: "К какой отрасли относится ваша компания?",
                3: "Какие члены специалисты вам нужны?",
                4: "С какими технологиями работает ваша компания?",
                5: "Ваш бюджет найма этого сотрудника команды превышает 3000 долларов США?",
                6: "Как скоро вам нужен специалист команды?",
                7: "Как на долго вам нужен специалист",
                8: "Какая степень занятости потребуется от сотрудника?",
                9: "Вам удобно работать с кем-то удаленно?",
                10: "Ваши контактные данные",
            },
            "placeholders": {
                "firstName": "Имя",
                "lastName": "Фамилия",
                "email": "Электронная почта",
                "mobile": "Телефон",
            }
        },
        "progress": {
            1: "Добро пожаловать",
            2: "Услуги",
            3: "Портфолио",
            4: "Процесс",
            5: "Отзывы",
            6: "Команда",
            7: "Контакты"
        },
        "nav": {
            "get_in_touch": "Свяжись с нами",
            "build": "Сервисы",
            "services": "Услуги",
            "portfolio": "Портфолио",
            "team": "Команда",
            "careers": "Карьера",
            "about": "О нас",
            "friends": "Друзья",
            "news": "Новости",
            "crm": "CRM"
        },
        "btn": {
            "read_more": "Подробнее",
            "learn_more": "Узнать больше",
            "read_article": "Прочитать статью",
            "read_less": "Скрыть",
            "see_portfolio": "Открыть портфолио",
            "visit_site": "Посетить сайт",
            "see_full_team": "Увидеть всю команду",
            "get_free_quote": "Получить бесплатную консультацию",
            "send": "Отправить",
            "see_openings": "Смотреть вакансии",
            "see_careers": "Смотреть вакансии",
            "project_in_progress": "Проект в разработке",
            "more_info": "Больше информации",
            "see-full-customers": "Увидеть всех клиентов",
            "contact-expert": "Связаться со специалистом",
            "get_started": "Начать",
            "home": "На главную"
        },
        "build": {
            "title": "Наши услуги",
            "product": {
                "title": "Создание продукта",
                "description":
                    "<p>Мы создаем программные продукты от А до Я.</p>" +
                    "<p>Мы используем самые передовые методологии управления проектами, такие как Agile и Scrum.</p>" +
                    "<p>В процессе мы отслеживаем каждый сбой и проблему.</p>",
                "mobileDescription":
                    "<p>Мы создаем программные продукты от А до Я. Мы используем самые передовые методологии управления проектами, такие как Agile и Scrum. В процессе мы отслеживаем каждый сбой и проблему, следим за ключевыми моментами, и проводим постоянную оптимизацию.</p>" +
                    "<p>Наши рабочие процессы позволяют нам быстро выходить на рынок - по сравнению с традиционным процессом разработки. В каждом проекте, который мы берем, для нас важно соблюдать сроки и цели - и доводить каждый продукт до конечной точки.</p>" +
                    "<p><title>Разработка приложений</title> Все необходимое для проекта под одной крышей: планирование, проектирование, разработка программного обеспечения, ТЭО и выход на рынок. Мы разработали широкий спектр программных продуктов: комплексные сайты, CRM-системы, приложения, системы управления и информации. Попробуйте сотрудничество с нами.</p>" +
                    "<p><title>Разработка MVP/POC</title> Аутсорсинговая разработка позволяет обойти барьеры для инноваций, которые существуют в крупных организациях. Мы разработаем первоначальную концепцию и изучим осуществимость. Далее мы передадим вам эстафету, чтобы вывести предприятие на новый уровень.</p>",
                "servicesTitle": "Хотите развивать свой продукт и масштабироваться?",
                "servicesDescription": "Мы создаем программные продукты от А до Я. Мы используем самые передовые методологии управления проектами, такие как Agile и Scrum. В процессе мы отслеживаем каждый сбой и проблему, следим за ключевыми моментами, и проводим постоянную оптимизацию."
            },
            "team": {
                "title": "Создание команды",
                "description":
                    "<p><title>Нужен программист на вчера?</title>Мы можем это устроить.</p>" +
                    "<p>Программисты на аутсорсинге — отличное решение для стартапов или малого бизнеса, которым быстро нужна качественная рабочая сила.</p>",
                "mobileDescription":
                    "<p><title>Нужен программист на вчера?</title>Мы можем это устроить. Программисты на аутсорсинге — отличное решение для стартапов или малого бизнеса, которым быстро нужны опытные разработчики.</p>" +
                    "<p>Мы предоставляем максимальную гибкость: набираем и обучаем программиста для вашей команды в среднем за 7-14 дней, в зависимости ваших потребностей.</p>" +
                    "<p>Работая с нами, вы получаете уверенность и стабильность, которых нет на таких платформах, как Upwork и Toptal. Наши программисты являются наемными работниками. Они приходят к нам в офис каждый день, получают оборудование, социальные выплаты, профессиональные консультации (и даже занятия йогой). В условиях современного конкурентного рынка мы знаем, как удерживать таланты на долгие годы. Большинство наших клиентов работают с одними и теми же программистами по 3-4 года. Они становятся неотъемлемой частью своей команды.</p>" +
                    "<p>Есть два способа нанять разработчиков-аутсорсеров:</p>" +
                    "<p>1. Под нашим профессиональным управлением - с менеджером проекта.</p>" +
                    "<p>2. Под вашим профессиональным руководством - работа напрямую с программистом.</p>",
                "servicesTitle": "Хотите расширить свою команду и масштабироваться?",
                "servicesDescription": "Нужна гибкая команда? Вам нужно поговорить с нами! Мы будем рады обсудить с вами ваши потребности и помочь вам создать команду лучших специалистов, чтобы помочь вашему бизнесу раскрыть свой потенциал."
            }
        },
        "services": {
            "team": "Команду",
            "product": "Продукт"
        },
        "about_us": {
            "title": "О нас",
            "main_text": "За прошедшие годы мы создали мощную инфраструктуру разработки, охватывающую различные области, чтобы обеспечивать комплексные решения по программному обеспечению с различными требованиями. В настоящее время компания оказывает услуги аутсорсинга различным предприятиям, а также разрабатывает собственные продукты.",
            "banner": {
                "title": "<h1>40+ <br> Компаний</h1>",
                "items": {
                    "item0": "Удобство, современность и интуитивность",
                    "item1": "Новые функции, модный дизайн",
                    "item2": "Полноэтапная разработка на высоком уровне",
                    "item3": "Круглосуточная служба поддержки клиентов",
                }
            },
            "secondary_text": "Нужна гибкая команда? Вам нужно поговорить с нами! Мы будем рады обсудить с вами ваши потребности и помочь вам создать команду лучших специалистов, чтобы помочь вашему бизнесу раскрыть свой потенциал."
        },
        "contact_form": {
            "headline": "<div>мы <span>здесь </span>для вас и мы готовы<span> работать </span> с вами<p/>",
            "mob_headline": "Свяжись с нами!",
            "discuss_needs": "Давайте обсудим ваши потребности!",
            "productive_together": "Давайте продуктивно работать вместе!",
        },
        "placeholders": {
            "name": "Полное имя",
            "phone": "Номер телефона",
            "email": "Электронная почта",
            "message": "Сообщение",
            "Link": "Ссылка на портфолио"
        },
        "careers": {
            "title": "Карьера",
            "heading": "Мы — ступенька к следующему уровню вашей карьеры.",
            "main_text": "Мы ищем тех, кто хочет добиться большого прорыва в своей карьере, влиять на то, что они делают, стремящихся к саморазвитию и изучению новых технологий. Тех, кто хочет работать в успешной международной команде и умеет решать проблемы и выдерживать давление."
        },
        "positions": {
            "heading": "Открытые вакансии",
            "see_position": "Просмоотреть вакансию",
            "position_description": "Описание вакансии",
            "requirements": "Требования"
        },
        "friendsCategories" : {
            "brands": "Брэнды и стартапы",
            "startups": "Startups",
            "government": "Правительственные структуры",
            "finance": "Финансы",
            "education": "Образование",
            "ecommerce": "Ecommerce",
            "other": "Другое",
        },
        "blog": {
            "heading": "БЛОГ",
            "title": "Productive Блог",
        },
        "news": {
            "heading": "из прессы",
            "title": "Вот что думает о нас пресса",
        },
        "InformationSystem": {
            "heading": "Информационная система",
            "title": "Внедрение информационной системы в бизнесе",
            "description": "<p>Услуги по внедрению CRM направлены на предоставление предприятиям платформенных или индивидуальных CRM-решений для оптимизации процессов продаж, маркетинга и обслуживания клиентов.</p>" +
                "<p>Обладая 8-летним опытом работы в сфере CRM-услуг, ScienceSoft осуществляет внедрение CRM, чтобы помочь компаниям снизить затраты на привлечение и конверсию потенциальных клиентов, а также затраты на удержание клиентов.</p>",
            "img": TopAppServices,
            "sections": {
                "services": {
                    "heading": "Наши услуги",
                },
                "products": {
                    "heading": "Продукты SalesForce",
                },
                "about-us": {
                    "heading": "О нас",
                    "title": "Мы являемся партнерами в ваших технологических %задачах% и %оптимизации% рабочей среды",
                    "description": "<p>В качестве партнера-консультанта Productive создает уникальную стратегию для Вашей организации, которая позволяет использовать CRM в качестве катализатора для достижения Ваших целей трансформации.</p>" +
                        "<p>Мы знаем технологию, стратегию и то, как это применимо к отраслям. Стремясь к инновациям, Productive заранее привносит технические знания, используя многолетний опыт для разработки, разработки и реализации стратегии Salesforce, которая отвечает потребностям вашей организации сегодня и строит план на будущее.</p>",
                    "img": Data
                },
                "customers": {
                    "heading": "наши клиенты",
                    "subtitle": "Официальный веб-сайт",
                }
            },
        },
        "monday": {
            "heading": "MONDAY.COM",
            "title": "Присоединяйтесь к революции системного управления %Monday.Com%",
            "description": "<p>У нас есть опыт и ресурсы, чтобы помочь Вашей организации получить максимальную отдачу от Ваших инвестиций в monday.com. Наша команда помогла клиентам во всех аспектах использования monday.com, включая адаптацию, ИТ-консультации, обучение и услуги по внедрению проектов.</p>",
            "img": Monday,
        },
        "salesforce": {
            "heading": "Salesforce",
            "title": "Присоединяйтесь к революции системного управления %Salesforce%",
            "description": "<p>Как партнер Salesforce Consulting, Productive создает уникальную стратегию для вашей организации, которая позволяет Salesforce выступать в качестве катализатора для достижения ваших целей трансформации.</p>" +
                "<p>Мы знаем Salesforce — технологию, стратегию и то, как это применимо к отраслям. Стремясь к инновациям, Productive заранее привносит технические знания, используя многолетний опыт для разработки, разработки и реализации стратегии Salesforce, которая отвечает потребностям вашей организации сегодня и строит план на будущее.</p>",
            "img": Salesforce,
        }
    }
}
