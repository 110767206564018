import React, {useEffect} from 'react'
import ContactForm from '../components/ContactForm/ContactForm';
import LetsBuild from '../components/LetsBuild/LetsBuild';

const Build = () => {

    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <main>
            <LetsBuild />
            <ContactForm style={{marginBottom:'1010px'}}/>
        </main>
    )
}

export default Build
