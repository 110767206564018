import React from 'react';
import styled from "styled-components";

import office1 from "../../assets/img/careers/office1.png"
import office2 from "../../assets/img/careers/office2.png"
import offices1 from "../../assets/img/offices1.png"
import offices2 from "../../assets/img/offices2.png"
import offices3 from "../../assets/img/offices3.png"
import useWindowSize from "../../hooks/useWindowSize";

const OfficeGalleryStyled = styled.section`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 72px ;
    align-items: center;
    img {
        border-radius: 14px;
        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.18)) drop-shadow(0px 0px 3.55245px rgba(34, 17, 34, 0.18));
        object-fit: cover;

    }

    .leftImg {
        width: calc((100% - 24px) / 2);

        img{
            width: 100%;
            aspect-ratio: 615 / 396;
        }
    }

    .rightImgsContainer{
        width: calc((100% - 24px) / 2);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        img {
            width: calc((100% - 24px) / 2);
            aspect-ratio: 295 / 182;
            &:nth-child(-n+2) {
              margin-bottom: 32px;  
            }
        }
    }
  
    @media screen and (max-width: 960px) {
      flex-direction: column;
      
      .leftImg {
        width: 100%;
        margin-bottom: 16px;
      }
      .rightImgsContainer{
        width: 100%;
        flex-wrap: nowrap;
        img {
          width: calc((100% - 16px) / 2);
          aspect-ratio: unset;
          margin-bottom: 0 !important;}
        }
      }
    }
`;

const OfficeGallery = () => {

    const screenSize = useWindowSize().width;

    return (
        <OfficeGalleryStyled>
            <div className="leftImg">
                <img src={screenSize > 960 ? office1 : offices1} alt="" />
            </div>
            <div className="rightImgsContainer">
                <img src={screenSize > 960 ? office2 : offices2} alt="" />
                <img src={screenSize > 960 ? office2 : offices3} alt="" />
                {screenSize > 960 && <img src={office2} alt="" />}
                {screenSize > 960 && <img src={office2} alt="" />}
            </div>
        </OfficeGalleryStyled>
    )
}

export default OfficeGallery
