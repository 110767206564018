import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";


const NavListStyled = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 20px ;
    flex-grow: 1;
    li {
        display: flex;
        padding: 0 15px;
        justify-content: center;
        font-weight: normal;
        font-size: 18px;
        line-height: 140%;
        color: var(--black);
    }
`;

const NavList = () => {

    const {t} = useTranslation();

    return (
        <NavListStyled>
            <Link  to="/about">
                <li>{t("nav.about")}</li>
            </Link>
            <Link   to="/clients">
                <li>{t("nav.friends")}</li>
            </Link>
            <a href="https://p.productive.co.il/blog">
                <li>{t("nav.blog")}</li>
            </a>
            <Link   to="/information-system">
                <li>{t("nav.crm")}</li>
            </Link>
        </NavListStyled>
    )
};

export default NavList;
