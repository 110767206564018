import React, {useEffect} from 'react';

import ContactForm from '../components/ContactForm/ContactForm';
import { companies } from '../data/companies';
import {useTranslation} from "react-i18next";
import OurWorkPortfolio from "../components/OurWork/OurWorkPortfolio";
import {HeadlineP} from "../components/Fonts.Style";
import useWindowSize from "../hooks/useWindowSize";

const Portfolio = () => {

    const {i18n, t} = useTranslation();
    const screenSize = useWindowSize().width;
    const renderCompanies = companies[i18n.language.substring(0,2)] || companies.en;

    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <main>
            <HeadlineP style={{
                margin: screenSize > 500 ? '10rem auto 4rem' : '7rem auto 2rem',
                textAlign: 'center'
            }}>
                {t("our_work")}
            </HeadlineP>
            {
                renderCompanies.map((company, i) => (
                    <OurWorkPortfolio company={company} key={i} direction={i % 2 ? 'reverse' : ''}/>
                ))
            }
            <ContactForm />
        </main>
    )
}

export default Portfolio

