import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'

import "./index.css";
import "./services/i18n/init";
import App from './App.js';
import GlobalStyle from './GlobalStyles';
import { store } from './store/store.js'

ReactDOM.render(
  <React.StrictMode>
      <React.Suspense fallback="Loading...">
          <Provider store={store}>
              <GlobalStyle />
              <App />
          </Provider>
      </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
