export default function usePartColor(text, color) {
    const splitter = "%";
    return text.split(splitter).map((s, idx) => {
        if (idx % 2 !== 0) {
            return (
                <span
                    key={idx}
                    style={{
                        color,
                    }}
                >
                    {s}
                </span>
            );
        }
        return s;
    });
}