import React from "react";
import {useTranslation} from "react-i18next";
import {team} from "../../data/TeamData";
import styled from "styled-components";
import Slider from "react-slick";
import Member from "../MeetTheTeam/Member";
import useWindowSize from "../../hooks/useWindowSize";
import useDirection from "../../hooks/useDirection";

const SliderWrap = styled.section`
    height: 350px;
    width: 100%;
    .team-item {
      width: 132px;
      margin: 0 auto;
      transition: all .3s;
      p, a {
        font-size: 14px;
      }
      h3 {
        font-size: 18px;
      }
    }
    .slick-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .slick-track {
      display: flex;
      height: 300px;
    }
    .slick-current .team-item{
      width: 186px;
    }
`

const TeamCarousel = () => {

    const {i18n} = useTranslation();
    const renderTeam = team[i18n.language.substring(0,2)] || team.en;
    const {isRtl} = useDirection();

    const settings = {
        centerMode: true,
        loop: true,
        slidesToShow: 7,
        autoplay: true,
        speed: 500,
        pauseOnHover: false,
        rtl: isRtl,
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 7
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };

    return (
        <SliderWrap>
            <Slider {...settings} >
                {
                    renderTeam.israel.team.map((member, i) => (
                        <Member teamMember={member} key={i}/>
                    ))
                }
            </Slider>
        </SliderWrap>
    )
}

export default TeamCarousel;
