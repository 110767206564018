import React from 'react';
import styled from "styled-components";

import ButtonMain from '../Buttons/ButtonMain';
import processImg from '../../assets/img/processImg.png';
import { TextP, GreyHeadline } from '../Fonts.Style';
import useWindowSize from "../../hooks/useWindowSize";
import {useTranslation} from "react-i18next";


const OurProcessContentStyled = styled.section`
  width: 50% /* 615px -> 38.4375rem */;
  max-width: 615px;
  margin-right: 20px;
  max-height: 100%;
  //align-self: flex-start;
  /* margin-bottom: 50px; */

  h1 {
    font-weight: bold;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: var(--purple1);
    margin-bottom: 1.5rem;
  }

  img {
    margin-top: 2rem;
    margin-left: 1rem;
    max-width: 80%;
    width: auto;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.18)) drop-shadow(0px 0px 2px rgba(34, 17, 34, 0.18));
    border-radius: 8px;
  }

  h5 {
    font-size: 18px;
    line-height: 26px;
  }

  @media screen and (max-width: 1500px) {
    h1 {
      font-size: 2.916vw;
    }
  }
}
`;

const OurProcessContentMobile = styled.section`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .content {
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.04), 0px 23.5px 23px -13.5px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    width: 90%;
    //max-width: 450px;
    position: absolute;
    bottom: 35px;
    transform: translateX(-50%);
    left: 50%;
    transition: all .3s;
    &.prev {
      left: -50%;
    }
    &.next {
      left: 150%
    }
    &.hidden {
      opacity: 0;
      left: 150%
    }
    h1{
      font-weight: bold;
      font-size: 24px;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: var(--purple1);
      margin-bottom: 1.5rem;
    }
    h5 {
      margin: 0;
      font-size: 4.44vw;
    }
  }
`

const OurProcessContent = ({process, activeIndex, handleToContacts}) => {
    const screenSize = useWindowSize().width;
    const {t} = useTranslation();

    return (
        <>
            {
                screenSize > 960 ? (
                    <OurProcessContentStyled>
                        <GreyHeadline style={{marginBottom:'50px', fontSize: screenSize > 960 ? '20px' : '16px'}}>
                            {t("our_process")}
                        </GreyHeadline>
                        <h1>{process[activeIndex].title}</h1>
                        <TextP style={{minHeight: "110px"}}>{process[activeIndex].text}</TextP>
                        <div className="wrap" onClick={() => handleToContacts()}>
                            <ButtonMain
                                title={t("btn.get_free_quote")}
                                marginBottom='50px'
                                notBold={true}
                                fontSize={"18px"}
                                minHeight={"48px"}
                                minWidth={"300px"}
                            />
                        </div>
                        <img src={processImg} alt="" />
                    </OurProcessContentStyled>
                ) : (
                    <OurProcessContentMobile>
                        {process.map((item, i) => (
                            <div key={i} className={activeIndex === i ? "content active" : (activeIndex === i + 1) ? "content prev" : (activeIndex === i - 1) ? "content next" : "content hidden"}>
                                <h1>{item.title}</h1>
                                <TextP>{item.text}</TextP>
                            </div>
                        ))}
                    </OurProcessContentMobile>
                )
            }
        </>
    )
}

export default OurProcessContent
