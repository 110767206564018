import React from 'react';
import styled from "styled-components";

const Button = styled.button`
    padding: 4px 32px;
    background: var(--purple1);
    color: var(--white);
    border: 1.5px solid var(--purple1);
    border-radius: 72px;
    box-shadow: 0px 10px 40px -10px #6529BD;
    cursor: pointer;
    font-size: 1.5rem;
    align-self: center;

    :hover {
        background: var(--purple2);
    }

    :active {
        background: var(--purple3);
    }
  
    @media screen and (max-width: 960px) {
      font-size: 1.2rem;
      //max-height: 50px;
    }
`;

const ButtonMain = ({ title, marginTop, marginBottom, marginLeft, marginRight, maxWidth, width, height, minHeight, minWidth, fontSize, padding, maxHeight, type, onClick, handleToContacts, lowerCase, notBold, upperCase, position, zIndex}) => {
    return (
        <Button style={{
            marginTop: marginTop,
            marginBottom: marginBottom,
            marginLeft: marginLeft,
            marginRight: marginRight,
            fontSize: fontSize,
            padding: padding,
            // maxHeight: maxHeight,
            maxWidth: maxWidth,
            width: width,
            height: height,
            minHeight: minHeight,
            textTransform: upperCase ? "uppercase" : lowerCase ? "unset" : "capitalize",
            fontWeight: notBold ? "400" : "bold",
            minWidth: minWidth,
            position: position || 'unset',
            zIndex: zIndex
        }}>
            {title}
        </Button>
    )
}

export default ButtonMain
