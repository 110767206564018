// import whiteLogo from '../assets/img/hero-logo-white.svg';
// import mobileLogo from '../assets/img/mobileLogo.svg';
import logo from "../assets/img/hero-logo-white.svg";

export const footerData = {
  en: {
    logo: logo,
    mobileLogo: logo,
    contactDetails: {
      address: "Rival 18, Tel Aviv",
      phoneNumber: "+972506582131",
      email: "office@productive.co.il",
      facebook: "https://www.facebook.com/productiveil",
      instagram: "https://www.instagram.com/productive_software/?hl=en",
      linkedin: "https://www.linkedin.com/company/49076933/",
      privacy: "",
      terms: "",
    },
    content: [
      {
        title: "About Productive",
        content: [
          "About",
          "The Team",
          "The process",
          "Clients",
          "Portfolio",
          "Careers",
          "Blog",
        ],
        link: [
          "./about",
          "/team",
          "/services/team",
          "/clients",
          "/portfolio",
          "/careers",
          {
            "custom": "https://p.productive.co.il/blog",
          }
        ],
      },
      {
        title: "Our Services",
        content: ["Build a Team", "Build a Product", "Services"],
      },
      {
        title: "Boring Stuff",
        content: ["FAQ", "Blog", "Terms of Use", "Privacy Policy"],
      },
    ],
  },
  he: {
      logo: logo,
      mobileLogo: logo,
      contactDetails: {
        address: "ריב\"ל 18,  תל אביב",
        phoneNumber: "972-506582131+",
        email: "office@productive.co.il",
        facebook: "https://www.facebook.com/productiveil",
        instagram: "https://www.instagram.com/productive_software/?hl=en",
        linkedin: "https://www.linkedin.com/company/49076933/",
        privacy: "",
        terms: "",
      },
      content: [
        {
          "title": "על פרודקטיב",
          "content": [
            "עלינו",
            "הצוות שלנו",
            "התהליך",
            "חברים",
            "פורטפוליו",
            "קריירה",
            "בלוג"
          ],
          link: [
            "./about",
            "/team",
            "/services/team",
            "/clients",
            "/portfolio",
            "/careers",
            {
              "custom": "https://p.productive.co.il/blog",
            }
          ],
        },
        {
          "title": "השירותים שלנו",
          "content": ["בניית צוות", "בניית מוצר", "שירותים"]
        },
        {
          "title": "דברים משעממים",
          "content": ["שאלות ותשובות", "בלוג", "תנאי שימוש", "מדיניות הפרטיות"]
        },
      ],
  },
  ru: {
    logo: logo,
    mobileLogo: logo,
    contactDetails: {
      address: "Rival 18, Tel Aviv",
      phoneNumber: "+972506582131",
      email: "contact@productive.co.il",
      privacy: "",
      terms: "",
    },
    content: [
      {
        title: "О Productive",
        content: [
          "О нас",
          "Команда",
          "Процесс",
          "Наши друзья",
          "Портфолио",
          "Карьера",
        ],
        link: [
          "./about",
          "/team",
          "/services/team",
          "/clients",
          "/portfolio",
          "/careers",
        ],
      },
      {
        title: "Наши услуги",
        content: ["Создать команду", "Создать продукт", "Сервисы"],
      },
      {
        title: "Скучное...",
        content: [
          "FAQ",
          "Блог",
          "Условия эксплуатации",
          "Политика конфиденциальности",
        ],
      },
    ],
  },
};
