import SalesImg from "../assets/img/InformationSystem/sub/Saleforce/sales-circle .svg";
import MarketingImg from "../assets/img/InformationSystem/sub/Saleforce/marketing-circle.svg";
import ExperienceImg from "../assets/img/InformationSystem/sub/Saleforce/experience-circle.svg";
import LearningImg from "../assets/img/InformationSystem/sub/Saleforce/learning-circle.svg";
import ServiceImg from "../assets/img/InformationSystem/sub/Saleforce/service-circle.svg";

export const salesforceProducts = {
    en: [
        {
            img: SalesImg,
            title: "Sales Cloud",
            description: "Cross-platform optimization of B2B and B2C sales processes from the phase of receiving the lead and its management to closing the sales cycle - for the purpose of shortening sales processes and improving effectiveness."
        },
        {
            img: MarketingImg,
            title: "Marketing Cloud",
            description: "Management and optimization of the customer journey for the purpose of increasing the conversion ratio, including the concentration of behaviors and lead data as a tool to increase the effectiveness of each interaction."
        },
        {
            img: ExperienceImg,
            title: "Experience Cloud",
            description: "Managing communication with customers, partners and employees through customized portals."
        },
        {
            img: LearningImg,
            title: "Pardot",
            description: "Marketing automation from SalesForce offering email automation, targeted email campaigns and lead management for B2B sales and marketing organizations and landing pages. Automate common marketing tasks, including: tracking customer behaviors, Creating digital marketing campaigns."
        },
        {
            img: ServiceImg,
            title: "Service Cloud",
            description: "Management of a variety of service processes, including tools to improve the productivity of the service providers, digital service systems, dashboards (work and control board) that provide a comprehensive view of the customer's details and dedicated components for the various fields. The tool includes the possibility of managing diverse interactions through different digital means, by using the Service Cloud Digital Engagement."
        },
    ],
    he: [
        {
            img: SalesImg,
            "title": "Sales Cloud",
            "description": "אופטימיזציה חוצה פלטפורמות של תהליכי מכירות B2B ו- B2C משלב קבלת ההובלה וניהול שלה לסגירת מעגל המכירות - במטרה לקצר את תהליכי המכירות ולשפר את היעילות."
        },
        {
            img: MarketingImg,
            "title": "Marketing Cloud",
            "description": "ניהול ואופטימיזציה של מסע הלקוח לצורך הגדלת יחס ההמרה כולל ריכוז התנהגויות וניהול נתונים ככלי להגברת האפקטיביות של כל אינטראקציה."
        },
        {
            img: ExperienceImg,
            title: "Experience Cloud",
            "description": "ניהול תקשורת עם לקוחות, שותפים ועובדים באמצעות פורטלים מותאמים אישית."
        },
        {
            img: LearningImg,
            title: "Pardot",
            "description": "אוטומציה שיווקית מ- Salesforce המציעה אוטומציה של דואר אלקטרוני, קמפיינים ממוקדים בדוא\"ל וניהול מוביל עבור ארגוני מכירות ושיווק B2B ודפי נחיתה. אוטומציה של משימות שיווק נפוצות, כולל: מעקב אחר התנהגויות של לקוחות, יצירת קמפיינים שיווקיים דיגיטליים."
        },
        {
            img: ServiceImg,
            title: "Service Cloud",
            "description": "ניהול מגוון של תהליכי שירות, לרבות כלים לשיפור הפרודוקטיביות של ספקי השירות, מערכות שירות דיגיטליות, לוחות מחוונים (לוח עבודה ובקרה) המספקים מבט מקיף על פרטי הלקוח ורכיבים ייעודיים לתחומים השונים. הכלי כולל את האפשרות לנהל אינטראקציות מגוונות באמצעים דיגיטליים שונים, באמצעות השירות Cloud Digital Engagement."
        },
    ],
    ru: [
        {
            img: SalesImg,
            title: "Sales Cloud",
            description: "Кросс-платформенная оптимизация процессов продаж B2B и B2C от фазы получения лида и управления им до закрытия цикла продаж - с целью сокращения процессов продаж и повышения эффективности."
        },
        {
            img: MarketingImg,
            title: "Marketing Cloud",
            description: "Управление и оптимизация пути клиента с целью повышения коэффициента конверсии, включая концентрацию поведений и данных о лидах как инструмент повышения эффективности каждого взаимодействия."
        },
        {
            img: ExperienceImg,
            title: "Experience Cloud",
            description: "Управление общением с клиентами, партнерами и сотрудниками через настраиваемые порталы."
        },
        {
            img: LearningImg,
            title: "Pardot",
            description: "Автоматизация маркетинга от SalesForce, предлагающая автоматизацию электронной почты, целевые кампании по электронной почте и управление потенциальными клиентами для организаций по продажам и маркетингу B2B и целевых страниц. Автоматизируйте общие маркетинговые задачи, в том числе: отслеживание поведения клиентов, создание кампаний цифрового маркетинга."
        },
        {
            img: ServiceImg,
            title: "Service Cloud",
            description: "Управление различными сервисными процессами, включая инструменты для повышения производительности поставщиков услуг, цифровые системы обслуживания, информационные панели (рабочие и контрольные панели), которые обеспечивают всестороннее представление сведений о клиенте и специальные компоненты для различных областей. Инструмент включает в себя возможность управления разнообразными взаимодействиями с помощью различных цифровых средств с помощью Service Cloud Digital Engagement."
        },
    ]
};
