import React, { useState } from 'react';
import styled from "styled-components";
import Carrousel from './Carrousel';
import ButtonMain from '../Buttons/ButtonMain';
import { Link } from 'react-router-dom';
import { GreyHeadline } from '../Fonts.Style';
import {useTranslation} from "react-i18next";
import {useWindowSize} from "react-use";

const TestimonialsStyled = styled.section`
    //height: 47.875rem /* 766px -> 47.875rem */;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 5.5rem;
    padding-right: 0;
    position: relative;
    height: fit-content;
    background: #F7F7F7;
`;

const Testimonials = () => {

    const [slideIdx] = useState(0);
    const {t} = useTranslation();
    const screenSize = useWindowSize().width;


    // const handleClick = (direction) => {
    //     if (direction === "left") {
    //         setSlideIdx(slideIdx > 1 ? slideIdx - 1 : 2);
    //     } else {
    //         setSlideIdx(slideIdx < 2 ? slideIdx + 1 : 0)
    //     };
    // };

    return (
        <TestimonialsStyled >
            <GreyHeadline style={{ marginTop: screenSize > 500 ? '6.875rem' : '3rem', position: "relative", zIndex: "3"}}>
                {t("testimonials")}
            </GreyHeadline>
            <Carrousel slideIdx={slideIdx} />
            <div className="flex">
                <Link to="/clients">
                    <ButtonMain title="All Clients"
                        width="230px"
                        height="48px"
                        position="relative"
                        zIndex="3"
                        fontSize="1.125rem"
                        notBold={true}
                    />
                </Link>
            </div>
        </TestimonialsStyled>
    );
};

export default Testimonials;
