import React from 'react';
import styled from "styled-components";

import arrow from '../../assets/img/Arrow-Right.svg';
import {useTranslation} from "react-i18next";
import useDirection from "../../hooks/useDirection";

const SubmitBtnStyled = styled.button`
  background-color: ${props => props.disabled ? '#F7F7F7' : 'var(--purple1)'};
  color: ${props => props.disabled ? 'var(--purple1)' : 'var(--white)'};
  border-radius: 40px;
  border: ${props => props.disabled ? '2px solid var(--purple1)' : '2px solid var(--white)'};
  align-items: center;
  padding: 4px;
  //border: none;
  //width: ${props => props.page === 'position' ? '12.25rem' : '100%'} ;
  width: 185px;
  height: 40px;
  font-size: 18px;
  line-height: 23px;
  //font-weight: bold;
  align-self: ${props => props.page === 'position' ? 'flex-end' : 'center'} ;
  cursor: pointer;
  text-transform: capitalize;
  ${({isRtl}) => isRtl ? "margin-right" : "margin-left" }: ${props => props.text ? 'unset' : 'auto'};
  justify-content: center;

  img {
    width:32px;
    height: 32px;
    border-radius: 50px;
    background-color: var(--white);
    padding:5px;
    transform: ${({isRtl}) => isRtl ? "rotate(180deg)" : "unset"};
  }

  @media screen and (max-width: 500px) {
    width: 200px;
    margin: 0 auto;
  }
`;

const ButtonFormSubmit = ({page, text = '', disabled = false, type="submit"}) => {

    const {t} = useTranslation();
    const {isRtl} = useDirection();

    return (
        <SubmitBtnStyled type={type} className={disabled ? "flex" : "flex space-between"} page={page} isRtl={isRtl} disabled={disabled} text={text}>
            <div />
            {text ? text : t("btn.send")}
            {!disabled && <img src={arrow} alt="" />}
        </SubmitBtnStyled>
    );
};

export default ButtonFormSubmit;
