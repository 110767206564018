import React from 'react';
import styled from "styled-components";

import Card from './Card';
import {HeadlineP} from "../Fonts.Style";
import useWindowSize from "../../hooks/useWindowSize";
import {useTranslation} from "react-i18next";

const CardsListContainer = styled.div`
    display: flex;
    justify-self: flex-end;
    width: ${props => props.page === "services" ? "55%" : "100%"};
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: ${props => props.page === "careers" ? "-10%" : "unset"};
    @media screen and (max-width: 960px) {
      width: 100%;
      margin-top: 2rem;
    }
`;

const CardsList = ({cards, page}) => {

    const screenSize = useWindowSize().width;
    const {t} = useTranslation();

    return (
        <CardsListContainer page={page}>
            {(screenSize <= 960 && page !== "services") && <HeadlineP style={{margin:'2rem auto', width: "100%"}}>{t("benefits")}</HeadlineP>}
            {cards.map((card, index) => (
                <Card card={card} key={index} page={page}/>
            ))}
        </CardsListContainer>
    )
}

export default CardsList
