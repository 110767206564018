import React from 'react';
import styled from "styled-components";

import V from '../../assets/img/V.svg';
import {Trans, useTranslation} from "react-i18next";
const BannerStyled = styled.section`
  border-radius: 200px;
  width: 90%;
  max-width: 1500px;
  padding: 50px 0;
  background-color: var(--purple1);
  margin: 0 auto 136px;
  align-items: center;
  display: flex;
  h1{
    margin:0 5% 0 12%;
    font-family: Poppins;
    font-weight: 800;
    font-size: 2.5vw;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
  }

  .banner-info {
    width: 188px;
    text-align: center;
    margin: 0 15px;
    img {
      margin-bottom:20px;
    }

    p{
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      text-align: center;
      color: #FFFFFF;
    }
  }
  @media screen and (max-width: 960px) {
    flex-direction: column;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding-bottom: 100px;
    margin-top: -10px;
    h1 {
      font-size: 32px;
      margin: 0;
      text-align: center;
    }
    .banner-info {
      margin: 30px 0 0;
    }
  }
  @media screen and (max-width: 500px) {
    margin-top: 0;
    width: calc(100% - 60px);
    //box-shadow: 0px 2px 5px rgb(0 0 0 / 60%)
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    margin-bottom: 40px;
  }
`;

const Banner = () => {

  const {t} = useTranslation();

  const bannerLength = 4;


  return <BannerStyled>
    <Trans components={{br: <br />, h1: <h1 />}}>about_us.banner.title</Trans>
    {new Array(bannerLength).fill('').map((title, index) => (
      <div className="banner-info fle column" key={index}>
        <img src={V} alt="" />
        <p>{t(`about_us.banner.items.item${index}`)}</p>
      </div>
    ))}
  </BannerStyled>;
};

export default Banner;
