import RoadMap from "../assets/img/InformationSystem/sub/Monday/roadmap.svg";


export const mondayServiceCards = {
    en: [
        {
            img: RoadMap,
            title: "STRATEGY & CONSULTING",
            description: "We offer strategic consulting services to help our clients map out how to approach their monday.com implementation, in a deliberate, benefits-centered manner, ensuring they realize the intended benefits."
        },
        {
            img: RoadMap,
            title: "WORKFLOW IMPLEMENTATION",
            description: "We offer several implementation services that address all aspects of the implementation process, from requirements gathering thru roles and permissions."
        },
        {
            img: RoadMap,
            title: "MONDAY.COM TRAINING",
            description: "We provide various training models that are tailored to the needs and culture of each client and the learning objectives of individual teams.  This includes formal training, hands-on application, as well as one-on-one coaching."
        },
        {
            img: RoadMap,
            title: "CUSTOM DEVELOPMENT",
            description: "Using the monday.com App Framework as well as the monday.com API, we are able to build monday.com apps or 3rd party apps that address specific process requirements of clients."
        },
        {
            img: RoadMap,
            title: "CUSTOMER SUCCESS MANAGEMENT",
            description: "With our dedicated account managers, we are able to offer clients a well-defined onboarding plan, as well as ongoing reviews and insights to ensure they maximize their investment in the platform."
        },
        {
            img: RoadMap,
            title: "MAINTENANCE & SUPPORT",
            description: "We offer maintenance, optimization, and support packages that enable clients to ensure their monday.com workflows remain current and perform efficiently."
        }
    ],
    he: [
        {
            img: RoadMap,
            "title": "אסטרטגיה וייעוץ",
            "description": "אנו מציעים שירותי ייעוץ אסטרטגיים כדי לסייע ללקוחותינו למפות כיצד לגשת ליישום שלהם ב- monday.com, באופן מכוון ומרוכז בהטבות, ולהבטיח שהם יממשו את היתרונות המיועדים."
        },
        {
            img: RoadMap,
            "title": "WORKFLOW IMPLEMENTATION",
            "description": "אנו מציעים מספר שירותי הטמעה העונים על כל ההיבטים של תהליך היישום, החל מהדרישות שאוספות תפקידים והרשאות."
        },
        {
            img: RoadMap,
            "title": "MONDAY.COM אימון",
            "description": "אנו מספקים מודלי הכשרה שונים המותאמים לצרכים ולתרבות של כל לקוח ולמטרות הלמידה של צוותים בודדים. זה כולל הכשרה רשמית, יישום מעשי, כמו גם אימון אחד על אחד."
        },
        {
            img: RoadMap,
            "title": "פיתוח מותאם אישית",
            "description": "באמצעות תוכנת monday.com כמו גם ה-API של monday.com, אנו מסוגלים לבנות אפליקציות monday.com או לבצע אינטגרציות לאפליקציות צד שלישי הנותנות מענה לדרישות תהליך ספציפיות של לקוחות"
        },
        {
            img: RoadMap,
            "title": "ניהול הצלחת לקוח",
            "description": "אנו מציעים תהליך ליווי מלא לתמיכה בשירותי Monday, מנהלי הלקוחות שלנו יהיו זמינים לכל שאלה, הדרכה ולווי שידרשו כדי להוציא את המיטב ממערכת ה Monday שלכם"
        },
        {
            img: RoadMap,
            "title": "תחזוקה ותמיכה",
            "description": "אנו מציעים חבילות תחזוקה, מיטוב ותמיכה, המאפשרות ללקוחות להבטיח שזרימות העבודה של monday.com יישארו עדכניות ויפעלו ביעילות."
        }
    ],
    ru: [
        {
            img: RoadMap,
            title: "СТРАТЕГИЯ И КОНСАЛТИНГ",
            description: "Мы предлагаем стратегические консультационные услуги, чтобы помочь нашим клиентам наметить, как подойти к внедрению monday.com обдуманно и с ориентацией на преимущества, гарантируя, что они получат ожидаемые преимущества."
        },
        {
            img: RoadMap,
            title: "РЕАЛИЗАЦИЯ РАБОЧЕГО ПРОЦЕССА",
            description: "Мы предлагаем несколько услуг по реализации, которые охватывают все аспекты процесса внедрения, от сбора требований до ролей и разрешений."
        },
        {
            img: RoadMap,
            title: "MONDAY.COM ОБУЧЕНИЕ",
            description: "Мы предлагаем различные модели обучения, адаптированные к потребностям и культуре каждого клиента, а также к целям обучения отдельных команд. Это включает в себя формальное обучение, практическое применение, а также индивидуальный коучинг."
        },
        {
            img: RoadMap,
            title: "ПОЛЬЗОВАТЕЛЬСКАЯ РАЗРАБОТКА",
            description: "Используя платформу приложений monday.com, а также API monday.com, мы можем создавать приложения monday.com или сторонние приложения, отвечающие конкретным требованиям клиентов к процессам."
        },
        {
            img: RoadMap,
            title: "УПРАВЛЕНИЕ УСПЕХОМ КЛИЕНТА",
            description: "С помощью наших преданных менеджеров по работе с клиентами мы можем предложить клиентам четко определенный план адаптации, а также постоянные обзоры и идеи, чтобы гарантировать, что они максимизируют свои инвестиции в платформу."
        },
        {
            img: RoadMap,
            title: "ОБСЛУЖИВАНИЕ И ПОДДЕРЖКА",
            description: "Мы предлагаем пакеты обслуживания, оптимизации и поддержки, которые позволяют клиентам обеспечивать актуальность и эффективность рабочих процессов monday.com."
        }
    ]
 };
