import React from 'react';
import styled from "styled-components";

const MemberStyled = styled.div`
    text-align: center;

    img {
        width: ${props => props.page === "teamPage" ? "165px" : "100%"};
        aspect-ratio: 1 / 1;
        border: 3px solid var(--purple1);
        border-radius:50%;
        background: var(--purple1);
        margin: ${props => props.page === "teamPage" ? "50px 25px"  : "0 auto 20px"};
    }
    h3 {
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: var(--grey1);
        margin-bottom: 8px;
    }
    p, a{
        font-weight: normal;
        font-size: 1rem;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: var(--black);
    }
    a{
        color: var(--purple1);
    }
  @media screen and (max-width: 500px) {
    img {
      margin-bottom: 16px;
    }
  }
`;

const Member = ({ teamMember, page }) => {
    return (
        <MemberStyled className="team-item flex column" page={page}>
            <img src={teamMember.img} alt="" />
            <h3>{teamMember.teamMember}</h3>
            <p className="p-position">{teamMember.position}</p>
            <a href={`mailto:${teamMember.email}`}> {teamMember.email}</a>
        </MemberStyled>
    );
};

export default Member;
