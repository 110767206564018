import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const CustomerStyled = styled.div`
  text-align: center;
  width: 140px;
     .img-container {
       border: 3px solid var(--purple1);
       border-radius: 50%;
       background-color: white;
       width: 100%;
       display: flex;
       justify-content: center;
       margin: ${props => props.page === "teamPage" ? "50px 25px" : "0 auto 20px"};
       aspect-ratio: 1 / 1;
       overflow: hidden;
       img {
         object-fit: contain;
         width: 80%;
       }
     }
    h3 {
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: var(--grey1);
    } 
    p {
        margin: 5px 0;
    }
    p, a{
        font-weight: normal;
        font-size: 1rem;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: var(--black);
    }
    a{
      color: var(--purple1);
      text-decoration: underline;
    }
  @media screen and (max-width: 500px) {
    img {
      margin-bottom: 16px;
    }
  }
`;

const Customer = ({ customer, page }) => {
    const {t} = useTranslation();
    return (
        <CustomerStyled className="team-item flex column" page={page}>
            <div className="img-container">
                <img src={customer.img1} alt="" />
            </div>
            <h3>{customer.companyName}</h3>
            <p> {customer.website || t("btn.project_in_progress")}</p>
            {customer.website && <a href={customer.website} target="_blank" className="p-position">{t("see_more")}</a>}
        </CustomerStyled>
    );
};

export default Customer;
