import React from 'react';
import styled from "styled-components";

import secondaryImg from "../../assets/img/aboutSec.png";
import {useTranslation} from "react-i18next";

const AboutSecondaryStyled = styled.main`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto 130px;
  padding: 0 30px;

  .secondary-img {
    flex: 1;
    /* filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.18)) drop-shadow(0px 0px 2px rgba(34, 17, 34, 0.18)); */
    border-radius: 8px;

    img {
      width: 100%;
    }
  }

  .secondary-info {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    margin-left: 200px;
    min-width: 400px;

    .about-wrapper {
      flex-direction: column;
      display: flex;

      h1 {
        font-weight: bold;
        font-size: 3.625rem;
        line-height: 140%;
        text-transform: capitalize;
        color: var(--gray1);
        margin-bottom: 38px;
      }

      p {
        font-weight: normal;
        font-size: 24px;
        line-height: 140%;
        color: var(--black);
      }
    }
  }
  
  @media screen and (max-width: 960px) {
    flex-direction: column-reverse;
    .secondary-info {
      width: 100%;
      min-width: unset;
      margin: 0 0 40px;
    }
  }


`;

const AboutSecondary = () => {

    const {t} = useTranslation();

    return (<AboutSecondaryStyled>
            <div className="secondary-img">
                <img src={secondaryImg} alt=""/>
            </div>
            <div className="secondary-info">
                <div className="about-wrapper">
                    {/*<h1>Header is here</h1>*/}
                    <p>{t("about_us.secondary_text")}</p>
                </div>
            </div>
        </AboutSecondaryStyled>);
};

export default AboutSecondary;
