import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import CustomersCarousel from "./CustomersCarousel";
import ButtonMain from "../../components/Buttons/ButtonMain";
import {GreyHeadline} from "../../components/Fonts.Style";
import {Link} from "react-router-dom";

const CustomersStyled = styled.section`
    background-color: ${props => props.page === "InformationSystem" ? "#F7F7F7" : "#FFFFFF"};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 218px;
    padding-top: 65px;
  @media screen and (max-width: 960px) {
    padding-bottom: 118px;
    margin-bottom: 65px;
  }
  
`;

const Customers = ({page}) => {
    const {t} = useTranslation();
    return (
        <CustomersStyled page={page}>
            <GreyHeadline style={{ marginBottom: '136px' }}>{t("InformationSystem.sections.customers.heading")}</GreyHeadline>
            <CustomersCarousel/>
            <Link to="/clients">
                <ButtonMain
                    title={t("btn.see-full-customers")}
                    fontSize="15px"
                    width="228px"
                    height="52px"
                    notBold={true}
                />
            </Link>
        </CustomersStyled>
    )
}

export default Customers
