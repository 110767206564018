import React from 'react';
import styled from 'styled-components';
import ButtonMain from '../Buttons/ButtonMain';
import HeroScroller from './HeroScroller';
import {useTranslation} from "react-i18next";
import {useWindowSize} from "react-use";
import MobileContactBtn from "../Buttons/MobileContactBtn";

const BottomWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7rem;
  .btnWrap {
    display: flex;
    width: 90%;
  }

  @media screen and (max-width: 960px) {
    margin-top: 0;
    width: 80%;
    .btnWrap {
      width: unset;
    }
  }
`

const HeroBottom = ({handleToContacts}) => {

    const {t} = useTranslation();
    const screenSize = useWindowSize().width;

    return (
        <BottomWrap>
            <div className={"btnWrap"} onClick={handleToContacts}>
                {
                    screenSize > 960 ?
                        <ButtonMain title={t("btn.get_free_quote")}
                                    width={screenSize > 500 ? "400px" : "unset"}
                                    maxWidth="450px"
                                    marginLeft="auto"
                                    marginRight="auto"
                                    minHeight={screenSize > 500 ? "72px" : "60px"}
                                    upperCase={false}
                                    notBold={true}
                                    fontSize={'18px'}
                                    handleToContacts={handleToContacts}
                        /> :
                        <MobileContactBtn
                            text={t("btn.get_free_quote")}
                        />
                }

            </div>
            <HeroScroller />
        </BottomWrap>
    );
};

export default HeroBottom;
