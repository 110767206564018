import React from 'react';
import styled from "styled-components";
import arrow from '../../assets/img/Arrow-Right.svg';

const SubmitBtnStyled = styled.button`
  background-color: var(--purple1);
  color: var(--white);
  border-radius: 40px;
  align-items: center;
  padding: 4px;
  border: none;
  width: 200px;
  height: 40px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  align-self: center;
  cursor: pointer;

  img {
    width: 32px;
    height: 32px;
    border-radius: 50px;
    background-color: var(--white);
    padding:5px;
  }
`;

const BtnWithArrow = ({text}) => {
    return (
        <SubmitBtnStyled type="submit" className="flex space-between">
            <div />
            {text}
            <img src={arrow} alt="" />
        </SubmitBtnStyled>
    );
};

export default BtnWithArrow;
