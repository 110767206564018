import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import heroImg from '../../assets/img/hero-phone.svg';
import heroVid from '../../assets/video/hero.mp4';
import HeroBottom from './HeroBottom';
import useWindowSize from '../../hooks/useWindowSize';
import {HeroHeadline} from '../Fonts.Style';
import {Trans, useTranslation} from "react-i18next";
import HeroScroller from "./HeroScroller";
import Swipe from "react-easy-swipe";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import {AnimationOnScroll} from 'react-animation-on-scroll';
import useDirection from "../../hooks/useDirection";
import ReactPlayer from "react-player";

const HeroSection = styled.section`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  //margin: 0 auto;
  justify-content: space-between;
  height: calc(100vh - 100px);
  position: relative;
  align-items: center;
  padding: 0 120px 100px;


  .hero-headline-container {
    flex-direction: column;
    justify-content: center;
    width: 75%;
    overflow: visible;
    min-height: 100%;
  }

  .hero-headline {
    //white-space: nowrap;
    width: fit-content;
  }

  .hero-headline span {
    display: inline-block;
  }

  .hero-headline:first-child {
    margin-bottom: 0;
  }

  .hero-right {
    z-index: -1;
    flex-direction: column;
    width: 53.5%;
    aspect-ratio: 10 / 9;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 90%;
    overflow: hidden;
    video {
      margin: -3px;
      border: none;
      width: auto !important;
      height: 100% !important;
      object-fit: cover;
    }
  }

  @media screen and (max-width: 960px) {
    min-height: calc(100vh - 80px);
    height: unset;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px 90px;
    .hero-headline-container {
      width: 100%;
      min-height: unset;
    }

    .hero-headline {
      margin: 0;
      white-space: normal;
      width: 100%;
    }

    .hero-right {
      width: 90%;
      aspect-ratio: 1 / 1;
      margin: 20px auto 40px;
    }
  }

`;

const HeadlineAnimation = styled.span`
  font-size: 3.75vw;
  letter-spacing: -0.06em;
  line-height: 150%;
  color: var(--purple1);
  position: relative;
  width: max-content;

  @media screen and (max-width: 960px) {
    font-size: 35px;
  }

  ::before {
    content: "";
    position: absolute;
    width: calc(100% + 5px);
    top: 0;
    bottom: 0;
    right: ${({isRtl}) => isRtl ? "0": "-5px"};
    left: ${({isRtl}) => isRtl ? "100%": "0"};
    background: white;
    animation: typeWriter 1s .5s steps(10) forwards ;
  }

  @keyframes typeWriter {
    to {
      width: 0%;
      right: ${({isRtl}) => isRtl ? "100%": "-5px"};
      left: ${({isRtl}) => isRtl ? "0": "100%"};
    }
  }
`;


const Hero = ({scrollDown, dir, handleToContacts}) => {

    const screenSize = useWindowSize().width;
    const animation = dir ? "animate__pulse" : "animate__fadeInDown";
    const [activeIndex, setActiveIndex] = useState(0);
    const {t} = useTranslation();
    const {isRtl} = useDirection();

    useEffect(() => {
        setTimeout(() => {
            activeIndex == 2 ? setActiveIndex(0) : setActiveIndex(activeIndex + 1)
        },4000);
    }, [activeIndex])

    return (
        <AnimationOnScroll animateOnce={true} animateIn={animation} animateOut={animation} duration={.5}>
            <Swipe onSwipeUp={() => scrollDown()}>
                <HeroSection className="flex">
                    <div className="flex hero-headline-container">
                        <HeroHeadline className="hero-headline">
                            <Trans components={{highlight: <HeadlineAnimation isRtl={isRtl}/>}} key={activeIndex}>{t([`hero_title.${activeIndex}`, 'hero_title[1]'])}</Trans>
                        </HeroHeadline>
                        {screenSize >= 960 &&
                            <HeroBottom handleToContacts={handleToContacts}/>
                        }
                    </div>
                    {/*<div className="hero-right flex" style={{backgroundImage: `url(${heroImg})`}}/>*/}
                    {/*<div className="hero-right flex">*/}
                    {/*    <video autoPlay={true} loop={true} muted={true} poster={heroImg}>*/}
                    {/*        <source src={heroVid}/>*/}
                    {/*    </video>*/}
                    {/*</div>*/}
                    <ReactPlayer
                        url={heroVid}
                        playing
                        volume={0}
                        muted
                        className="hero-right flex"
                        loop
                        playsinline
                        controls={false}
                        width={screenSize > 960 ? "53.5%" : "90%"}
                        height={"auto"}
                        // config={{ file: {attributes: { poster: heroImg }} }}
                    />
                    {screenSize < 960 &&
                        <HeroBottom handleToContacts={handleToContacts}/>
                    }
                </HeroSection>
            </Swipe>
        </AnimationOnScroll>

    );
};

export default Hero;
