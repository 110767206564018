import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import OurProcessAnimate from './OurProcessAnimate';
import {process} from "../../data/process";
import OurProcessContent from './OurProcessContent';
import {useTranslation} from "react-i18next";
import Swipe from "react-easy-swipe";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";

const OurProcessStyled = styled.section`
  display: flex;
  align-items: center;
  position: relative;
  height: calc(100vh - 100px);
  @media screen and (max-width: 960px) {
    height: calc(100vh - 80px);
    overflow: hidden;
  }
`;

const OurProcess = ({scrollUp, scrollDown, handleToContacts}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const {i18n} = useTranslation();
    const renderProcess = process[i18n.language.substring(0, 2)] || process.en;
    const [pause, setPause] = useState(500);

    useEffect(() => {
        setTimeout(() => setPause(null), 500)
    }, [])

    const handleScroll = isDown => {
        if(! pause) {
            if (isDown) {
                activeIndex === renderProcess.length - 1 ? scrollDown() : setActiveIndex(activeIndex + 1);
            } else {
                activeIndex === 0 ? scrollUp() : setActiveIndex(activeIndex - 1);
            }
        }
    }

    return (
        <Swipe onSwipeUp={() => handleScroll(true)} onSwipeDown={() => handleScroll(false)}>
            <ReactScrollWheelHandler upHandler={() => handleScroll(false)} downHandler={() => handleScroll(true)}>
                <OurProcessStyled>
                    <OurProcessAnimate process={renderProcess} activeIndex={activeIndex}/>
                    <OurProcessContent process={renderProcess} activeIndex={activeIndex} handleToContacts={handleToContacts}/>
                </OurProcessStyled>
            </ReactScrollWheelHandler>
        </Swipe>
    )
}

export default OurProcess
