import FunderLogo from "../assets/img/news/funder-logo.png";
import FunderImg from "../assets/img/news/funder-img.png";
import MarkerLogo from "../assets/img/news/MarkerLogo.png";
import MarkerImg from "../assets/img/news/MarkerImg.png";
import IceLogo from "../assets/img/news/ice-logo.png"
import NewsImg from "../assets/img/news/news-img.png"
import NewsLogo from "../assets/img/news/news-logo.png"
import IceImg1 from "../assets/img/news/ice-lmg1.png";
import IceImg2 from "../assets/img/news/ice-lmg2.png";
import IceImg3 from "../assets/img/news/ice-lmg3.png";
import IceImg4 from "../assets/img/news/ice-lmg4.png";
import GeektimeImg from "../assets/img/news/geektime-img.png";
import GeektimeLogo from "../assets/img/news/geektime-logo.png";
import Tech12Logo from "../assets/img/news/tech12-logo.png";
import Tech12Img from "../assets/img/news/tech12-img.png";
import GlobesLogo from "../assets/img/news/globes-logo.png";
import GlobesImg from "../assets/img/news/globes-img.png";

export const news = {
    en: [
        {
            logo: MarkerLogo,
            img1: MarkerImg,
            link: 'https://www.themarker.com/labels/technologies/2023-01-17/ty-article-labels/00000185-c00a-d6ff-afdd-f35e16240000'
        },
        {
            logo: FunderLogo,
            img1: FunderImg,
            link: 'https://www.funder.co.il/article/136473'
        },
        {
            logo: IceLogo,
            img1: IceImg1,
            img2: IceImg2,
            img3: IceImg3,
            img4: IceImg4,
            link: 'https://www.ice.co.il/finance/news/article/868623'
        },
        {
            logo: NewsLogo,
            img1: NewsImg,
            link: 'https://finance.walla.co.il/item/3520499'
        },
        {
            logo: GeektimeLogo,
            img1: GeektimeImg,
            link: 'https://www.geektime.com/outsourcing-developer-teams/'
        },
        {
            logo: Tech12Logo,
            img1: Tech12Img,
            link: 'https://www.tech12.co.il/index-commentary/Article-9efd7299cc3d281027.htm?sCh=cf25c425b37bc710&pId=18722765311'
        },
        {
            logo: GlobesLogo,
            img1: GlobesImg,
            link: 'https://www.globes.co.il/news/article.aspx?did=1001420712'
        },
    ]
}