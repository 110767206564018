import React from 'react';
import styled from "styled-components";

import { offices } from '../../data/officesData';

const MapsStyled = styled.section`
    display: flex;
    justify-content:  space-between;
    width: 100%;
    margin-bottom: 108px ;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const MapStyled = styled.div`
    display: flex;
    width: calc((100% - 48px) / 3);
    flex-direction: column;
    align-items: center;
    img{
        border-radius:10px 10px 0 0;
        aspect-ratio: 500 / 320;
        width: 100%;
    }
    p{
        border-radius: 0 0 10px 10px;
        background-color: #4A4A4A;
        width: 100%; 
        text-align: center;
        color: var(--white);
        font-weight: normal;
        font-size: 17px;
        line-height: 150%;
        padding: 10px;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 30px;
    }
`;

const Maps = () => {
    return (
        <MapsStyled>
            {offices.map((office, index) => (
                <MapStyled key={index}>
                    <img src={office.tempMap} alt="" />
                    <p>
                        {office.address}
                    </p>
                </MapStyled>
            ))}
        </MapsStyled>
    )
}

export default Maps
