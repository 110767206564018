import React, {useState} from 'react';
import styled from "styled-components";

import whiteArrow from '../../assets/img/white-arrow.svg';
import purpleArrow from '../../assets/img/purple-arrow.svg';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import useDirection from "../../hooks/useDirection";

const NavbarBtnStyled = styled.button`
    color: var(--white);
    background-color: var(--purple1);
    border: 1.5px solid var(--purple1);
    border-radius: 72px;
    align-items: center;
    padding: ${({padding}) => '12px ' + padding };
    width: fit-content;
    align-self: center;
    height: 48px;
    font-size: 1rem;
    line-height: 144%;
    font-weight: 400;
    min-width: 240px;
    /* align-self: center; */
    text-transform: capitalize;
    cursor: pointer;
    box-shadow: 0px 10px 29px -12px rgba(101, 41, 189, 0.7);
    transition: all .25s;
  
    &:hover {
      background: transparent;
      color: var(--purple1);
    }
    
    .btn-content{
        display: flex;
        justify-content: space-around;
        .btn-img {
            width:25px;
            height: 25px;
            padding:5px;
            ${({isRtl}) => isRtl ? "margin-right" : "margin-left"}: 8px;
            transform: ${({isRtl}) => isRtl ? 'rotate(180deg)' : 'unset'};
        }
    }
`;

const ButtonFormSubmit = ({link, text, padding}) => {

    const [hover, setHover] = useState(false);
    const {isRtl} = useDirection();

    return (
        <NavbarBtnStyled type="submit" isRtl={isRtl} padding={padding} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <Link to={link} className="btn-content">
                {text}
                <img src={hover ? purpleArrow : whiteArrow} alt="" className="btn-img flex"/>
            </Link>
        </NavbarBtnStyled>
    );
};

export default ButtonFormSubmit;
