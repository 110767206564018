import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

    :root {
        --purple1: #9146FF;
        --purple2: #7A3BD6;
        --purple3: #6330AD;

        --black: #1A1A1A;
        --grey00: #BFBFBF;
        --grey1: #333333;
        --grey2: #7A7A7A;
        --grey3: #DADADA;
        --grey4: #E7E7E7;
        --grey5: #F7F7F7;
        --white: #FFFFFF;
    }
    ::placeholder {
        color: var(--purple1);
        opacity: 1;
        font-family: 'bar', sans-serif;
    }
    ::selection {
        background-color: var(--purple1);
        color: var(--white);  
    }

    *{
        margin: 0;
        padding: 0;
        list-style: none;
        box-sizing: border-box;
        /* font-family: 'bar', sans-serif; */
    }
    
    body {
        font-family: 'Lato', sans-serif;
        background-color: var(--white);
        color: var(--grey1);
        overflow-x: hidden;
    }
    
    h1, h2, h3, h4, h5, h6 {
        font-family: 'Lato', sans-serif;
    }

    button {
        curser: pointer;
        font-family: 'Lato', sans-serif;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    .flex {
        display: flex;
    }

    .flex-end {
        justify-content: flex-end;
    }
    .column {
        flex-direction: column;
    }
    .space-between{
        justify-content: space-between;
    }
    .align-center {
        align-items: center;
    }

    .align-end {
      align-items: flex-end;
    }

    .align-stretch {
      align-items: stretch;
    }

    .space-evenly {
        justify-content:space-evenly;
    }

    .justify-center{
        justify-content: center;
    }
    .justify-end{
        justify-content: flex-end;
    }

    .reverse-our-work{
        flex-direction: row-reverse;
        padding: 0 0 0 80px;
    }


    .absolute {
        position: absolute;
    }
    .relative {
        position: relative;
    }

    .info-container{
        display: flex;
        align-self: flex-start;
        justify-content: space-between;
        width: 100%;
    }

    .column-reverse {
        flex-direction: column-reverse;
    }
  

`;

export default GlobalStyle;
