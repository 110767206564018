import office1 from '../assets/img/careers/office1.png';
import office2 from '../assets/img/careers/office2.png';
import map from '../assets/img/careers/map.png';
import israel from '../assets/img/israel.png';
import ukraine from '../assets/img/ukraine.png';
import gana from '../assets/img/gana.png';

export const offices = [
    {
        title: 'Office1',
        img1: office1,
        img2: office2,
        img3: office2,
        img4: office2,
        img5: office2,
        logo: office2,
        address: 'Rival 18, Tel aviv, Israel',
        coordinations:'',
        tempMap: israel,
    },
    {
        title: 'Office2',
        img1: office1,
        img2: office2,
        img3: office2,
        img4: office2,
        img5: office2,
        logo: office2,
        address: 'Nauky Avenue, 47/1, Kharkiv, Ukraine',
        coordinations:'',
        tempMap: ukraine,
    },
    {
        title: 'Office3',
        img1: office1,
        img2: office2,
        img3: office2,
        img4: office2,
        img5: office2,
        logo: office2,
        address: '226 Dzorwulu Road, Accra, Ghana',
        coordinations:'',
        tempMap: gana,
    },
];
