import quik from '../assets/img/clientsImg/quik.svg';
import quikCoIl from '../assets/img/clientsImg/quik-co-il.svg';
import keshet from '../assets/img/clientsImg/keshet.svg';
import keshetBw from '../assets/img/clientsImg/keshet-bw.svg';
import eco99 from '../assets/img/clientsImg/eco99.svg';
import obli from '../assets/img/clientsImg/obli.svg';
import asal from '../assets/img/clientsImg/asal.svg';
import digital from '../assets/img/clientsImg/digital';
import carpet from '../assets/img/clientsImg/carpet.png';
import shenkar from '../assets/img/clientsImg/shenkar.png';
import kiryat from '../assets/img/clientsImg/kiryat.jpg';
import idc from '../assets/img/clientsImg/idc.png';
import canzon from '../assets/img/clientsImg/canzon.png';
import mornao from '../assets/img/clientsImg/mornao.png';
import proCenter from '../assets/img/clientsImg/proCenter.svg';
import heel from '../assets/img/clientsImg/heel.jpg';
import sea from '../assets/img/clientsImg/sea.png';
import fitnuts from '../assets/img/clientsImg/fitnuts.png';
import or from '../assets/img/clientsImg/or.png';
import syqe from '../assets/img/clientsImg/syqe.jpg';
import wecheck from '../assets/img/clientsImg/wecheck.png';
import meller from '../assets/img/clientsImg/meller.png';
import zoom from '../assets/img/clientsImg/zoom.png';
import Bezalel from '../assets/img/clientsImg/bezales.png';
import Giraffe from '../assets/img/clientsImg/giraffe.png';
import Holon from '../assets/img/clientsImg/holon.png';
import Rosh from '../assets/img/clientsImg/rosh.png';
import logz from '../assets/img/clientsImg/logz.png';
import a11 from '../assets/img/clientsImg/a11.png';
import safsar from '../assets/img/clientsImg/safsar.png';
import elementix from '../assets/img/clientsImg/elementix.png';
import antiq from '../assets/img/clientsImg/antiq.png';
import parks from '../assets/img/clientsImg/parks.png';
import ministri from '../assets/img/clientsImg/ministri.png';
import opa from '../assets/img/clientsImg/opa.png';
import nope from '../assets/img/clientsImg/nope.png';


export const clients = {
    en: [
        {
            "category": "government",
            "companyName": "Holon municipality",
            "description": "Development of the Holon municipality website",
            "img1": Holon,
            "order": 0,
            "website": "https://www.holon.muni.il/Pages/default.aspx"
        },
        {
            "category": "government",
            "companyName": "Rosh-haayin municipality",
            "description": "CRM development and support in automation processes",
            "img1": Rosh,
            "order": 1,
            "website": "https://www.rosh-haayin.muni.il/"
        },
        {
            "category": "government",
            "companyName": "Ministry of Heritage",
            "description": "Website development",
            "img1": ministri,
            "order": 2,
            "website": ""
        },
        {
            "category": "government",
            "companyName": "Israel Nature and Parks Authority",
            "description": "Website development",
            "img1": parks,
            "order": 3,
            "website": ""
        },
        {
            "category": "government",
            "companyName": "Israel Antiquities Authority",
            "description": "Website development",
            "img1": antiq,
            "order": 4,
            "website": ""
        },
        {
            "category": "startup",
            "companyName": "Keshet12",
            "description": "A promotional app for the leading TV channel in Israel",
            "img1": keshet,
            "img2": keshetBw,
            "order": 5,
            "website": "https://www.mako.co.il/tv"
        },
        {
            "category": "startup",
            "companyName": "Quik",
            "description": "Development of an online store for the sale of consumer products",
            "img1": quik,
            "img2": quikCoIl,
            "order": 6,
            "website": "https://quik.co.il/"
        },
        {
            "category": "startup",
            "companyName": "Eco99FM",
            "description": "Web, Mobile, Car and TV applications for Israeli radio station",
            "img1": eco99,
            "img2": eco99,
            "order": 7,
            "website": "https://eco99fm.maariv.co.il/"
        },
        {
            "category": "startup",
            "companyName": "Syqe",
            "description": "CRM development and support in automation processes for marketing and process management with the brand's customers",
            "img1": syqe,
            "order": 8,
            "website": "https://www.syqe.com/"
        },
        {
            "category": "startup",
            "companyName": "OPA",
            "description": "Development and maintain OPA systems",
            "img1": opa,
            "order": 9,
            "website": "https://www.opaglobal.io/"
        },
        {
            "category": "",
            "companyName": "Elemetix",
            "description": "Startup by Productive - full development",
            "img1": elementix,
            "order": 10,
            "website": ""
        },
        {
            "category": "startup",
            "companyName": "SafsarX",
            "description": "Web and Mobile full development",
            "img1": safsar,
            "order": 11,
            "website": ""
        },
        {
            "category": "startup",
            "companyName": "The pantheon",
            "description": "Web and Mobile full development ",
            "img1": nope,
            "order": 12,
            "website": ""
        },
        {
            "category": "startup",
            "companyName": "The Audience Store",
            "description": "",
            "img1": a11,
            "order": 13,
            "website": "https://audience11.com/"
        },
        {
            "category": "startup",
            "companyName": "logz.io",
            "description": "",
            "img1": logz,
            "order": 14,
            "website": "https://logz.io/"
        },
        {
            "category": "finance",
            "companyName": "OBLI",
            "description": "B2C fintech startup, which provides digital guarantees with fully digital process. ",
            "img1": obli,
            "order": 15,
            "website": "https://obli.co.il/"
        },
        {
            "category": "finance",
            "companyName": "WeCheck",
            "description": "A digital platform for checking check coverage and background checks for apartment renters",
            "img1": wecheck,
            "order": 16,
            "website": "https://wecheck.news/"
        },
        {
            "category": "finance",
            "companyName": "OR Insurance",
            "description": "Development of a digital system for insurance products - car, mortgage, travel.",
            "img1": or,
            "order": 17,
            "website": "https://or-ins.co.il/"
        },
        {
            "category": "finance",
            "companyName": "Blue giraffe",
            "description": "CRM development and support in automation processes for marketing and process management with the brand's",
            "img1": Giraffe,
            "order": 18,
            "website": "https://www.bluegiraffe.co.il/"
        },
        {
            "category": "finance",
            "companyName": "Productive Finance",
            "description": "Startup by Productive - full development",
            "img1": nope,
            "order": 19,
            "website": "https://productive.finance/"
        },
        {
            "category": "education",
            "companyName": "Bezalel Are Academy",
            "description": "Development of the Bezalel Art Academy website",
            "img1": Bezalel,
            "order": 20,
            "website": "https://www.bezalel.ac.il/"
        },
        {
            "category": "education",
            "companyName": "Shenkar",
            "description": "Development of the Shenkar Student Association website",
            "img1": shenkar,
            "order": 21,
            "website": "https://www.myaguda.co.il/"
        },
        {
            "category": "education",
            "companyName": "Kiryat Ono Student Association",
            "description": "Development of the Kiryat Ono Student Association website",
            "img1": kiryat,
            "order": 22,
            "website": "https://onostudent.co.il/"
        },
        {
            "category": "education",
            "companyName": "IDC",
            "description": "Development of the IDC Student Association website",
            "img1": idc,
            "order": 23,
            "website": "https://www.runi.ac.il/"
        },
        {
            "category": "education",
            "companyName": "Digital studio ",
            "description": "Development of a system of courses for acting teaching studio",
            "img1": digital,
            "order": 24,
            "website": "https://digitalstudio.co.il/"
        },
        {
            "category": "ecommerce",
            "companyName": "Flying Carpet ",
            "description": "Development of an internal order management system",
            "img1": carpet,
            "order": 25,
            "website": "https://www.flying.co.il/"
        },
        {
            "category": "ecommerce",
            "companyName": "Meshek Meller",
            "description": "Digital store for the purchase of fruits, vegetables and pantry products",
            "img1": meller,
            "order": 26,
            "website": "https://meshekmeller.co.il/"
        },
        {
            "category": "ecommerce",
            "companyName": "Sea time ",
            "description": "Development of a website for ordering yachts in a variety of destinations around the world",
            "img1": sea,
            "order": 27,
            "website": "https://sea-time.co.il/"
        },
        {
            "category": "ecommerce",
            "companyName": "Pro-Center",
            "description": "Development of an online store for an Israeli care and hair brand",
            "img1": proCenter,
            "order": 28,
            "website": "https://procenter.co.il/"
        },
        {
            "category": "ecommerce",
            "companyName": "Heel-Free",
            "description": "Development of an online store for an Israeli insole brand",
            "img1": heel,
            "order": 29,
            "website": "https://heel-free.com/"
        },
        {
            "category": "ecommerce",
            "companyName": "Canzon",
            "description": "Development of an online store for a global CBD brand",
            "img1": canzon,
            "order": 30,
            "website": "https://www.canzon.com/"
        },
        {
            "category": "ecommerce",
            "companyName": "By Morano",
            "description": "Development of an online store for an Israeli fashion brand",
            "img1": mornao,
            "order": 31,
            "website": "https://bymorano.com/"
        },
        {
            "category": "other",
            "companyName": "Idan Ofer",
            "description": "Website development",
            "img1": nope,
            "order": 32,
            "website": ""
        },
        {
            "category": "other",
            "companyName": "Fitnuts",
            "description": "Development of a digital platform for sports and nutrition training",
            "img1": fitnuts,
            "order": 33,
            "website": "https://fitnuts.com/"
        },
        {
            "category": "other",
            "companyName": "Zoom REI",
            "description": "Establishment of an information system for property management and real estate",
            "img1": zoom,
            "order": 34,
            "website": "https://www.zoom-rei.com/"
        },
        {
            "category": "other",
            "companyName": "ASAL",
            "description": "Development of an Internet system for recruiting and managing foreign workers in Israel",
            "img1": asal,
            "order": 35,
            "website": ""
        }
    ],
    he: [
        {
            "category": "government",
            "companyName": "עירית חולון",
            "description": "פיתוח אתר לעירית חולון",
            "img1": "https://www.holon.muni.il/Pages/default.aspx",
            "order": 0,
            "website": "https://www.holon.muni.il/Pages/default.aspx"
        },
        {
            "category": "government",
            "companyName": "עיירית ראש העין ",
            "description": "פיתוח ותמיכה בתהליכי אוטומציה",
            "img1": Rosh,
            "order": 1,
            "website": "https://www.rosh-haayin.muni.il/"
        },
        {
            "category": "government",
            "companyName": "משרד המורשת",
            "description": "Website development",
            "img1": ministri,
            "order": 2,
            "website": ""
        },
        {
            "category": "government",
            "companyName": "רשות הטבע והגנים",
            "description": "Website development",
            "img1": parks,
            "order": 3,
            "website": ""
        },
        {
            "category": "government",
            "companyName": "רשות העתיקות",
            "description": "Website development",
            "img1": antiq,
            "order": 4,
            "website": ""
        },
        {
            "category": "startup",
            "companyName": "קשת 12",
            "description": "אפליקציית קידום מכירות לערוץ הטלויזיה המוביל בישראל",
            "img1": keshet,
            "order": 5,
            "website": "https://www.mako.co.il/tv"
        },
        {
            "category": "startup",
            "companyName": "Quik",
            "description": "פיתוח חנות אינטרנטית למכירת מוצרי צריכה",
            "img1": quik,
            "order": 6,
            "website": "https://quik.co.il/"
        },
        {
            "category": "startup",
            "companyName": "רדיו 99",
            "description": "אפליקציות אינטרנט, מובייל, רכב וטלוויזיה לתחנת רדיו ישראלית",
            "img1": eco99,
            "order": 7,
            "website": "https://eco99fm.maariv.co.il/"
        },
        {
            "category": "startup",
            "companyName": "Syqe",
            "description": "פיתוח CRM ותמיכה בתהליכי אוטומציה למרקטינג וניהול תהליכים מול לקוחות המותג",
            "img1": syqe,
            "order": 8,
            "website": "https://www.syqe.com/"
        },
        {
            "category": "startup",
            "companyName": "OPA",
            "description": "Development and maintain OPA systems",
            "img1": opa,
            "order": 9,
            "website": "https://www.opaglobal.io/"
        },
        {
            "category": "startup",
            "companyName": "Elemetix",
            "description": "Startup by Productive - full development",
            "img1": elementix,
            "order": 10,
            "website": ""
        },
        {
            "category": "startup",
            "companyName": "ספסר X",
            "description": "Web and Mobile full development",
            "img1": safsar,
            "order": 11,
            "website": ""
        },
        {
            "category": "startup",
            "companyName": "הפנתאון",
            "description": "Web and Mobile full development ",
            "img1": nope,
            "order": 12,
            "website": ""
        },
        {
            "category": "startup",
            "companyName": "The Audience Store",
            "description": "",
            "img1": a11,
            "order": 13,
            "website": "https://audience11.com/"
        },
        {
            "category": "finance",
            "companyName": "logz.io",
            "description": "",
            "img1": logz,
            "order": 14,
            "website": "https://logz.io/"
        },
        {
            "category": "finance",
            "companyName": "OBLI",
            "description": "סטארט-אפ פינטק B2C , המספק ערבויות דיגיטליות בתהליך דיגיטלי מלא",
            "img1": obli,
            "order": 15,
            "website": "https://obli.co.il/"
        },
        {
            "category": "finance",
            "companyName": "WeCheck",
            "description": "פלטפורמה דיגיטלית לבדיקת כיסוי צקים ובדיקות רקע למשכירי דירות",
            "img1": wecheck,
            "order": 16,
            "website": "https://wecheck.news/"
        },
        {
            "category": "finance",
            "companyName": "אור ביטוחים",
            "description": "פיתוח מערכת דיגיטלית למוצרי ביטוח - רכב, משכנתא, נסיעות לחו״ל. ",
            "img1": or,
            "order": 17,
            "website": "https://or-ins.co.il/"
        },
        {
            "category": "finance",
            "companyName": "בלו ג׳ירף",
            "description": "פיתוח ותמיכה בתהליכי אוטומציה לשיווק וניהול תהליכים של המותג",
            "img1": Giraffe,
            "order": 18,
            "website": "https://www.bluegiraffe.co.il/"
        },
        {
            "category": "finance",
            "companyName": "פרודקטיב פיננסים",
            "description": "Startup by Productive - full development",
            "img1": nope,
            "order": 19,
            "website": "https://productive.finance/"
        },
        {
            "category": "education",
            "companyName": "בצלאל אקדמיה לאומנות",
            "description": "פיתוח אתר לבצלאל אקדמיה לאומנות",
            "img1": Bezalel,
            "order": 20,
            "website": "https://www.bezalel.ac.il/"
        },
        {
            "category": "education",
            "companyName": "שנקר",
            "description": "פיתוח אתר אגודת הסטודנטים של שנקר",
            "img1": shenkar,
            "order": 21,
            "website": "https://www.myaguda.co.il/"
        },
        {
            "category": "education",
            "companyName": "אגודת הסטודנטים קרית אונו ",
            "description": "פיתוח אתר אגודת הסטודנטים של קרית אונו",
            "img1": kiryat,
            "order": 22,
            "website": "https://onostudent.co.il/"
        },
        {
            "category": "education",
            "companyName": "הבינתחומי הרצליה",
            "description": "פיתוח אתר אגודת הסטודנטים של מכללת הבינתחומי",
            "img1": idc,
            "order": 23,
            "website": "https://www.runi.ac.il/"
        },
        {
            "category": "education",
            "companyName": "הסטודיו הדיגיטלי ",
            "description": "פיתוח מערכת קורסים לסטודיו למשחק של גל אמיתי",
            "img1": digital,
            "order": 24,
            "website": "https://digitalstudio.co.il/"
        },
        {
            "category": "ecommerce",
            "companyName": "השטיח המעופף ",
            "description": "פיתוח מערכת פנימית לניהול הזמנות",
            "img1": carpet,
            "order": 25,
            "website": "https://www.flying.co.il/"
        },
        {
            "category": "ecommerce",
            "companyName": "משק מלר",
            "description": "חנות דיגיטלית לרכישת פירות, ירקות ומוצרי מזווה",
            "img1": meller,
            "order": 26,
            "website": "https://meshekmeller.co.il/"
        },
        {
            "category": "ecommerce",
            "companyName": "Sea time ",
            "description": "פיתוח אתר אינטרנט להזמנת יאכטות במגוון יעדים בעולם ",
            "img1": sea,
            "order": 27,
            "website": "https://sea-time.co.il/"
        },
        {
            "category": "ecommerce",
            "companyName": "Pro-Center",
            "description": "פיתוח חנות אינטרנטית למותג טיפוח ושיער ישראלי",
            "img1": proCenter,
            "order": 28,
            "website": "https://procenter.co.il/"
        },
        {
            "category": "ecommerce",
            "companyName": "Heel-Free",
            "description": "פיתוח חנות אינטרנטית למותג מדרסים ישראלי",
            "img1": heel,
            "order": 29,
            "website": "https://heel-free.com/"
        },
        {
            "category": "ecommerce",
            "companyName": "Canzon",
            "description": "פיתוח חנות אינטרנטית למותג CBD עולמי",
            "img1": canzon,
            "order": 30,
            "website": "https://www.canzon.com/"
        },
        {
            "category": "ecommerce",
            "companyName": "By Morano",
            "description": "פיתוח חנות אינטרנטית למותג אופנה ישראלי",
            "img1": mornao,
            "order": 31,
            "website": "https://bymorano.com/"
        },
        {
            "category": "other",
            "companyName": "עידן ובתיה עופר",
            "description": "Website development",
            "img1": nope,
            "order": 32,
            "website": ""
        },
        {
            "category": "other",
            "companyName": "Fitnuts",
            "description": "פיתוח פלטפורמה דיגיטלית לאימוני ספורט ותזונה",
            "img1": fitnuts,
            "order": 33,
            "website": "https://fitnuts.com/"
        },
        {
            "category": "other",
            "companyName": "Zoom REI",
            "description": "הקמת מערכת מידע לניהול נכסים ונדלן",
            "img1": zoom,
            "order": 34,
            "website": "https://www.zoom-rei.com/"
        },
        {
            "category": "other",
            "companyName": "יותר קל",
            "description": "פיתוח מערכת אינטרנטית לגיוס וניהול עובדים זרים בישראל",
            "img1": asal,
            "order": 35,
            "website": ""
        }
    ],
    ru: [
        {
            "category": "government",
            "companyName": "Holon municipality",
            "description": "Разработка веб-сайта муниципалитета Холона",
            "img1": Holon,
            "order": 0,
            "website": "https://www.holon.muni.il/Pages/default.aspx"
        },
        {
            "category": "government",
            "companyName": "Rosh-haayin municipality",
            "description": "Разработка и поддержка CRM в процессах автоматизации",
            "img1": Rosh,
            "order": 1,
            "website": "https://www.rosh-haayin.muni.il/"
        },
        {
            "category": "government",
            "companyName": "Ministry of Heritage",
            "description": "Разработка сайта",
            "img1": ministri,
            "order": 2,
            "website": ""
        },
        {
            "category": "government",
            "companyName": "Israel Nature and Parks Authority",
            "description": "Разработка сайта",
            "img1": parks,
            "order": 3,
            "website": ""
        },
        {
            "category": "government",
            "companyName": "Israel Antiquities Authority",
            "description": "Разработка сайта",
            "img1": antiq,
            "order": 4,
            "website": ""
        },
        {
            "category": "startup",
            "companyName": "Keshet12",
            "description": "Рекламное приложение для ведущего телеканала Израиля.",
            "img1": keshet,
            "order": 5,
            "website": "https://www.mako.co.il/tv"
        },
        {
            "category": "startup",
            "companyName": "Quik",
            "description": "Разработка интернет-магазина по продаже товаров народного потребления",
            "img1": quik,
            "order": 6,
            "website": "https://quik.co.il/"
        },
        {
            "category": "startup",
            "companyName": "Eco99FM",
            "description": "Интернет, мобильное, автомобильное и телевизионное приложения для израильской радиостанции",
            "img1": eco99,
            "order": 7,
            "website": "https://eco99fm.maariv.co.il/"
        },
        {
            "category": "startup",
            "companyName": "Syqe",
            "description": "Разработка CRM и поддержка процессов автоматизации маркетинга и управления процессами с клиентами бренда",
            "img1": syqe,
            "order": 8,
            "website": "https://www.syqe.com/"
        },
        {
            "category": "startup",
            "companyName": "OPA",
            "description": "Разработка и поддержка OPA-систем.",
            "img1": opa,
            "order": 9,
            "website": "https://www.opaglobal.io/"
        },
        {
            "category": "startup",
            "companyName": "Elemetix",
            "description": "Startup by Productive - full development",
            "img1": elementix,
            "order": 10,
            "website": ""
        },
        {
            "category": "startup",
            "companyName": "SafsarX",
            "description": "Разработка сайта  мобильного приложения",
            "img1": safsar,
            "order": 11,
            "website": ""
        },
        {
            "category": "startup",
            "companyName": "The pantheon",
            "description": "Разработка сайта  мобильного приложения",
            "img1": nope,
            "order": 12,
            "website": ""
        },
        {
            "category": "startup",
            "companyName": "The Audience Store",
            "description": "",
            "img1": a11,
            "order": 13,
            "website": "https://audience11.com/"
        },
        {
            "category": "startup",
            "companyName": "logz.io",
            "description": "",
            "img1": logz,
            "order": 14,
            "website": "https://logz.io/"
        },
        {
            "category": "finance",
            "companyName": "OBLI",
            "description": "Финтех-стартап B2C, который предоставляет цифровые гарантии с полностью цифровым процессом.",
            "img1": obli,
            "order": 15,
            "website": "https://obli.co.il/"
        },
        {
            "category": "finance",
            "companyName": "WeCheck",
            "description": "Цифровая платформа для проверки охвата чеками и проверки биографических данных арендаторов квартир",
            "img1": wecheck,
            "order": 16,
            "website": "https://wecheck.news/"
        },
        {
            "category": "finance",
            "companyName": "OR Insurance",
            "description": "Разработка цифровой системы для страховых продуктов - авто, ипотека, путешествия.",
            "img1": or,
            "order": 17,
            "website": "https://or-ins.co.il/"
        },
        {
            "category": "finance",
            "companyName": "Blue giraffe",
            "description": "Разработка CRM и поддержка процессов автоматизации маркетинга и управления процессами с брендом",
            "img1": Giraffe,
            "order": 18,
            "website": "https://www.bluegiraffe.co.il/"
        },
        {
            "category": "finance",
            "companyName": "Productive Finance",
            "description": "Startup by Productive - full development",
            "img1": nope,
            "order": 19,
            "website": "https://productive.finance/"
        },
        {
            "category": "education",
            "companyName": "Bezalel Are Academy",
            "description": "Разработка веб-сайта Bezalel Art Academy",
            "img1": Bezalel,
            "order": 20,
            "website": "https://www.bezalel.ac.il/"
        },
        {
            "category": "education",
            "companyName": "Shenkar",
            "description": "Разработка сайта студенческой ассоциации «Шенкар»",
            "img1": shenkar,
            "order": 21,
            "website": "https://www.myaguda.co.il/"
        },
        {
            "category": "education",
            "companyName": "Kiryat Ono Student Association",
            "description": "Разработка веб-сайта Студенческой ассоциации Кирьят-Оно",
            "img1": kiryat,
            "order": 22,
            "website": "https://onostudent.co.il/"
        },
        {
            "category": "education",
            "companyName": "IDC",
            "description": "Разработка сайта студенческой ассоциации IDC",
            "img1": idc,
            "order": 23,
            "website": "https://www.runi.ac.il/"
        },
        {
            "category": "education",
            "companyName": "Digital studio",
            "description": "Разработка системы курсов для студии актерского мастерства",
            "img1": digital,
            "order": 24,
            "website": "https://digitalstudio.co.il/"
        },
        {
            "category": "ecommerce",
            "companyName": "Flying Carpet ",
            "description": "Разработка внутренней системы управления заказами",
            "img1": carpet,
            "order": 25,
            "website": "https://www.flying.co.il/"
        },
        {
            "category": "ecommerce",
            "companyName": "Meshek Meller",
            "description": "Цифровой магазин для покупки фруктов, овощей и кладовых продуктов",
            "img1": meller,
            "order": 26,
            "website": "https://meshekmeller.co.il/"
        },
        {
            "category": "ecommerce",
            "companyName": "Sea time",
            "description": "Разработка сайта для заказа яхт в различных локациях по всему миру",
            "img1": sea,
            "order": 27,
            "website": "https://sea-time.co.il/"
        },
        {
            "category": "ecommerce",
            "companyName": "Pro-Center",
            "description": "Development of an online store for an Israeli care and hair brand",
            "img1": proCenter,
            "order": 28,
            "website": "https://procenter.co.il/"
        },
        {
            "category": "ecommerce",
            "companyName": "Heel-Free",
            "description": "Разработка интернет-магазина для израильского фэшн бренда",
            "img1": heel,
            "order": 29,
            "website": "https://heel-free.com/"
        },
        {
            "category": "ecommerce",
            "companyName": "Canzon",
            "description": "Разработка интернет-магазина для мирового бренда CBD",
            "img1": canzon,
            "order": 30,
            "website": "https://www.canzon.com/"
        },
        {
            "category": "ecommerce",
            "companyName": "By Morano",
            "description": "",
            "img1": mornao,
            "order": 31,
            "website": "https://bymorano.com/"
        },
        {
            "category": "other",
            "companyName": "Idan Ofer",
            "description": "Разработка сайта",
            "img1": nope,
            "order": 32,
            "website": ""
        },
        {
            "category": "other",
            "companyName": "Fitnuts",
            "description": "Разработка цифровой платформы для тренировок по спорту и питанию",
            "img1": fitnuts,
            "order": 33,
            "website": "https://fitnuts.com/"
        },
        {
            "category": "startup",
            "companyName": "Zoom REI",
            "description": "Создание информационной системы по управлению имуществом и недвижимостью",
            "img1": zoom,
            "order": 34,
            "website": "https://www.zoom-rei.com/"
        },
        {
            "category": "other",
            "companyName": "ASAL",
            "description": "Разработка интернет-системы для найма и управления иностранными работниками в Израиле",
            "img1": asal,
            "order": 35,
            "website": ""
        }
    ]

};
