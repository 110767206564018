import React, {useState} from 'react';
import styled from "styled-components";

import whiteArrow from '../../assets/img/white-arrow.svg';
import purpleArrow from '../../assets/img/purple-arrow.svg';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import arrow from "../../assets/img/Arrow-Right.svg";
import useDirection from "../../hooks/useDirection";

const NavbarBtnStyled = styled.button`
    color: var(--white);
    background-color: var(--purple1);
    border: 1.5px solid var(--purple1);
    display: flex;
    border-radius: 72px;
    align-items: center;
    padding: 5px 20px;
    width: fit-content;
    align-self: center;
    min-height: 48px;
    font-size: 18px;
    line-height: 36px;
    font-weight: 400;
    min-width: 240px;
    cursor: pointer;
    box-shadow: 0px 10px 40px -10px #6529BD;
    transition: all .25s;
  
    &:hover {
      background: transparent;
      color: var(--purple1);
    }
    
    .btn-content{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
      img {
        width:32px;
        height: 32px;
        border-radius: 50px;
        background-color: var(--white);
        padding:5px;
        transform: ${({isRtl}) => isRtl ? "rotate(180deg)" : "unset"};
        ${({isRtl}) => isRtl ? "margin-right" : "margin-left"}: 20px;
      }
    }
`;

const MobileContactBtn = ({link}) => {

    const {t} = useTranslation();
    const {isRtl} = useDirection();

    return (
        <NavbarBtnStyled type="submit" isRtl={isRtl}>
            <Link to={link} className="btn-content">
                {t("btn.more_info")}
                <img src={arrow} alt="" />
            </Link>
        </NavbarBtnStyled>
    );
};

export default MobileContactBtn;
