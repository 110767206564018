import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {clients} from "../../data/clientsData";
import {salesforceServiceCards} from "../../data/salesforceServiceCards";
import {mondayServiceCards} from "../../data/mondayServiceCards";
import {useParams} from "react-router-dom";
import {SectionTitle} from "./index";
import {informationSystemServiceCards} from "../../data/informationSystemServiceCards";

export default function Services() {
    const { t, i18n } = useTranslation();
    const {subpage} = useParams();
    const cardsName = subpage === 'monday' ? mondayServiceCards : subpage === 'salesforce' ? salesforceServiceCards : informationSystemServiceCards;
    const renderServices = cardsName[i18n.language.substring(0,2)] || clients.en;

    const ServicesCards = styled.div`
      width: 100%;
      max-width: 70%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 84px;
      justify-content: space-between;
      @media screen and (max-width: 1280px) {
        max-width: 80%;
      }
      @media screen and (max-width: 960px) {
        max-width: 90%;
      }
      @media screen and (max-width: 769px) {
        margin-top: 34px;
      }
    `
    const Services = styled.div`
      display: flex;
      background-color: #F7F7F7;
      padding-top: 65px;
      padding-bottom: 165px;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 769px) {
        padding-top: 40px;
        padding-bottom: 63px;
      }
    `
    const ServicesCard = styled.div`
      width: 49%;
      background: #FFFFFF;
      box-shadow: 0px 0px 8px 0px #0000000F;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;
      justify-content: flex-start;
      padding: 38px 0 30px 0;
      .img-container {
        width: 100%;
        display: flex;
        position: relative;
        justify-content: center;
        z-index: 1;

        .img {
          box-shadow: 0px 0px 4px 0px #00000040;
          padding: 22px;
          border-radius: 8px;
          margin-bottom: 23px;
          background: #FFFFFF;
          img {
            width: 71px;
            height: 71px;
          }
        }

        .background {
          position: absolute;
          width: 100%;
          z-index: -1;
          top: 50%;
          background: rgba(145, 70, 255, 0.3);
          height: 1px;
        }
      }
      .text-container {
        width: 90%;
        text-transform: capitalize;
        color: #333333;
        .title {
          font-weight: 700;
          font-size: 22px;
          line-height: 31px;
          text-transform: capitalize;
          text-align: center;
          margin-bottom: 27px;
        }
        .desc {
          font-weight: 300;
          font-size: 18px;
          line-height: 25px;
          text-align: center;
        }
      }
      @media screen and (max-width: 769px) {
        padding: 25px 0 32px 0;
        width: 100%;
        .text-container
          .desc {
          font-size: 16px;
          line-height: 22px;
        }
      }
      .img-container {
        .img {
          padding: 16px;
          margin-bottom: 24px;
          img {
            width: 52px;
            height: 52px;
          }
        }
      }
    `

    return <Services>
        <SectionTitle title={t("InformationSystem.sections.services.heading")}/>
        <ServicesCards className="cards">
            {renderServices.map((service, index) => (
                    <ServicesCard key={index} className="card">
                        <div className="img-container">
                            <div className="img">
                                <img src={service.img} alt={service.title} />
                            </div>
                            <div className="background"></div>
                        </div>
                        <div className="text-container">
                            <div className="title">
                                {service.title}
                            </div>
                            <div className="desc">
                                {service.description}
                            </div>
                        </div>
                    </ServicesCard>
                ))}
        </ServicesCards>
    </Services>
}