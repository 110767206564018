import Heading from "components/Pages/Heading";
import {Trans, useTranslation} from "react-i18next";
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";
import React from "react";
import {useParams} from "react-router-dom";
import usePartColor from "./hooks/usePartColor";
import {Link} from "react-router-dom";
import useDirection from "../../hooks/useDirection";

const WelcomeTopStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 880px;
    height: 100%;
    padding-bottom: 179px;
    direction: ltr; // temporary fix for the arabic version
    .content {
      direction:  ${({isRtl}) => isRtl ? 'rtl' : 'ltr'};
      margin-right:  ${({isRtl, page}) => isRtl && page === "InformationSystem" ? '100%' : 'unset'};
      display: flex;
      margin-top: 117px;
      width: 90%;
      .title {
        font-size: 56px;
        font-weight: 700;
        line-height: 78px;
        text-transform: capitalize;
        color: #333333;
        margin-bottom: 28px;
      }
      .left-container {
        display: flex;
        flex-direction: column;
        max-width: 50%;
        width: 100%;

        .desc {
          width: 90%;
          font-size: 24px;
          font-weight: 300;
          line-height: 34px;
          color: #000000;
          text-transform: capitalize;

          p {
            margin-bottom: 1rem;
          }
        }

        .btn-container {
          margin-top: 48px;
          display: flex;
          justify-content: flex-start;

          button {
            background-color: var(--purple1);
            box-shadow: 0px 10px 40px -10px #9146ff;
            border-radius: 40px;
            border: 1px solid var(--purple1);
            padding: 19px 76px;
            color: #FFFFFF;
            font-weight: 700;
            font-size: 20px;
            line-height: 23px;
            text-transform: uppercase;

            :hover {
              color: var(--purple1);
              background-color: #FFFFFF;
            }
          }
        }
      }
      .right-container-absolute {
        max-width: 50%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
      }
      .right-container {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
      }
    }
  @media screen and (max-width: 1280px) {
    padding-bottom: 100px;
    .content {
      margin-top: 50px;
    }
  }
  @media screen and (max-width: 960px) {
    padding-bottom: 60px;
    min-height: 100%;
    .content {
      margin-top: 25px;
      width: 90%;
      .title {
        font-size: 32px;
        font-weight: 900;
        line-height: 45px;
        text-align: left;
        margin-bottom: 0;
      }
      .left-container {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        .desc {
          width: 100%;
          color: #1A1A1A;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          margin-top: 24px;
        }
        .btn-container {
          margin-top: 30px;
          justify-content: center;
          button {
            padding: 10px 46px;
            font-size: 16px;
          }
        }
      }
    }
`

export default function WelcomeTop({handleScrollToElement}) {
    const screenSize = useWindowSize().width;
    const { t } = useTranslation();
    const {subpage} = useParams();
    const page = subpage || 'InformationSystem';
    const isRTL = useDirection().isRtl;
    return <WelcomeTopStyled isRtl={isRTL} page={page}>
        <Heading heading={t(`${page}.heading`)} />
        <div className="content">
            <div className="left-container">
                <div className="title">{usePartColor(t(`${page}.title`), "var(--purple1)")}</div>
                <div className="desc">
                    <Trans>{page}.description</Trans>
                </div>
                <div className="btn-container">
                    {screenSize > 960 ? <button onClick={() => handleScrollToElement()}>{t("btn.contact-expert")}</button> :
                    <Link to="/contacts">
                        <button>{t("btn.contact-expert")}</button>
                    </Link>}
                </div>
            </div>
            {screenSize > 960 && <div className={page === "InformationSystem" ? 'right-container-absolute' : 'right-container'}>
                <img src={t(`${page}.img`)} alt="Information system img"/>
            </div> }
        </div>
    </WelcomeTopStyled>

}
