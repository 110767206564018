import styled from "styled-components";

export const Heading = styled.h1`
    font-size: 48px;/* 56px */
    line-height: 4.9rem;/* 78px */
    font-weight: bold;
    color: var(--purple1);
    @media screen and (max-width: 960px) {
      font-weight: bold;
      font-size: 40px;
      line-height: 140%;
      color: #000000;
    }
`;

export const Heading1 = styled.h1`
    font-size:2.5rem;
    line-height:3.5rem;
    font-weight:700;

`;
export const Heading2 = styled.h2`
    font-size:2rem;
    line-height:2.75rem;
    font-weight:700;
`;
export const Heading3 = styled.h3`
    font-size:1rem;
    line-height:1rem;
    font-weight:700;
`;

export const Body1 = styled.div`
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;
    p {
      margin-bottom: 1rem;
    }
    .title {
      margin-bottom: 0;
      font-weight: bold;
      display: block;
    }
`;

export const Body2 = styled.p`
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 400;
`;

export const SmallText = styled.p`
    font-size: .875rem;
    line-height: 1.3125rem;
    font-weight: 400;
`;

export const Caption = styled.p`
    font-size: .75rem;
    font-weight: 700;
    line-height: 1rem;
    color: #a9a9a9;
    text-transform: uppercase;
`;

export const HeadlineP = styled.p`
    font-weight: bold;
    font-size: 20px;
    line-height: 67%;
    text-transform: uppercase;
    color: var(--grey00);
    text-align: center;
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
`;

export const HeadlineH1 = styled.h1`
    font-weight: bold;
    font-size: 2.9vw;
    line-height: 140%;
    text-align: center;
    color: var(--grey1);
    margin-bottom: ${props => props.comp === 'careers' ? '2.5rem' : '3.75rem'} ;
`;

export const TextP = styled.h5`
    font-weight: normal;
    font-size: 1.25vw;
    line-height: 1.2;
    letter-spacing: -0.02em;
    color: var(--grey1);
    margin-bottom: 50px;
`;

export const GreyHeadline = styled.p`
    font-size: 1.25rem;
    line-height: 80%;
    color: var(--grey00);
    text-transform: uppercase;
    font-weight: bold;
    @media screen and (max-width: 500px) {
      font-size: 16px;
    }
`;

export const HeroHeadline = styled.p`
 	//width: max-content; 
    font-size: 3.75vw;
    letter-spacing: -0.06em;
    line-height: 150%;
    color: var(--grey1);
    font-weight: bold;

    @media screen and (max-width: 960px){
        font-size: 35px;
        flex-direction: row;
    }
`;
