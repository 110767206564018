import React from 'react';
import styled from "styled-components";
import useDirection from "../../hooks/useDirection";

const Icon = styled.section`
    position: absolute;
    //transform: translateY(-50%);
    // top: calc(1640px * .195 - 65px);
    // ${({rtl}) => rtl ? "left" : "right"}: ${({side}) => side === 'left' ? '-69px' : 'unset'};
    // ${({rtl}) => rtl ? "right" : "left"}: ${({side}) => side !== 'left' ? '-69px' : 'unset'};
    // width: 130px;
     aspect-ratio: 1/1;
      width: 84px;
      top: 50%;
      transform: translateY(-50%);
      ${({rtl}) => rtl ? "left" : "right"}: ${({side}) => side === 'left' ? '-52px' : 'unset'};
      ${({rtl}) => rtl ? "right" : "left"}: ${({side}) => side !== 'left' ? '-52px' : 'unset'};
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D2B7FA;
    border-radius: 50%;
    transition: all .5s;

  .iconImg {
    width: ${({side}) => (side === 'left' || side === 'top') ? '57.6923%' : '100%'};
  }

  //@media screen and (max-width: 1700px) {
  //  top: calc((100vw - 60px) * .195 - 65px);
  //}

  // @media screen and (max-width: 1500px) {
  //   width: 84px;
  //   //top: calc((100vw - 42px) * .15 - 42px);
  //   top: 50%;
  //   transform: translateY(-50%);
  //   ${({rtl}) => rtl ? "left" : "right"}: ${({side}) => side === 'left' ? '-46px' : 'unset'};
  //   ${({rtl}) => rtl ? "right" : "left"}: ${({side}) => side !== 'left' ? '-46px' : 'unset'};
  // }
  
  @media screen and (max-width: 1280px) {
    //top: calc((100vw - 60px) * .195 - 40px);
    top: 50%;
    width: 80px;
    ${({rtl}) => rtl ? "left" : "right"}: ${({side}) => side === 'left' ? '-42px' : 'unset'};
    ${({rtl}) => rtl ? "right" : "left"}: ${({side}) => side !== 'left' ? '-42px' : 'unset'};
  }

  @media screen and (max-width: 960px) {
    display: ${({side}) => side !== "top" ? "none" : "flex"};
    width: 125px;
    height: 125px;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 100%;
    background-color: #9146FF;
  }
`;

const ServiceIcon = ({icon, side}) => {
    const {isRtl} = useDirection();
    // console.log(side)
    return (
        <Icon side={side} rtl={isRtl} className="icon">
            <img src={icon} alt="" className="iconImg"/>
        </Icon>
    )
}

export default ServiceIcon
