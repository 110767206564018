import React from 'react';
import styled from "styled-components";

import ButtonMain from '../Buttons/ButtonMain';
import TheTeam from './TheTeam';
import { GreyHeadline } from '../Fonts.Style';
import { Link } from 'react-router-dom';
import useWindowSize from "../../hooks/useWindowSize";
import TheTeamMobile from "./TheTeamMobile";
import {useTranslation} from "react-i18next";

const MeetTheTeamStyled = styled.section`
    align-items: center;
    margin-bottom: 14vw;
    max-width: 100%;
    margin-top: 5rem;
    padding-bottom: 4rem;
    position: relative;
    overflow-x: hidden;

    h1{
        font-weight: bold;
        font-size: 2.448vw;
        line-height: 120%;
        letter-spacing: -0.02em;
        text-transform: capitalize;
        color: var(--purple1);
        margin-bottom: 4.8rem;
    }
  
    @media screen and (max-width: 500px) {
      margin-bottom: 5rem;
    }
`;

const MeetTheTeam = () => {

    const screenSize = useWindowSize().width;
    const {t} = useTranslation();

    return (
        <MeetTheTeamStyled className="flex column">
            <GreyHeadline style={{ marginBottom: '4rem' }}>
                {t("meet_the_team")}
            </GreyHeadline>
            {screenSize > 500 && <h1>{t("team_slogan")}</h1>}
            {screenSize > 500 ? <TheTeam /> : <TheTeamMobile />}
            {screenSize > 500 && <Link to="team">
                <ButtonMain title={t('btn.see_full_team')}
                    width="230px"
                    fontSize="1rem"
                    notBold={true}
                    height="48px"
                />
            </Link>}
        </MeetTheTeamStyled>
    );
};

export default MeetTheTeam;
