import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import useDirection from "../../hooks/useDirection";

const OurProcessAnimateStyled = styled.section`
  /* flex: 1; */
  width: 50%;
  @media screen and (max-width: 960px) {
    align-self: flex-start;
  }
`;

const Circle = styled.div`
  width: 33.85vw;
  aspect-ratio: 1 / 1;
  background: var(--grey5);
  border: 6.48356px solid #9146FF;
  border-radius: 50%;
  margin-left: ${({isRtl}) => isRtl ? 'unset' : '-20%'};
  margin-right: ${({isRtl}) => isRtl ? '-20%' : 'unset'};
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  
  @media screen and (max-width: 960px) {
    border-width: 4px;
    width: 45vh;
    margin-left: ${({isRtl}) => isRtl ? 'unset' : '-27vh'};
    margin-right: ${({isRtl}) => isRtl ? '-27vh' : 'unset'};
  }

  .ball {
    width: 3.8vw;
    aspect-ratio: 1 / 1;
    background: #9146FF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translate(-50%, -50%);
    transition: all .25s;
    opacity: 0;
    ${({isRtl}) => isRtl ? 'right' : 'left'}: -200%;
    
    @media screen and (max-width: 960px) {
      width: 45px;
    }

    .img {
      width: 60%;
      aspect-ratio: 1 / 1;
      object-fit: contain;
    }

    &.active {
      opacity: 1;
      ${({isRtl}) => isRtl ? 'right' : 'left'}: ${({isRtl}) => isRtl ? '80%' : '100%'};
      top: 50%;
      @media screen and (min-width: 960px) {
        width: 6.7vw;
      }
      @media screen and (max-width: 960px) {
        width: 79px;
      }
    }

    &.prev {
      opacity: 1;
      ${({isRtl}) => isRtl ? 'right' : 'left'}: 50%;
      top: 0;
      @media screen and (max-width: 960px) {
        left: ${({isRtl}) => isRtl ? '25%' : '75%'};
        top: 6.5%;
      }
    }

    &.next {
      opacity: 1;
      ${({isRtl}) => isRtl ? 'right' : 'left'}: 50%;
      top: 100%;
      @media screen and (min-width: 960px) {
        width: 4.675vw;
      }
      @media screen and (max-width: 960px) {
        left: ${({isRtl}) => isRtl ? '25%' : '75%'};
        top: 93.5%;
      }
    }
  }

  .title {
    display: flex;
    position: absolute;
    opacity: 0;
    transition: all .25s;
    transform: translateY(-50%);
    left: ${({isRtl}) => isRtl ? 'unset' : '100%'};
    right: ${({isRtl}) => isRtl ? '100%' : 'unset'};

    h1 {
      color: #9146FF;
      margin: 0px .8rem;
      font-weight: bold;
      font-size: 48px;
      align-self: center;
      @media screen and (max-width: 1500px) {
        font-size: 2.7vw;
      }
      @media screen and (max-width: 960px) {
        font-size: 28px;
      }
    }

    &.active {
      top: 50%;
      opacity: 1;
      //left: 110%;
      left: ${({isRtl}) => isRtl ? 'unset' : '110%'};
      right: ${({isRtl}) => isRtl ? '110%' : 'unset'};
    }

    &.prev {
      top: 0;
    }

    &.next {
      top: 100%;
    }
  }
`;


const OurProcessAnimate = ({process, activeIndex}) => {    const [current, setCurrent] = useState(activeIndex);
    useEffect(() => {
        (activeIndex !== undefined) && setCurrent(activeIndex);
    }, [activeIndex]);
    const {isRtl} = useDirection();
    return (
        <OurProcessAnimateStyled className="flex">
            <Circle isRtl={isRtl}>
                {
                    process.map((item, i) => (
                        <div key={i}>
                            <div className={current === i ? "ball active" : (current === i + 1) ? "ball prev" : (current === i - 1) ? "ball next" : "ball"}>
                                <img src={item.icon} alt="" className="img"/>
                            </div>
                            <div
                                className={current === i ? "title active" : (current === i + 1) ? "title prev" : (current === i - 1) ? "title next" : "title"}>
                                <h1>{item.name}</h1>
                            </div>
                        </div>
                    ))
                }
            </Circle>
        </OurProcessAnimateStyled>
    )
}

export default OurProcessAnimate
