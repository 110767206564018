export const hireFormOptions = {
    he: {
        1: {
            options: ['1 - 10', '10 - 50', '50 - 200', '200 +'],
            isMultiple: false,
            key: 'companySize'
        },
        2: {
            options: ['E-Commerce', 'Apps', 'B2B Tech', 'Local business', 'Consumer Tech', 'Marketing', 'אחר'],
            isMultiple: true,
            key: 'industry'
        },
        3: {
            options: ['מתכנת front-end', 'מתכנת back-end', 'מתכנת full-stack', 'מנהל פרוייקט', 'בדיקת תוכנה ', 'אנליסט', 'מהנדס DevOps'],
            isMultiple: false,
            key: 'position'
        },
        4: {
            options: ['PHP', 'NodeJS', 'ReactJS', 'Python', 'ASP.net', 'C#', 'אחר'],
            isMultiple: false,
            key: 'technology'
        },
        5: {
            options: ['כן', 'לא'],
            isMultiple: false,
            key: 'isBudgetGreater3K'
        },
        6: {
            options: ['מיידי', 'בין 1-2 שבועות', 'בין שלושה שבועות לחודש', 'אחליט מאוחר יותר'],
            isMultiple: false,
            key: 'howSoon'
        },
        7: {
            options: ['בין חודש ל-3 חודשים ', 'בין 3 חודשים לחצי שנה ', 'מעל חצי שנה'],
            isMultiple: false,
            key: 'howLong'
        },
        8: {
            options: ['משרה מלאה (40 שעות בשבוע)', 'משרה חלקית - (20 שעות בשבוע)', 'לפי שעה', 'אחר'],
            isMultiple: false,
            key: 'engagementLevel'
        },
        9: {
            options: ['כן', 'לא', 'אחליט מאוחר יותר'],
            isMultiple: false,
            key: 'remotelyFriendly'
        }
    },
    en: {
        1: {
            options: ['1 - 10', '10 - 50', '50 - 200', '200 +'],
            isMultiple: false,
            key: 'companySize'
        },
        2: {
            options: ['E-Commerce', 'Apps', 'B2B Tech', 'Local business', 'Consumer Tech', 'Marketing', 'Other'],
            isMultiple: true,
            key: 'industry'
        },
        3: {
            options: ['Frontend developer', 'Backend developer', 'Full stack developer', 'Project manager', 'QA ', 'Data analyst', 'DevOps engineer'],
            isMultiple: false,
            key: 'position'
        },
        4: {
            options: ['PHP', 'NodeJS', 'ReactJS', 'Python', 'ASP.net', 'C#', 'Other'],
            isMultiple: false,
            key: 'technology'
        },
        5: {
            options: ['Yes', 'No'],
            isMultiple: false,
            key: 'isBudgetGreater3K'
        },
        6: {
            options: ['Immediately', '1-2 weeks', '3-4 weeks', 'Decide later'],
            isMultiple: false,
            key: 'howSoon'
        },
        7: {
            options: ['1-3 months ', '3-6 months ', '6 months + '],
            isMultiple: false,
            key: 'howLong'
        },
        8: {
            options: ['Full-time (40 Hours a week)', 'Part-time (20 Hours a week)', 'Hourly', 'Other'],
            isMultiple: false,
            key: 'engagementLevel'
        },
        9: {
            options: ['Yes', 'No', 'Decide later'],
            isMultiple: false,
            key: 'remotelyFriendly'
        }
    },
    ru: {
        1: {
            options: ['1 - 10', '10 - 50', '50 - 200', '200 +'],
            isMultiple: false,
            key: 'companySize'
        },
        2: {
            options: ['E-Commerce', 'Apps', 'B2B Tech', 'Local business', 'Consumer Tech', 'Marketing', 'Другое'],
            isMultiple: true,
            key: 'industry'
        },
        3: {
            options: ['Frontend разработчик', 'Backend разработчик', 'Full stack разработчик', 'Project manager', 'Тестировщик ', 'Data analyst', 'DevOps инженер'],
            isMultiple: false,
            key: 'position'
        },
        4: {
            options: ['PHP', 'NodeJS', 'ReactJS', 'Python', 'ASP.net', 'C#', 'Другое'],
            isMultiple: false,
            key: 'technology'
        },
        5: {
            options: ['Да', 'Нет'],
            isMultiple: false,
            key: 'isBudgetGreater3K'
        },
        6: {
            options: ['Немедленно', '1-2 недели', '3-4 недели', 'Ответить позже'],
            isMultiple: false,
            key: 'howSoon'
        },
        7: {
            options: ['1-3 месяцев ', '3-6 месяцев ', 'Более 6 месяцев'],
            isMultiple: false,
            key: 'howLong'
        },
        8: {
            options: ['Полная занятость (40 часов в неделю)', 'Частичная занятость (20 часов в неделю)', 'Почасово', 'Другое'],
            isMultiple: false,
            key: 'engagementLevel'
        },
        9: {
            options: ['Да', 'Нет', 'Ответить позже'],
            isMultiple: false,
            key: 'remotelyFriendly'
        }
    }

}