export const inputsData = [
    {
        type: 'text',
        placeholder: 'Full Name',
        name: 'name',
    },
    {
        type: 'tel',
        placeholder: 'Phone Number',
        name: 'phone',
    },
    {
        type: 'email',
        placeholder: 'Email Address',
        name: 'email',
    },
];

export const inputsEmailData = [
    {
        name: '_next',
        value: 'http://localhost:3000/#/',
    },
    {
        name: '_subject',
        value: 'You have a new message from your website.',
    },
    {
        name: '_autoresponse',
        value: 'Thank you for choosing Productive.io',
    },
];