import React from 'react';
import styled from "styled-components";
import useDirection from "../../hooks/useDirection";

const CeoQuoteStyled = styled.div`
    display: flex;
    background: var(--white);
    box-shadow: 0px 10px 40px -10px rgba(51, 51, 51, 0.24);
    border-radius: 24px;
    align-items: center;
    padding: 3.646vw;
    max-width: 95%;
    min-width: 670px;
    margin: 0 auto;
    position: relative;
  direction: ${({isRtl}) => isRtl ? "rtl" : "ltr"};
  
    &:before {
      content: '';
      width: 105%;
      aspect-ratio: 1 / 1;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      z-index: 1;
      backdrop-filter: blur(4px);
      background: rgba(255,255,255,.2);
    }
  
    &:after {
      content: '';
      position: absolute;
      width: 70%;
      aspect-ratio: 1 / 1;
      border: 3px solid #9146FF;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      border-radius: 50%;
      z-index: -1;
      transition: all 0s;
    }

    .ceo-img{
        width: 157px;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        border: 3px solid var(--purple1);
        padding: 15px;
        background: white;
        img {            
            width: 100%;
            aspect-ratio: 1 / 1;
            object-fit: contain;
        }
    }

    .ceo-info{
      ${({isRtl}) => isRtl ? "margin-right" : "margin-left"}: 80px;
        flex: 1;
        h2{
            font-weight: bold;
            font-size: 2.625rem;
            line-height: 4.25rem;
        }
        p{
            font-size: 1.375rem;
            color: var(--purple1);
        }
        .quote{
            padding-top:16px ;
            color: var(--black);
            font-weight: normal;
            font-size: 1.5rem;
            line-height: 140%;
            letter-spacing: -0.02em
        }
    }
  
    .heading-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      * {
        white-space: nowrap;
      }
    }
  
  @media screen and (max-width: 1500px) {
    padding: 2.5vw;
    .ceo-img {
      width: 8.2vw;
    }
    .ceo-info {
      ${({isRtl}) => isRtl ? "margin-right" : "margin-left"}: 10%;
      h2 {
        font-size: 30px;
        line-height: 1.5;
      }
      p {
        font-size: 18px;
      }
    }
  }

  @media screen and (max-width: 960px) {
    width: 85%;
    max-width: unset;
    min-width: unset;
    .ceo-img {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 0;
      left: 50%;
      width: 90px;
    }
    .ceo-info{
      margin: 0;
      h2 {
        font-size: 2rem;
      }
    }
    .heading-wrap {
      flex-direction: column;
      padding-top: 50px;
      align-items: flex-start;
    }
    &:before {
      height: calc(100% + 100px);
    }
    .ceo-info .quote {
      font-size: 18px;
    }
  }
`;

const CeoQuote = ({ company }) => {

    const {isRtl} = useDirection();

    return (
        <CeoQuoteStyled className="company-card" isRtl={isRtl}>
            <div className="ceo-img flex">
                <img src={company.logo} alt="" />
            </div>
            <div className="ceo-info flex column">
                <div className="heading-wrap">
                    <h2>{company.testimonial.name}</h2>
                    <p>{company.testimonial.position} - {company.companyName}</p>
                </div>
                <p className='quote'>{company.testimonial.text}</p>
            </div>
        </CeoQuoteStyled>
    );
};

export default CeoQuote;
