import { useTranslation } from "react-i18next";
import styled from "styled-components";
import React from "react";
import {SectionTitle} from "../index";
import {clients} from "../../../data/clientsData";
import {salesforceProducts} from "../../../data/salesforceProducts";

const ProductsStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  background-color: #F7F7F7;
  .cards {
    display: flex;
    margin-top: 117px;
    width: 90%;
    flex-wrap: wrap;
    justify-content: center;
    .card {
      display: flex;
      flex-direction: column;
      width: 33%;
      align-items: center;
      margin-bottom: 100px;
      .text-container {
        text-transform: capitalize;
        color: #333333;
        text-align: center;
        width: 80%;
        .title {
          font-weight: 700;
          font-size: 22px;
          line-height: 31px;
          margin-bottom: 40px;
        }
        .desc {
          font-weight: 300;
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }
  @media screen and (max-width: 960px) {
    padding-top: 40px;
    .cards {
      margin-top: 50px;
      .card {
        width: 50%;
        margin-bottom: 65px;
        .text-container {
          width: 90%;
          .title {
            margin-bottom: 24px;
            font-size: 20px;
            line-height: 28px;
          }
          .desc {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
  }
    @media screen and (max-width: 500px) {
      .cards {
        .card {
          width: 100%;
          .text-container {
            width: 100%;
          }
        }
      }
`

export default function SalesforceProducts() {
    const { t, i18n } = useTranslation();
    const renderProducts = salesforceProducts[i18n.language.substring(0,2)] || clients.en;
    return <ProductsStyled>
        <SectionTitle title={t("InformationSystem.sections.products.heading")}/>
        <div className="cards">
            {renderProducts.map((product, index) => (
                <div key={index} className="card">
                    <div className="img-container">
                        <img src={product.img} alt="" />
                    </div>
                    <div className="text-container">
                        <div className="title">
                            {product.title}
                        </div>
                        <div className="desc">
                            {product.description}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </ProductsStyled>

}