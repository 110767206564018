import React from 'react';
import styled from "styled-components";
import careersImg from '../../assets/img/careers-img.png';
import {useTranslation} from "react-i18next";
import useWindowSize from "../../hooks/useWindowSize";
import {HeadlineH1} from "../Fonts.Style";
import useDirection from "../../hooks/useDirection";

const CareersInfoStyled = styled.section`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 80px;
    .heading {
      text-align: ${({isRtl}) => isRtl ? 'right' : 'left'};
    }
    .info {
      display: flex;
      flex-direction: column;
      width: 50%;
      align-items: center;
      a {
        width: 90%;
      }
    }
    p{
        font-weight: normal;
        font-size: 1.375rem;
        line-height: 140%;
        color: var(--black);
        margin-bottom:3.75rem;
    }
    img {
        width: 60%;
      object-fit: contain;
      border-radius: 8px;
      margin-top: -6%;
      margin-left: -3%;
    }
    @media screen and (max-width: 960px) {
      width: 100%;
      flex-direction: column;
      .info {
        width: 100%;
      }
      img {
        width: 100%;
        margin-top: -40px;
        transform: scale(130%);
      }
      margin-bottom: -40px;
    }
`;

const CareersInfo = () => {
    const {t} = useTranslation();
    const screensize = useWindowSize().width;
    const {isRtl} = useDirection();
    return (
        <CareersInfoStyled isRtl={isRtl}>
            <div className="info">
                {screensize > 960 && <HeadlineH1 comp="careers" className={"heading"}>{t("careers.heading")}</HeadlineH1>}
                <p>{t("careers.main_text")}</p>
                {/*{screensize > 960 &&
                    <Link   to="/positions">
                        <ButtonMain
                            title={t("btn.see_openings")}
                            padding="4px 32px" width="100%" height="58px"
                            fontSize="1.125rem" marginBottom="3.75rem"
                            notBold={true}
                        />
                    </Link>
                }*/}
            </div>

            <img src={careersImg} alt="" />
        </CareersInfoStyled>
    )
}

export default CareersInfo;
