import useWindowSize from "../../hooks/useWindowSize";
import { HeadlineP } from "../Fonts.Style";

export default function Heading({ heading }) {
  const screenSize = useWindowSize().width;

  return (
    <>
      <HeadlineP style={{ marginTop: screenSize > 960 ? "11rem" : "7rem" }}>
        {heading}
      </HeadlineP>
    </>
  );
}
