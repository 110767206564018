import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeadlineP } from '../components/Fonts.Style';
import useWindowSize from '../hooks/useWindowSize';
import styled from "styled-components";
import {news} from "../data/news";


const News = () => {
    const screenSize = useWindowSize().width;
    const { t, i18n } = useTranslation();
    const renderNews = news[i18n.language.substring(0,2)] || news.en;
    const Wrap = styled.div`
      display: flex;
      justify-content: center; 
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 0 auto;
      .title {
        font-weight: 700;
        font-size: 56px;
        line-height: 78px;
        text-transform: uppercase;
        color: #333333;
        margin: 45px auto 0 auto;
        text-align: center;
        max-width: 90%;
      }
      .cards {
        max-width: 80%;
        display: flex;
        margin: 135px -15px;
        flex-wrap: wrap;
        justify-content: center;
        .card {
          padding: 30px 0;
          flex: 1 1 auto;
          margin: 0 15px 74px 15px;
          background: #FFFFFF;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 31%;
          min-height: 747px;
          justify-content: space-between;
          a {
            color: var(--purple1);
            background-color: white;
            background-color: white;
            border: 1.5px solid #9146FF;
            box-shadow: 0px 0px 8px rgba(145, 70, 255, 0.25);
            border-radius: 40px;
            transition-duration: .25s;
            cursor: pointer;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 16px;
            padding: 8.5px 53px;
            gap: 10px;
            border-radius: 40px;
            margin-top: 30px;
            :hover {
              color: white;
              background-color: var(--purple1);
            }
          }
        }
      }
    }
    @media screen and (max-width: 1610px) {
      .cards {
        max-width: 95%;
        .card {
          margin: 0 10px 74px 10px;
        }
      }
    }
    @media screen and (max-width: 1280px) {
      .title {
        font-size: 40px;
      }
      .cards {
        margin: 45px auto 0 auto;
        max-width: 90%;
        .card {
          margin: 0 15px 74px 15px;
          width: 44%;
        }
      }
    }
      @media screen and (max-width: 769px) {
        .title {
          font-size: 32px;
          line-height: 45px;
          margin: 45px auto 0 auto;
        }
        .cards {
          margin: 45px 0;
          flex-direction: column;
          justify-content: center;
          .card {
            margin: 15px 0;
            width: 100%;
          }
      }
    `

    return <div>
        <HeadlineP style={{marginTop: screenSize > 500 ? "11rem" : "7rem"}}>
            {t("news.heading")}
        </HeadlineP>
        <Wrap>
            <div className="title">{t("news.title")}</div>
            <div className="cards">
                {renderNews.map((news, index) => (
                    <div className="card" key={index}>
                        <img src={news.logo} alt="Logo"/>
                        <img src={news.img1} alt="Img"/>
                        {news.img2 && <img src={news.img2} alt="Img"/>}
                        {news.img3 && <img src={news.img3} alt="Img"/>}
                        {news.img4 && <img src={news.img4} alt="Img"/>}
                        <a href={news.link} target="_blank"> {t("btn.read_article")}</a>
                    </div>
                ))}
            </div>
        </Wrap>
    </div>


}

export default News;