import styled from "styled-components";

// export const OuterLayout = styled.section`
//     padding: 5rem 18rem;
//     @media screen and (max-width: 1347px){
//         padding: 5rem 14rem;
//     }
//     @media screen and (max-width: 1186px){
//         padding: 5rem 8rem;
//     }
//     @media screen and (max-width: 990px){
//         padding: 5rem 4rem;
//     }

// `;

export const InnerLayout = styled.section`
    padding: 0 30px;
    width: 100%;
    max-width: 1700px;
    margin: 0 auto;
    @media (max-width: 960px){
		padding: 0 16px;
	}
`;

export const CompHeight = styled.section`
    height: 26.5rem;
`;
