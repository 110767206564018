import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import About from "./pages/About";
import Build from "./pages/Build";
import NavBar from "./components/Nav/Navbar";
import Clients from "./components/Testimonials/Clients";
import TeamPage from "./components/MeetTheTeam/TeamPage";
import Portfolio from "./pages/Portfolio";
import AppFooter from "./components/Footer/AppFooter";
import CareersPage from "./pages/CareersPage";
import Contacts from "./pages/Contacts";
import { Home } from "./pages/Home";
import "./styles/hideScroll.css";
import "animate.css/animate.min.css";
import News from "./pages/News";
import MondaySalesforce from "./pages/information-system/subpages";
import InformationSystem from "./pages/information-system";
import Hire from "./pages/Hire";

function App() {

  return (
    <Router>
      <NavBar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/portfolio" component={Portfolio} />
        <Route path="/services/:tab" component={Build} />
        <Route path="/careers" component={CareersPage} />
        {/*<Route path="/positions" component={Positions} />*/}
        {/*<Route path="/position/:id" component={PositionDetails} />*/}
        <Route path="/team" component={TeamPage} />
        <Route path="/clients" component={Clients} />
        <Route path="/about" component={About} />
        <Route path="/contacts" component={Contacts} />
        <Route path="/news" component={News} />
        <Route path="/hire/:lang" component={Hire} />
        <Route path="/hire" component={Hire} />

        {/* Information System */}
        <Route path="/information-system/:subpage" component={MondaySalesforce} />
        <Route path="/information-system" component={InformationSystem} />
        <Route exact path="*" component={Home} />
      </Switch>
      <AppFooter />
    </Router>
  );
}

export default App;
