import React from 'react';
import styled from 'styled-components/macro';

import {footerData} from '../../data/footerData';
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";

const FooterContentStyled = styled.section`
  display: flex;
  //flex: 1;
  justify-content: flex-start;
  //margin: 0 8%;

  .content-column {
    margin: 0 45px;

    .footer-category {
      color: var(--purple1);
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 16px;
    }
  }

  .content-column:last-child {
    color: #A9A9A9;
  }
  
  @media screen and (max-width: 960px) {
    justify-content: space-between;
  }
`;

const FooterContent = () => {
    const {i18n} = useTranslation();
    const renderFooterData = footerData[i18n.language.substring(0, 2)] || footerData.en;
    return (
        <FooterContentStyled>
            {
                renderFooterData.content.slice(0, renderFooterData.content.length - 1).map((category, index) => (
                    <div key={category.title}>
                        <div className="content-column flex column" key={category.title}>
                            <p className="footer-category">{category.title}</p>
                            {category.content.map((link, i) => (
                                <p key={i}>
                                    {
                                        index === 0
                                        ? renderFooterData.content[0].link[i]?.custom ? <a href="https://p.productive.co.il/blog" target="_blank" rel="noreferrer">{link}</a> : <Link to={renderFooterData.content[0].link[i]}>{link}</Link>
                                        : <span>{link}</span>
                                    }
                                </p>
                            ))}
                        </div>
                    </div>
                ))}
        </FooterContentStyled>
    );
};

export default FooterContent;
