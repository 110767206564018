import React from 'react';
import styled from "styled-components";

import aboutPrimary from "../../assets/img/aboutMain.png";
import { team } from '../../data/TeamData';
import Member from '../MeetTheTeam/Member';
import {useTranslation} from "react-i18next";

const AboutPrimaryStyled = styled.main`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  padding: 0 30px;
  margin: 5rem auto 130px;
  .about-content{
    width: 45%;
    margin-right: 15%;
    min-width: 400px;
    flex-direction: column;
    h1{
      font-size: 4.5rem;
      font-weight: 800;
      line-height: 140%;
      color: var(--purple1);
      margin-bottom: 1rem;
    }
    .about-description{
      width: 100%;
      font-weight: normal;
      font-size: 18px;
      line-height: 144%;
      color:var(--black);
      .descP {
        margin-bottom: 90px;
        p {
          margin-bottom: 1rem;
        }
      }
      //p:nth-child(2){
      //  margin-bottom: 60px;
      //}
    }
  }
  .about-img{
    flex: 1;
    align-items: flex-start;
    img {
      width: 100%;
      /* filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.18)) drop-shadow(0px 0px 2px rgba(34, 17, 34, 0.18)); */
      border-radius: 8px;
      margin-top: 25px;
    }
  }
  .team-item {
      max-width: 300px;
      margin: 0 auto;
      a  {
        display: none;
      }
      img {
        max-width: 115px;
      }
  }
  @media screen and (max-width: 960px) {
    margin-top: 50px;
    flex-direction: column;
    margin-bottom: 80px;
    .about-content {
      width: 100%;
      margin: 0;
      min-width: unset;
      h1 {
        font-size: 28px;
      }
    }
    .about-img {
      margin-top: 55px;
    }
  }
  @media screen and (max-width: 500px) {
    margin-top: 5rem;
    margin-bottom: 0;
    .about-content .about-description .descP {
      margin-bottom: 40px;
    }
    .about-img {
      margin-top: 40px;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 30px 30px 0px 0px;
      padding: 0 15px 20px;
    }
  }
`;


const AboutPrimary = () => {

  const {i18n, t} = useTranslation();
  const renderTeam = team[i18n.language] || team.en;

  const CEO = renderTeam.israel.team[0];

  return (
    <AboutPrimaryStyled>
        <div className="about-content flex">
        <h1>{t("about_us.title")}</h1>
        <div className="about-description">
          <div className={"descP"} dangerouslySetInnerHTML={{__html: t("about_us.main_text")}} />
          <Member teamMember={CEO} page="about"/>
        </div>
      </div>
      <div className="about-img flex">
        <img src={aboutPrimary} alt="" className="flex" />
      </div>
    </AboutPrimaryStyled>
  );
};

export default AboutPrimary;
