import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import ButtonMain from '../Buttons/ButtonMain';
import { Heading } from "../Fonts.Style";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useDirection from "../../hooks/useDirection";
import ButtonWhite from "../Buttons/ButtonWhite";

const OurWorkStyled = styled.section`
    min-height: calc(100vh - 80px);
    font-style:normal;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    .content {
        flex: 1;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .our-work {
          font-weight: bold;
          font-size: 16px;
          text-transform: uppercase;
          color: #BFBFBF;
          text-align: center;
          width: 100%;
          margin-bottom: 16px;
        }
        .project-type {
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
          margin-bottom: 24px;
        }
        .tags {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          position: relative;
          -webkit-animation: .5s linear 0s normal toRight;
          margin-bottom: 24px;
          ${({isRtl}) => isRtl ? "margin-right" : "margin-left"}: -4px;
        }
    }
    .heading-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: relative;
      -webkit-animation: .5s linear 0s normal toRight;
      margin-bottom: 24px;
    }
    .bottom {
      width: 100%;
      position: relative;
      margin-top: 150px;
      img {
        position: absolute;
        z-index: 2;
        bottom: 160px;
        left: 50%;
        max-width: 90%;
        transform: translateX(-50%);
        max-height: 230px;
      }
    }
    .arc {
      height: 200px;
      position: relative;
      width: 100%;
      overflow: hidden;
      &:before {
        content: '';
        position: absolute;
        width: calc(100% + 10px);
        aspect-ratio: 1 / 1;
        top: 0;
        z-index: 1;
        border: 4px solid #9146FF;
        border-radius: 100%;
        transform: translateX(-50%);
        left: 50%;
      }
    }
    .arc-link {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      animation: .5s linear 0s normal toTop;
    }
  
  .white-btn {
    font-size: 16px;
  }
  
  .btnWrap {
    margin: -65px auto 0;
  }

  @keyframes toRight {
    0% {
      left: -100%;
    }
    100% {
      left: 0;
    }
  }
  @keyframes toTop {
    0% {
      bottom: -100%;
    }
    100% {
      bottom: 30px;
    }
  }
`;

const LinkStyled = styled.a`
    color: var(--purple1);
    text-transform: capitalize;
    text-decoration: underline;
    line-height: 23px;
    font-size: 18px;
    font-weight: bold;
`;

const TagStyled = styled.p`
    color: var(--purple1);
    line-height: 21px;
    font-size: 14px;
    border: 1px solid var(--grey2);
    border-radius: 30px;
    padding: 5px 16px;
    margin: 8px 4px;
`;

const LoremStyled = styled.div`
    color: black;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 15vw;
      //overflow: hidden;
      //text-overflow: ellipsis;
      //display: -webkit-box;
      //-webkit-line-clamp: 3; /* number of lines to show */
      //line-clamp: 3;
      //-webkit-box-orient: vertical;
      overflow: hidden;
      position: relative;
      max-height: ${({isFull}) => isFull ? 'unset' : '78px'};
    p {
      margin-bottom: 0;
    }
`;

const OurWorkMobileItem = ({company}) => {

    const {t} = useTranslation();
    const {isRtl} = useDirection();
    const [isFull, setIsFull] = useState(false);
    const tagsRef = useRef(null);
    const headingWrapRef = useRef(null);

    useEffect(() => {
        tagsRef.current.style.animation = 'none';
        headingWrapRef.current.style.animation = 'none';
        setTimeout(() => {
            tagsRef.current.style.animation = '';
            headingWrapRef.current.style.animation = '';
        }, 30);
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 0);
    }, [company]);

    return (
        <OurWorkStyled className="flex" isRtl={isRtl}>
            <div className="content">
                <p className="our-work">{t("our_work")}</p>
                <div className="heading-wrap" ref={headingWrapRef}>
                    <Heading>{company.companyName}</Heading>
                    <LinkStyled href={company.website} target="_blank" rel="noopener noreferrer">
                        {t("btn.visit_site")}
                    </LinkStyled>    
                </div>
                { company.mobileTypes && <p className="project-type">{company.mobileTypes}</p> }
                <div className="tags" ref={tagsRef}>
                    {company.projectTypes.map((tag, indx) => (<TagStyled key={indx}>{tag}</TagStyled>))}
                </div>
                <LoremStyled dangerouslySetInnerHTML={{__html: company.description}} isFull={isFull}></LoremStyled>
                {!isFull && <div className="btnWrap" onClick={() => setIsFull(true)}>
                    <ButtonWhite title={t('btn.read_more')}/>
                </div>}
            </div>
            <div className="bottom">
                <img src={company.mobileImg} alt={company.name}/>
                <div className="arc">
                    <Link   to="/portfolio" className="arc-link">
                        <ButtonMain title={t("btn.see_portfolio")} minHeight={'48px'} width={"230px"} notBold={true}/>
                    </Link>
                </div>
            </div>
        </OurWorkStyled>
    );
};

export default OurWorkMobileItem;
