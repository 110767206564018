import React from 'react';
import styled from "styled-components";

const Button = styled.button`
    width: 16.125rem /* 258px -> 16.125rem */;
    max-width: 100%;
    margin: 0 auto 3rem;
    height: 40px;
    padding: 4px 2rem ;
    background: var(--grey5);
    border: 1.5px solid var(--purple1);
    box-shadow: 0px 10px 40px -10px var(--purple1);
    border-radius: 2.5rem;
    cursor: pointer;
    font-size: 1.125rem /* 18px -> 1.125rem */;
    font-family: 'Lato', sans-serif;
    text-transform: capitalize;
    line-height: 1.4375rem;
    font-weight: bold;
    /* align-self: center; */
    color: var(--black);
  transition: all .25s;

    :hover {
        background: var(--purple2);
        color: var(--white);
    }
    
    :active {
        color: var(--white);
        background: var(--purple3);
    }
  
    @media screen and (max-width: 960px) {
      background: none;
      font-size: 18px;
      line-height: 23px;
      text-decoration-line: underline;
      text-transform: capitalize;
      color: #9146FF;
      border: none;
      box-shadow: none;

      :hover {
        background: none;
        color: #9146FF;
        opacity: .8;
      }

      :active {
        color: #9146FF;
        background: none;
      }
    }
`;

const ButtonWhite = ({ title, marginTop, type, onClick, }) => {
    return (
        <Button style={{ marginTop: marginTop }} className="white-btn">
            {title}
        </Button>
    )
}

export default ButtonWhite
