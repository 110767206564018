import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import useDirection from "../../hooks/useDirection";

const BurgerWrap = styled.div`
    background-color: #9146FF;
	border-radius: 50%;
	width: 40px;
	aspect-ratio: 1 / 1;
	position: fixed;
	right: ${({ isRtl }) => isRtl ? 'unset' : '50px'};
	left: ${({ isRtl }) => isRtl ? '50px' : 'unset'};
	top: 30px;
	cursor: pointer;
	transition: all .3s;
	margin: 0 15px;
	z-index: 21;
	> span, > span::before, > span::after {
		transform: translate(-50%, -50%);
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20px;
		display: block;
		height: 2px;
		background-color: #FFF;
	}
	> span::before {
		content: '';
		top: -6px;
	}
	> span::after {
		content: '';
		top: 8px;
	}
	
	&.opened {
		background: white;
		> span::before, > span::after {
			background-color: #9146FF;
		}
		> span {
			background: white;
		}
		> span::before {
			transform: rotate(135deg);
			top: 50%;
			left: 0;
		}
		> span::after {
			transform: rotate(45deg);
			top: 50%;
			left: 0;
		}
	}
	@media screen and (max-width: 500px) {
		top: 22px;
		right: ${({ isRtl }) => isRtl ? 'unset' : '24px'};
		left: ${({ isRtl }) => isRtl ? '24px' : 'unset'};
	}
`;

const Burger = ({toggleMenu, open}) => {
	const [opened, setOpened] = useState(open);
	const ref = useRef(null);
	const spanRef = useRef(null);
	const {isRtl} = useDirection();


	useEffect(() => toggleMenu(opened), [opened]);
	useEffect(() =>  setOpened(open), [open]);

	const handleClick = () => {
		setOpened(!opened);
		const burger = ref.current;
		const span = spanRef.current;
		// burger.style.height = "2px";
		// burger.style.borderRadius = "0";
		span.style.opacity = "0";
		setTimeout(() => {
			// burger.style.height = "40px";
			// burger.style.borderRadius = "50%";
			span.style.opacity = "1";
		}, 300);
	}

	return (
		<BurgerWrap id="burger" className={opened ? 'opened' : ''} onClick={handleClick} ref={ref} isRtl={isRtl}>
			<span ref={spanRef}></span>
		</BurgerWrap>
	);
};

export default Burger;
