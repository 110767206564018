import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import { en } from "./en";
import { he } from "./he";
import { ru } from "./ru";

const resources = {
    en: en,
    he: he,
    ru: ru
};

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",
        nonExplicitSupportedLngs: true,
        supportedLngs: ["en", "he", "ru"],
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: true,
            useSuspense: false,
        }
    });
export default i18next;
