import React, {useRef, useState} from 'react';
import styled from "styled-components";

import { companies } from '../../data/companies';
import CeoQuote from '../OurWork/CeoQuote';
import Slider from "react-slick";
import arrow from "../../assets/img/Arrow - Right Circle.svg";
import useWindowSize from "../../hooks/useWindowSize";
import {useTranslation} from "react-i18next";
import useDirection from "../../hooks/useDirection";
import arrowHover from "../../assets/img/Arrow - Right CircleHover.svg";

const SliderStyled = styled.section`
  position: relative;
  width: 100%;
  .slick-slider {
    position: relative;
  }
  .slick-list {
    overflow: visible;
    position: relative;
    &:before, &:after {
      content: '';
      position: absolute;
      border-radius: 50%;
      border: 1px solid #9146FF;
      z-index: -1;
      transform: translate(-50%, -50%);
      aspect-ratio: 1 / 1;
      display: none;
    }
    &:after {
      width: 70vw;
      left: 10%;
      top: 38%;
    }
    &:before {
      width: 36.27vw;
      left: 78%;
      top: 76%;
    }
  }
  .slick-track {
    padding: 70px 0;
    display: flex;
    align-items: center;
  }
  .arrows {
    display: flex;
    width: 141px;
    position: absolute;
    bottom: -65px;
    justify-content: space-between;
    left: calc(50% + 300px);
    align-items: center;
  }
  .arrow-wrap {
    width: 58px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: .5;
    }
  }
  .arrow {
    width: 100%;
    height: 100%;
    transition: all .25s;
    transform: ${({isRtl}) => isRtl ? 'rotate(180deg)' : ''};
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &.arrow-prev {
      transform: ${({isRtl}) => isRtl ? 'rotate(360deg)' : 'rotate(180deg)'};
    }
  }
  
  .slick-dots {
    bottom: 0;
    li {
      width: 8px;
      height: 8px;
      background: #DADADA;
      border-radius: 20px;
      transition: all .25s;
      &.slick-active {
        width: 24px;
        background: #9146FF;
      }
      button {
        opacity: 0;
      }
    }
  }
  .slick-slide:not(.slick-active) {
    
  }
  .slick-slide.slick-active .company-card:after,
  .slick-slide.slick-active .company-card:before {
    display: none;
  }
  @media screen and (max-width: 960px) {
    padding: 50px 0;
    .arrows {
      display: none;
    }
    .slick-list:before, 
    .slick-list:after {
      display: block;
    }
  }
  
  @media screen and (max-width: 500px) {
    .company-card {
      width: 95%;
    }
  }
`

const Carrousel = () => {
    const screenSize = useWindowSize().width;
    const [currenSlide, setCurrentSlide] = useState(null);
    const {i18n} = useTranslation();
    const renderCompanies = companies[i18n.language.substring(0, 2)] || companies.en;
    const {isRtl} = useDirection();

    const settings = {
        className: "center",
        infinite: false,
        autoplay: screenSize < 960,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrow: false,
        centerMode: true,
        centerPadding: screenSize > 1200 ? '22%' : '10%',
        dots: screenSize < 960,
        afterChange: current => changeHandler(current),
        rtl: isRtl
    };

    const [hoverPrev, setHoverPrev] = useState(false);
    const [hoverNext, setHoverNext] = useState(false);

    const sliderRef = useRef(null);

    const changeHandler = (index) => {
        setCurrentSlide(index);
        if (index === companies.length - 1) {
            setTimeout(() => {
                sliderRef.current.slickGoTo(0);
            }, 2000);
        }
    }

    const prev = () => sliderRef.current.slickPrev();

    const next = () => sliderRef.current.slickNext();

    return (
        <SliderStyled isRtl={isRtl}>
            <Slider {...settings} ref={sliderRef}>
                {
                    renderCompanies.filter(c => c.testimonial !== undefined).map((company, i) => (
                        <CeoQuote company={company} key={i}/>
                    ))
                }
            </Slider>
            <div className="arrows">
                <div className={ (currenSlide === 0) ? "arrow-wrap disabled" : "arrow-wrap"} onClick={prev}>
                    <div className="arrow arrow-prev"
                         onMouseEnter={() => setHoverPrev(true)}
                         onMouseLeave={() => setHoverPrev(false)}
                    >
                        <img src={hoverPrev ? arrowHover : arrow} alt="prev"/>
                    </div>
                </div>
                <div className={ (currenSlide === renderCompanies.filter(c => c.testimonial !== undefined).length - 1) ? "arrow-wrap disabled" : "arrow-wrap"} onClick={next}>
                    <div className="arrow arrow-next"
                         onMouseEnter={() => setHoverNext(true)}
                         onMouseLeave={() => setHoverNext(false)}
                    >
                        <img src={hoverNext ? arrowHover : arrow} alt="next"/>
                    </div>
                </div>
            </div>
        </SliderStyled>
    )
}

export default Carrousel
