import React, {useRef, useState} from 'react';
import styled from "styled-components";

import {companies} from "../../data/companies";
import OurWorkMobileItem from "./OurWorkMobileItem";
import {useTranslation} from "react-i18next";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import Swipe from "react-easy-swipe";

const Projects = styled.section`

`

const OurWorkMobile = ({scrollUp, scrollDown}) => {

    const {i18n} = useTranslation();
    const renderCompanies = companies[i18n.language.substring(0, 2)] || companies.en;
    renderCompanies.length = 4;
    const [activeIndex, setActiveIndex] = useState(0);
    const wrapperRef = useRef(null);

    const handleScroll = (isDown) => {
        if (isDown) {
            const {scrollHeight} = wrapperRef.current;
            const bottom = (window.scrollY + window.innerHeight) >= scrollHeight;
            if (isDown && bottom) {
                activeIndex <= renderCompanies.length - 2 ? setActiveIndex(activeIndex + 1) : scrollDown();
            }
        }
        else {
            activeIndex < 1 ? scrollUp() : setActiveIndex(activeIndex - 1);
        }
    }

    return (
        <ReactScrollWheelHandler
            upHandler={() => handleScroll(false)}
            downHandler={() => handleScroll(true)}
            preventScroll={false}>
            <Swipe onSwipeUp={() => handleScroll(true)} onSwipeDown={() => handleScroll(false)}>
                <Projects ref={wrapperRef}>
                    <OurWorkMobileItem company={renderCompanies[activeIndex]}/>
                </Projects>
            </Swipe>
        </ReactScrollWheelHandler>
    )
}

export default OurWorkMobile

