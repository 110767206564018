import Top from "../assets/img/letsBuild/top.svg";
import Stay from "../assets/img/letsBuild/stay.png";
import Maximum from "../assets/img/letsBuild/maximum.svg";
import Quick from "../assets/img/letsBuild/quick.svg";
import All from "../assets/img/letsBuild/all.svg";
import Complex from "../assets/img/letsBuild/complex.svg";
import Smooth from "../assets/img/letsBuild/smooth.svg";
import Speed from "../assets/img/letsBuild/speed.svg";

export const buildCards = {
    en: {
        team: [
            {
                img: Top,
                title: 'Top developers',
                description: 'Our programmers are salaried employees. They come to our offices every day, receive equipment, social benefit, professional guidance (and even yoga classes).'
            },
            {
                img: Stay,
                title: 'Retaintion',
                description: 'We know how to retain talent with us for years. Most of our clients work with the same programmers for 3-4 years. They become an integral part of their team.'
            },
            {
                img: Maximum,
                title: 'Maximum flexibility',
                description: 'We allow maximum flexibility: recruit and train a programmer for your team within 7-14 days on average, depending on the scope you need.'
            },
            {
                img: Quick,
                title: 'Quick solutions',
                description: 'Outsourced programmers are a great solution for startups or small businesses that need quality manpower quickly.'
            }
        ],
        products: [
            {
                img: All,
                title: 'All in one',
                description: 'All the needs of the project in under one roof: planning, design, software development, feasibility study and entering the market. '
            },
            {
                img: Complex,
                title: 'Complex products',
                description: 'We have developed a wide range of software products: complex websites, CRM systems, applications, management systems and information.'
            },
            {
                img: Smooth,
                title: 'Smooth process',
                description: 'Outsource development allows you to skip the barriers to innovation that exist in large organizations. '
            },
            {
                img: Speed,
                title: 'Speed up production',
                description: 'Our work processes allow us to enter the market quickly - compared to a traditional development process.'
            }
        ]
    },
    he: {
        team: [
            {
                img: Top,
                title: 'מפתחים מובילים',
                description:'המתכנתים שלנו הם עובדים בשכר. הם מגיעים למשרדינו מדי יום, מקבלים ציוד, הטבות סוציאלית, הדרכה מקצועית (ואפילו שיעורי יוגה).'
            },
            {
                img: Stay,
                title: 'שימור',
                description: 'אנחנו יודעים לשמור איתנו כישרונות לאורך שנים. רוב הלקוחות שלנו עובדים עם אותם מתכנתים במשך 3-4 שנים. הם הופכים לחלק בלתי נפרד מהצוות שלהם.'
            },
            {
                img: Maximum,
                title: 'גמישות מרבית',
                description: 'אנו מאפשרים גמישות מירבית: והכשרת מתכנת לצוות שלך תוך 7-14 ימים בממוצע, בהתאם להיקף שאתה צריך.'
            },
            {
                img: Quick,
                title: 'פתרונות מהירים',
                description: 'מתכנתים במיקור חוץ הם פתרון מצוין עבור סטארטאפים או עסקים קטנים שזקוקים לכוח אדם איכותי במהירות.'
            }
        ],
        products: [
            {
                img: All,
                title: 'הכל במקום באחד',
                description: 'כל צרכי הפרויקט תחת קורת גג אחת: תכנון, עיצוב, פיתוח תוכנה, בדיקת היתכנות ויציאה לשוק.'
            },
            {
                img: Complex,
                title: 'מוצרים מורכבים',
                description: 'פיתחנו מגוון רחב של מוצרי תוכנה: אתרי אינטרנט מורכבים, מערכות CRM, אפליקציות, מערכות ניהול ומידע.'
            },
            {
                img: Smooth,
                title: 'תהליך חלק',
                description: 'פיתוח מיקור חוץ מאפשר לדלג על החסמים לחדשנות הקיימים בארגונים גדולים.'
            },
            {
                img: Speed,
                title: 'להאיץ את הייצור',
                description: 'תהליכי העבודה שלנו מאפשרים לנו להיכנס לשוק במהירות – לעומת תהליך פיתוח מסורתי.'
            }
        ]
    },
    ru: {
        team: [
            {
                img: Top,
                title: 'Лучшие разработчики',
                description: 'Наши программисты - наемные работники. Они каждый день приходят в наши офисы, получают оборудование, социальные выплаты, посещают курсы повышения квалификации и даже занятия йогой.'
            },
            {
                img: Stay,
                title: 'Они остаются с нами',
                description: 'Мы знаем, как сохранить качественные кадры с нами в течение многих лет. Большинство наших клиентов работают с одними и теми же программистами по 3-4 года. Они становятся неотъемлемой частью своей команды.'
            },
            {
                img: Maximum,
                title: 'Максимальная гибкость',
                description: 'Мы придерживаемся максимальной гибкости: набираем и обучаем программиста для вашей команды в среднем за 7-14 дней, в зависимости от ваших потребностей.'
            },
            {
                img: Quick,
                title: 'Быстрые решения',
                description: 'Программисты на аутсорсинге — отличное решение для стартапов или малого бизнеса, которым нужно быстро нанять оыптных сотрудников.'
            }
        ],
        products: [
            {
                img: All,
                title: 'Всё в одном',
                description: 'Все потребности проекта под одной крышей: планирование, проектирование, разработка программного обеспечения, ТЭО и выход на рынок.'
            },
            {
                img: Complex,
                title: 'Комплексные продукты',
                description: 'Мы разработали широкий спектр программных продуктов: комплексные сайты, CRM-системы, приложения, системы управления и информации.'
            },
            {
                img: Smooth,
                title: 'Плавный процесс',
                description: 'Аутсорсинговая разработка позволяет обойти барьеры для инноваций, которые существуют в крупных организациях.'
            },
            {
                img: Speed,
                title: 'Ускорьте производство',
                description: 'Наши рабочие процессы позволяют нам быстро выходить на рынок - по сравнению с традиционным процессом разработки.'
            }
        ]
    }
}
