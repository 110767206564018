import React from 'react';
import styled from "styled-components";

import {SmallText} from "../Fonts.Style";
import {useTranslation} from "react-i18next";
import useDirection from "../../hooks/useDirection";

const Scroller = styled.div`
  position: absolute;
  bottom: 10%;
  transform: ${({rtl}) => rtl ? "translateX(50%)" : "translateX(-50%)"};
  ${({rtl}) => rtl ? "right" : "left"}: 32.5%;
  width: fit-content;

  .scroller-container {
    flex-direction: column;
    align-items: center;

    .scroller-wrapper {
      width: 1rem;
      height: 2rem;
      border-radius: 50px;
      border: 1px solid black;
      justify-content: center;
      margin-bottom: .5rem;

      .scroller {
        margin-top: 3px;
        width: .5rem;
        height: 1.0625rem;
        border-radius: 50px;
        background: black;
        animation: 1.5s linear 0s infinite normal scroll;
      }
    }
  }

  @media screen and (max-width: 960px) {
    left: 50%;
    transform: translateX(-50%);
    bottom: 1rem;
  }

  @keyframes scroll {
    0% {
      margin-top: 3px;
    }
    50% {
      margin-top: calc(100% - 3px);
    }
    100% {
      margin-top: 3px;
    }
  }
`;

const HeroScroller = () => {
    const {t} = useTranslation();
    const {isRtl} = useDirection();
    return (
        <Scroller rtl={isRtl}>
            <div className="scroller-container flex">
                <div className="scroller-wrapper flex">
                    <div className="scroller flex"></div>
                </div>
                <SmallText>
                    {t("scroll_more")}
                </SmallText>
            </div>
        </Scroller>
    );
};

export default HeroScroller;
