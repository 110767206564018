import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import arrow from "../../assets/img/select-arrow.svg";
import useDirection from "../../hooks/useDirection";

const SelectStyled = styled.select`
  font-size: 1.5rem;
  line-height: 140%;
  padding: 5px;
  box-shadow: -8px 0px 34px rgba(145, 70, 255, 0.65);
  color: white;
  border: 1px solid white;
  border-radius: 8px;
  padding: ${({ isRtl }) =>
    isRtl ? "15px 20px 15px 30px" : "15px 30px 15px 20px"};
  margin-top: auto;
  outline: none;
  appearance: none;
  width: 210px;
  background-color: #9146ff;
  background-image: url(${arrow});
  background-position: ${({ isRtl }) =>
    isRtl ? "left 20px center" : "right 20px center"};
  background-repeat: no-repeat;
`;

function LanguageSwitcher({ handleChange }) {
  const { i18n } = useTranslation();
  const {isRtl} = useDirection();
  const langChanged = (e) => {
    console.log(e, "lang");
    i18n.changeLanguage(e.target.value);
    handleChange();
  };
  return (
    <SelectStyled
      isRtl={isRtl}
      value={i18n.language.substring(0, 2)}
      onChange={(e) => langChanged(e)}
    >
      <option value="en">English</option>
      <option value="he">עִברִית</option>
      <option value="ru">Русский</option>
    </SelectStyled>
  );
}

export default LanguageSwitcher;
