import React from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import useDirection from "../../hooks/useDirection";
import {clients} from "../../data/clientsData";
import Customer from "./Customer";
import Slider from "react-slick";

const SliderWrap = styled.section`
    height: 350px;
    width: 100%;
    .team-item {
      width: 140px;
      margin: 0 auto;
      transition: all .3s;
      p, a {
        font-size: 14px;
      }
      h3 {
        font-size: 18px;
      }
    }
    .slick-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .slick-track {
      display: flex;
      height: 300px;
    }
    .slick-current .team-item{
      width: 186px;
    }
`

const CustomersCarousel = () => {

    const {i18n} = useTranslation();
    const renderCustomers = clients[i18n.language.substring(0,2)] || clients.en;
    const {isRtl} = useDirection();

    const settings = {
        centerMode: true,
        loop: true,
        slidesToShow: 7,
        autoplay: true,
        speed: 500,
        pauseOnHover: false,
        rtl: isRtl,
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 7
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };

    return (
        <SliderWrap>
            <Slider {...settings} >
                {
                    renderCustomers.map((customer, i) => (
                        <Customer customer={customer} key={i}/>
                    ))
                }
            </Slider>
        </SliderWrap>
    )
}

export default CustomersCarousel;
