import RoadMap from "../assets/img/InformationSystem/sub/Monday/roadmap.svg";


export const salesforceServiceCards = {
    en: [
        {
            img: RoadMap,
            title: "User management and viewing permissions",
            description: "Effective user management can help organisations ensure that they are maintaining their user based license compliancy, and helps with the transparency of user-based licenses."
        },
        {
            img: RoadMap,
            title: "Team management and sales people",
            description: "Track and monitor every customer's sales journey from start to finish in one place. Use automation and sales management tools to sell more efficiently so you can focus on delivering a great customer experience and closing deals."
        },
        {
            img: RoadMap,
            title: "Sales target management",
            description: "Easily set up and access real-time reports so you're always up-to-date with what's happening. \n" +
                "Company-wide pipeline visibility shows top performing teams and individuals.\n"
        },
        {
            img: RoadMap,
            title: "Configuration process deals",
            description: "Salesforce configuration focuses on using built-in tools, features, tools, and components to create the best business strategy to increase your revenue, ROI, and sales productivity."
        },
    ],
    he: [
        {
            img: RoadMap,
            "title": "ניהול משתמשים והרשאות צפייה",
            "description": "ניהול משתמשים יעיל יכול לעזור לארגונים להבטיח שהם שומרים על תאימות לרישיון מבוסס המשתמש שלהם, ומסייע בשקיפות של רישיונות מבוססי משתמשים."
        },
        {
            img: RoadMap,
            "title": "ניהול צוות ואנשי מכירות",
            "description": "מעקב וניטור של כל מסע מכירות של לקוח מתחילתו ועד סופו במקום אחד. השתמש בכלי אוטומציה וניהול מכירות כדי למכור ביעילות רבה יותר, כך שתוכל להתמקד באספקת חוויית לקוח מעולה וסגירת עסקאות."
        },
        {
            img: RoadMap,
            "title": "ניהול יעדי מכירות",
            "description": "להגדיר ולגשת בקלות לדוחות בזמן אמת כך שתהיה תמיד מעודכן לגבי המתרחש. הצגת תהליכי המכירה לכל החברה מראה קבוצות ויחידים בעלי התפוקה הכי גבוהה"
        },
        {
            img: RoadMap,
            "title": "עסקאות תהליך קונפיגורציה",
            "description": "הגדרת התצורה מתמקדת בשימוש בכלים, תכונות, כלים ורכיבים מובנים כדי ליצור את האסטרטגיה העסקית הטובה ביותר להגדלת ההכנסות, החזר ההשקעה ופרודוקטיביות המכירות."
        },
    ],
    ru: [
        {
            img: RoadMap,
            title: "Управление пользователями и разрешения на просмотр",
            description: "Эффективное управление пользователями может помочь организациям гарантировать, что они поддерживают соответствие своих лицензий на основе пользователей, а также способствует прозрачности лицензий на основе пользователей."
        },
        {
            img: RoadMap,
            title: "Управление командой и специалисты по продажам",
            description: "Отслеживайте и контролируйте путь продаж каждого клиента от начала до конца в одном месте. Используйте инструменты автоматизации и управления продажами, чтобы продавать более эффективно, чтобы вы могли сосредоточиться на обеспечении высокого качества обслуживания клиентов и заключении сделок."
        },
        {
            img: RoadMap,
            title: "Управление целевыми показателями продаж",
            description: "Простая настройка и доступ к отчетам в реальном времени, чтобы вы всегда были в курсе того, что происходит. \n" +
                "Общекорпоративное представление конвейера показывает наиболее эффективные команды и отдельные лица.\n"
        },
        {
            img: RoadMap,
            title: "Процесс настройки сделок",
            description: "Конфигурация Salesforce фокусируется на использовании встроенных инструментов, функций, инструментов и компонентов для создания наилучшей бизнес-стратегии для увеличения вашего дохода, рентабельности инвестиций и эффективности продаж."
        },
    ]
 };
