import TopAppServices from "../../assets/img/InformationSystem/top_apps_services.svg";
import Data from "../../assets/img/InformationSystem/sections/aboutus/data.svg";
import Monday from "../../assets/img/InformationSystem/sub/Monday/img.svg";
import Salesforce from "../../assets/img/InformationSystem/sub/Saleforce/img.svg";

export const he = {
    translation: {
        "hire": {
            "heading": "גיוס ",
            "headingSpan": "מתכנתים",
            "subHeading": "גיוס מהיר של מתכנתים",
            "conditions1": "בשימוש בטופס זה אתה מסכים עם",
            "conditions2": "מדיניות הפרטיות והתקנון של האתר ",
            "takes_time": "משך - 4 דקות",
            "error": "בבקשה בחר תשובה",
            "doneHeading": "תודה רבה",
            "doneSubHeading": "אנחנו נחזור אליך בהקדם",
            "doneText": "אנחנו נבצע את החיבור הטוב ביותר עבור החברה שלך",
            "multiple": " בחר כמה שתרצה",
            "stepNames": {
                1: "מה גודל החברה ?",
                2: "לאיזה תעשייה שייכת החברה ?",
                3: " איזה סוג מתכנת אתם מחפשים ?",
                4: "עם איזה טכנולוגיות אתם עובדים ?",
                5: "האם התקציב למתכנת גבוה יותר מ- 3000$ ?",
                6: "מה רמת הדחיפות לגיוס ?",
                7: "לכמה זמן תרצו להשתמש במתכנת ?",
                8: "מה אחוז המשרה ?",
                9: "האם אתם מנוסים בעבודה מרחוק ?",
                10: "כמה פרטים עליך",
            },
            "placeholders": {
                "firstName": "שם פרטי",
                "lastName": "שם משפחה",
                "email": "כתובת דוא״ל",
                "mobile": "מספר טלפון",
            }
        },
        "hero_title": {
            0: "אנחנו <highlight>נפתח</highlight> פתרונות דיגיטלים עבור <highlight>האתגר</highlight> העסקי הבא שלך",
            1: "אנחנו <highlight>נרחיב</highlight> את צוות הפיתוח עבור <highlight>הפרויקט</highlight> הבא שלך",
            2: "אנחנו מאמינים בעבודה <highlight>פרודקטיבית</highlight> עבור <highlight>מוצרים</highlight> חדשניים",
        },
        "our_work": "פרוייקטים",
        "about_us_headline": "עלינו",
        "our_process": "התהליך שלנו",
        "meet_the_team": "הצוות של פרודקטיב",
        "testimonials": "אומרים עלינו",
        "team_slogan": "בזכותם אתם מרוצים",
        "team_part_slogan": "רוצה להצטרף לפרודקטיב??",
        "technologies": "טכנולוגיות",
        "benefits": "הטבות",
        "our_offices": "המשרדים שלנו",
        // "lets_build": "בואו נבנה א",
        "lets_build": "",
        "description": "תיאור",
        "our_friends": "החברים שלנו",
        "scroll_more": "לגלול כדי לראות עוד",
        "see_more": "לראות עוד",
        "hire_heading": "גיוס מתכנתים",
        "progress": {
            "1": "ברוכה הבאה",
            "2": "שירותים",
            "3": "פורטפוליו",
            "4": "התהליך",
            "5": "המלצות",
            "6": "הצוות",
            "7": "יצירת קשר"
        },
        "nav": {
            "get_in_touch": "צור איתנו קשר",
            "build": " שירותים",
            "services": "שירותים",
            "portfolio": "עבודות",
            "team": "הצוות",
            "blog": "בלוג",
            "careers": "קרירה",
            "about": "עלינו",
            "friends": "חברים",
            "news": "כתבות",
        },
        "btn": {
            "read_more": "הצג עוד",
            "learn_more": "קרא עוד",
            "read_article": "קרא את הכתבה",
            "read_less": "הצג פחות",
            "see_portfolio": "צפה בפורטפוליו",
            "visit_site": "בקר באתר",
            "see_full_team": "צפה בכל הצוות",
            "get_free_quote": "קבל הצעת מחיר",
            "send": "שלח",
            "see_openings": "המשרות הפתוחות שלנו",
            "see_careers": "צפה בכל המשרות",
            "project_in_progress": "פרויקט בתהליך",
            "more_info": "עוד מידע",
            "see-full-customers": "צפה בכל הלקוחות",
            "contact-expert": "צור קשר עם מומחה",
            "get_started": "בואו נתחיל",
            "next": "הבא",
            "home": "עמוד הבית",
        },
        "build": {
            "title": "השירותים שלנו",
            "product": {
                "title": "בניית מוצר",
                "description":
                    "<p>כל צרכי הפרוייקט באותו בית: תכנון, עיצוב, פיתוח תוכנה, בדיקת היתכנות ויציאה לשוק. </p>",
                "mobileDescription":
                    "<p>אנחנו בונים מוצרי תוכנה מא' ועד ת'. אנחנו משתמשים במתודולוגיות המתקדמות ביותר לניהול פרויקטים, כמו אג'יל וסקראם. בתהליך, אנחנו מנטרים כל תקלה ובעיה, מודדים את מה שחשוב, ומבצעים אופטימיזציה שוטפת. תהליכי העבודה שלנו מאפשרים לנו לצאת לשוק במהירות - בהשוואה לתהליך פיתוח מסורתי. בכל פרוייקט שאנחנו לוקחים, חשוב לנו לעמוד בזמנים וביעדים - ולהביא כל מוצר לנקודת הסיום.</p>",
                // "servicesTitle": "מחפש להגדיל את המוצר שלך ולהגדיל את גודלו?",
                "servicesTitle": "פיתוח מוצרים דיגיטלים לחברות ויזמיים",
                "servicesDescription": "אנו בונים מוצרי תוכנה מא' ועד ת'. אנו משתמשים במתודולוגיות המתקדמות ביותר לניהול פרויקטים, כגון Agile ו- Scrum. בתהליך אנחנו עוקבים אחר כל תקלה ובעיה, מודדים מה חשוב ומבצעים אופטימיזציה מתמשכת."
            },
            "team": {
                "title": "בניית צוות",
                "description":
                    "<p>מתכנתים במיקור חוץ הוא פתרון מעולה לסטארטאפים או עסקים קטנים שצריכים כוח אדם איכותי במהירות. אנחנו מאפשרים את הגמישות המקסימלית: לגייס ולהכשיר מתכנת לצוות שלכם תוך 7-14 יום בממוצע, בהתאם להיקף שאתם צריכים.</p>",
                "mobileDescription":
                    "<p>מתכנתים במיקור חוץ הוא פתרון מעולה לסטארטאפים או עסקים קטנים שצריכים כוח אדם איכותי במהירות. אנחנו מאפשרים את הגמישות המקסימלית: לגייס ולהכשיר מתכנת לצוות שלכם תוך 7-14 יום בממוצע, בהתאם להיקף שאתם צריכים.</p>",
                "servicesTitle": "מחפש להגדיל את צוות הפיתוח? להגדיל את היקף העבודה?",
                "servicesDescription": "צריכים צוות דינמי? זה הזמן לדבר איתנו ! נשמח לדון בצרכים שלך ולעזור לבנות צוות של כישרונות מובילים כדי לעזור לעסק שלך למצות את הפוטנציאל שלו."
            }
        },
        "services": {
            "team": "צוות",
            "product": "מוצר"
        },
        "about_us": {
            "title": "הסיפור שלנו",
            "main_text": "<p>הסיפור של פרודקטיב מתחיל מאהבה גדולה לטכנולוגיה. כמו שפסל אוהב לפסל, כמו שמשורר אוהב לכתוב שירים - אנחנו אוהבים ליצור פתרונות טכנולוגים שהופכים חיים של אנשים לטובים יותר</p>" +
                "<p>מבחינתנו, פרודוקטיביות זה מעבר ליעילות. אנחנו לא כאן כדי לכתוב כמה שיותר שורות קוד בכמה שפחות זמן. אלא, להיות דינאמיים בעולם תחרותי, ולהוציא לשוק במהירות האפשרית מוצר תוכנה שעונה בדיוק על הצרכים של הלקוחות, בלי פיצ'רים מיותרים</p>" +
                "<p>לשם כך, אנחנו משתמשים במתודולוגיות המתקדמות ביותר לניהול פרויקטים, כמו אג'יל וסקראם. בתהליך, אנחנו מנטרים כל תקלה ובעיה, מודדים את מה שחשוב, ומבצעים אופטימיזציה שוטפת</p>" +
                "<p>חשוב לנו להיות שותפים מלאים להצלחה של הלקוחות שלנו. אנחנו לוקחים בעלות ומתייחסים לכל פרוייקט כאילו היה המוצר שלנו. הסטנדרטים שלנו גבוהים, אנחנו מקפידים לעמוד ביעדים ולהביא כל מוצר אל נקודת הסיום</p>" +
                "<p>המכלול של הערכים, כוח האדם ושיטות העבודה איפשרו לנו לפרוץ קדימה במהירות. ממשרד קטן בתחילת הדרך, למשרד המעסיק עשרות עובדים, ועובד עם חברות מובילות במשק, כגון קשת 12, אוניברסיטת רייכמן, שנקר, בנק הפועלים, דיסקונט ועוד</p>" +
                "<p>אנחנו ממשיכים לגדול, והמשימה שלנו היא לפתח מוצרי תוכנה שישפיעו לטובה על חייהם של מיליוני אנשים</p>",
            "banner": {
                "title": "<h1>40+ <br>חברות</h1>",
                "items": {
                    "item0": "ידידותי למשתמש, מודרני ואינטואיטיבי",
                    "item1": "פיצ'רים חדשים ועיצוב טרנדי",
                    "item2": "פיתוח מקצה לקצה ברמה גבוהה",
                    "item3": "24/7 שירות לקוחות"
                }
            },
            "secondary_text": "צריכים צוות דינמי? זה הזמן לדבר איתנו ! נשמח לדון בצרכים שלך ולעזור לבנות צוות של כישרונות מובילים כדי לעזור לעסק שלך למצות את הפוטנציאל שלו."
        },
        "contact_form": {
            "headline": "<div>אנחנו <span>פה </span>בשבילך ואנחנו מוכנים <span> לעבוד </span> איתך<p/>",
            "mob_headline": "צור קשר!",
            "discuss_needs": "בוא נדבר על הצרכים שלך!",
            "productive_together": "בואו נהיה פרודקטיבים ביחד",
        },
        "placeholders": {
            "name": "שם מלא",
            "phone": "מספר נייד",
            "email": "דוא״ל",
            "message": "תרגישו חופשי",
            "Link": "פורטפוליו"
        },
        "careers": {
            "title": "קרירה",
            "heading": "אנחנו המקפצה לשלב הבא בקריירה שלך.",
            "main_text": "אנחנו מחפשים מישהו שרוצה להגיע לפריצה הגדולה בקריירה שלו, להשפיע ולהשפיע עם מה שהוא עושה, ואוהב לחקור וללמוד טכנולוגיות חדשות. מישהו שרוצה לעבוד בצוות גלובלי ויודע לפתור בעיות ולעמוד בלחץ."
        },
        "positions": {
            "heading": "משרות פנויות",
            "see_position": "ראה משרות",
            "position_description": "תיאור משרה",
            "requirements": "דרישות"
        },
        "friendsCategories" : {
            "brands": "מותגים וסטארטאפים",
            "startups": "Startups",
            "government": "ממשלה ורשויות מקומיות",
            "finance": "פיננסים",
            "education": "חינוך ואקדמיה",
            "ecommerce": "מסחר דיגיטלי",
            "other": "אחרים",
        },
        "blog": {
            "heading": "הבלוג",
            "title": "בלוג פרודקטיב"
        },
        "news": {
            "heading": "מהעיתונות",
            "title": "זה מה שהתקשורת חושבת עלינו"
        },
        "InformationSystem": {
            "heading": "מערכת מידע",
            "title": "הטמעת מערכת מידע בעסקים",
            "description": "<p>שירותי יישום CRM נועדו לספק לעסקים פתרונות CRM מבוססי פלטפורמה או מותאמים אישית כדי למטב תהליכי מכירות, שיווק ושירות לקוחות. עם 8 שנות נסיון בשירותי CRM.</p>" +
                            "<p>ScienceSoft מבצעת יישום CRM כדי לסייע לחברות להפחית את העלויות של רכישת והמרת לידים, כמו גם עלויות שימור לקוחות</p>",
            "img": TopAppServices,
            "sections": {
                "services": {
                    "heading": "השירותים שלנו"
                },
                "products": {
                    "heading": "SalesForce מוצרי"
                },
                "about-us": {
                    "heading": "עלינו",
                    "title": "אנחנו שותפים באתגרים הטכנולוגיים שלכם ומשפרים את סביבת העבודה",
                    "description": "<p>שותף ייעוץ, פרודוקטיב יוצרת אסטרטגיה ייחודית עבור הארגון שלך המאפשרת ל- CRM להגיע למטרות השינוי שלך. אנו מכירים את הטכנולוגיה, את האסטרטגיה וכיצד היא חלה על תעשיות.</p>" +
                        "<p> עם עין על חדשנות, פרודוקטיב מביאה מומחיות טכנית, ממנפת שנות ניסיון לתכנון, תכנון ויישום אסטרטגיית Salesforce העונה על צרכי הארגון שלך היום ובונה תוכנית לעתיד.</p>",
                    "img": Data
                },
                "customers": {
                    "heading": "הלקוחות שלנו",
                    "subtitle": "אתר מקצועי"
                }
            },
        },
        "monday": {
            "heading": "מאנדיי",
            "title": "הצטרף למערכת הניהול של Monday",
            "description": "<p>ש לנו את הניסיון והמשאבים כדי לסייע לארגון שלך למקסם את הערך של ההשקעה שלך ב- monday.com. הצוות שלנו סייע ללקוחות עם כל ההיבטים של השימוש ב- monday.com כולל onboarding, ייעוץ IT, הדרכה ושירותי יישום מבוססי פרויקטים.</p>",
            "img": Monday,
        },
        "salesforce": {
            "heading": "סיילספורס",
            "title": "הצטרף למערכת הניהול של סיילספורס",
            "description": "<p>שותפים של Salesforce , פרודוקטיב יוצרת אסטרטגיה ייחודית עבור הארגון שלך המאפשרת ל- Salesforce כזרז להשגת היעדים המשתנים שלך. </p>" +
                "<p>אנו מכירים את Salesforce – הטכנולוגיה, האסטרטגיה וכיצד היא חלה על תעשיות. עם עין על חדשנות, פרודוקטיב מביאה מומחיות טכנית, ממנפת שנות ניסיון לתכנון, תכנון ויישום אסטרטגיית Salesforce העונה על צרכי הארגון שלך היום ובונה תוכנית לעתיד.",
            "img": Salesforce,
        }
    }
}
