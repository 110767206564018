import React, {useEffect} from 'react';
import styled from "styled-components";

import Member from './Member';
import { team } from '../../data/TeamData';
import { GreyHeadline } from '../Fonts.Style';
import {useTranslation} from "react-i18next";
import useWindowSize from "../../hooks/useWindowSize";
import ContactForm from "../ContactForm/ContactForm";
import ButtonMain from "../Buttons/ButtonMain";
import {Link} from "react-router-dom";

const TeamStyled = styled.main`
    > p {
      margin-top: 100px !important;
    } 
    .team-grid {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto 3rem;
        padding: 30px;
        justify-content: center;
    }
    .team-banner {
      width: 100%;
      background: #9146FF;
      padding: 120px 40px;
      position: relative;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 140%;
      color: white;
      margin-bottom: 6rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        margin-top: 2rem;
        background: white;
        color: #9146FF;
        padding: 20px 55px;
        font-weight: bold;
        font-size: 18px;
        max-height: unset;
        transition: all .25s;
        box-shadow: 0px 10px 40px -10px rgba(255, 255, 255, 0.5);
        &:hover {
          opacity: .8;;
        }
      }
      :before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 50px 500px 0 0;
        border-color: white transparent transparent transparent;
      }
      :after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 50px 500px;
        border-color:  transparent transparent white transparent;
      }
    }
    @media screen and (max-width: 500px) {
      .team-grid {
        justify-content: space-between;
        padding: 0 16px 30px;
      }
      
      //.team-item:not(:nth-child(-n+2)) {
      .team-item {
        width: calc((100% - 40px) / 2);
        max-width: calc((100% - 40px) / 2);
        img {
          width: 80%;
          margin: 50px auto;
        }
        a {
          display: none;
        }
        h3 {
          font-size: 22px;
        }
      }

      //.team-item:first-child img {
      //  margin-top: 32px;
      //}
      //
      //.team-item:nth-child(-n+2) {
      //  width: 60%;
      //  max-width: 60%;
      //  margin: 0 auto;
      //}
    } 
`;

const TeamPage = () => {

    const screenSize = useWindowSize().width;
    const {i18n, t} = useTranslation();
    const renderTeam = team[i18n.language.substring(0, 2)] || team.en;

    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById("root").style.overflowX = "hidden";
        return () => document.getElementById("root").style.overflowX = "unset";
    }, []);

    return (
        <TeamStyled>
            <GreyHeadline style={{ maxWidth: "1200px", marginTop: '10rem', margin: "auto", padding: "0 90px"}}>
                {renderTeam.israel.name}
            </GreyHeadline>
            {
                <div>
                    <div className="team-grid">
                        { [...renderTeam.israel.team].slice(0, -1).map((member, indx) => (
                                <Member key={indx} teamMember={member} page="teamPage"/>
                            )
                        )}
                    </div>
                    <div className="team-grid" style={{marginTop: "-100px"}}>
                        <Member teamMember={renderTeam.israel.team[renderTeam.israel.team.length - 1]} page="teamPage"/>
                    </div>
                </div>

            }

            <GreyHeadline style={{ maxWidth: "1200px", marginTop: '10rem', margin: "auto", padding: "0 90px"}}>
                {renderTeam.ukraine.name}
            </GreyHeadline>
            {
                <div className="team-grid">
                    { renderTeam.ukraine.team.map((member, indx) => (
                            <Member key={indx} teamMember={member} page="teamPage"/>
                        )
                    )}
                </div>
            }

            <GreyHeadline style={{ maxWidth: "1200px", marginTop: '10rem', margin: "auto", padding: "0 90px"}}>
                {renderTeam.kenya.name}
            </GreyHeadline>
            {
                <div className="team-grid">
                    { renderTeam.kenya.team.map((member, indx) => (
                            <Member key={indx} teamMember={member} page="teamPage"/>
                        )
                    )}
                </div>
            }

            {
                screenSize <= 500 &&
                <>
                    <div className="team-banner">
                        <p className="slogan">{t('team_part_slogan')}</p>
                        <Link to={"/careers"}><ButtonMain title={t("btn.see_careers")} notBold={true}/></Link>
                    </div>
                    <ContactForm/>
                </>
            }

        </TeamStyled>
    )
};

export default TeamPage;
