import React, {useState} from 'react';
import styled from "styled-components";
import ButtonMain from '../Buttons/ButtonMain';
import {Heading} from "../Fonts.Style";
import {useTranslation} from "react-i18next";
import TestimonialPortfolio from "../TestimonialPortfolio/TestimonialPortfolio";
import useWindowSize from "../../hooks/useWindowSize";
import BtnWithArrow from "../Buttons/BtnWithArrow";
import useDirection from "../../hooks/useDirection";
import ButtonWhite from "../Buttons/ButtonWhite";

const CompanyWrap = styled.div`
  width: 100%;
  margin-bottom: 5rem;

  &.reverse-our-work {
    background: #E5E5E5;
    flex-direction: row-reverse;
    padding: 5rem 0;

    > .flex {
      flex-direction: row-reverse;
    }
    
    .circle {
      right: unset;
      left: 0;
    }
  }

  @media screen and (max-width: 960px) {
    margin-bottom: 3rem;
    &.reverse-our-work {
      padding: 3rem 0;
    }
  }

`
const OurWorkStyled = styled.section`
  font-style: normal;
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
  justify-content: space-between;
  padding: 0 120px;
  display: flex;
  align-items: center;

  .left {
    width: calc((100% - 180px) / 2);
    justify-content: flex-end;
    height: 100%;
    min-height: 802px;

    img {
      position: absolute;
      max-height: 100%;
      z-index: 2;
    }

    .img-left, .img-right {
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      left: 50%;
      min-height: 413px;
    }

    //.img-right {
    //  top: 50%;
    //  transform: translate(-50%, -50%);
    //  left: 50%;
    //  width: 100%;
    //  height: 413px;
    //  border-radius: 8px;
    //}
  }

  .right {
    width: calc((100% - 180px) / 2);
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .tags {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      ${({isRtl}) => isRtl ? "margin-right" : "margin-left"}: -4px;
    }

    .desc, .tags, .visit {
      max-width: 600px;
    }

    .visit {
      margin: 50px 0;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .mobImg {
      width: 100%;
      max-width: 100%;
      margin: 2rem auto 0;
      img {
        border-radius: 8px;
      }
    }
    
    .desc {
      max-height: 104px;
      overflow: hidden;
      &.full {
        max-height: unset;
      }
    }
    
  }
  
  .btnWrap {
    margin: 0 auto;
  }
  .white-btn {
    border: none;
    color: #9146ff;
    text-decoration: underline;
    background: transparent;
    box-shadow: none;
    margin-top: 10px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 960px) {
    padding: 0 30px;
    .right {
      width: 100%;

      .desc, .tags, .visit {
        max-width: unset;
      }

      .desc {
        margin-bottom: 1rem;
      }

      .visit {
        margin: 2rem 0 0;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .right .mobImg {
      max-height: 300px;
      width: auto;
      border-radius: 8px;
    }
  }
`;

const CircleStyled = styled.div`
  width: 21.458333vw;
  height: 21.458333vw;
  border-radius: 50%;
  border: 4px solid var(--purple1);
  background: var(--grey5);
  align-self: center;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  z-index: 1;
  display: flex;
`;

const TagStyled = styled.p`
  color: var(--purple1);
  line-height: 21px;
  font-size: 14px;
  border: 1px solid var(--grey2);
  border-radius: 30px;
  padding: 5px 16px;
  margin: 8px 4px;
`;

const LoremStyled = styled.p`
  color: black;
  font-size: 18px;
  line-height: 26px;
  margin-top: 1rem;
  p {
    margin-bottom: 1rem;
  }
  .title {
    display: block;
    font-weight: bold;
  }
`;

const OurWorkPortfolio = ({direction, company, page}) => {

    const screenSize = useWindowSize().width;
    const {t} = useTranslation();
    const {isRtl} = useDirection();
    const [isFull, setIsFull] = useState(false);

    return (
        <CompanyWrap className={direction !== 'reverse' ? "" : "reverse-our-work"}>
            <OurWorkStyled className={"flex"} isRtl={isRtl}>
                {screenSize > 960 && (
                    <div className="left flex relative">
                        <img src={company.img} alt=""
                             className={direction !== 'reverse'
                                 ? "img-left" : 'img-right'}/>
                        <CircleStyled className={"circle"}/>
                    </div>
                )}

                <div className="right">
                    <Heading>{company.companyName}</Heading>
                    {screenSize <= 960 && <LoremStyled className={!isFull ? "desc" : "desc full"} dangerouslySetInnerHTML={{__html: company.portfolio}}></LoremStyled>}
                    <div className="tags">
                        {company.projectTypes.map((tag, indx) => (<TagStyled key={indx}>{tag}</TagStyled>))}
                    </div>
                    {screenSize > 960 && <LoremStyled className={!isFull ? "desc" : "desc full"} dangerouslySetInnerHTML={{__html: company.portfolio}}></LoremStyled>}
                    <div className="btnWrap"   onClick={() => setIsFull(!isFull)}>
                        <ButtonWhite title={!isFull ? t("btn.read_more") : t("btn.read_less")}/>
                    </div>

                    {screenSize > 960 && <div className="visit">
                        <a href={company.website} target="_blank" rel="noreferrer">
                            <ButtonMain
                                title={t("btn.visit_site")}
                                notBold={true}
                                height={"48px"}
                                minWidth={"280px"}
                            />
                        </a>
                    </div>}
                    {screenSize > 960 && company.testimonial && <TestimonialPortfolio company={company} />}
                    {screenSize <= 960 && <img src={company.mobileImg} alt={company.name} className={"mobImg"}/>}
                    {screenSize <= 960 && <div className="visit" rel="noreferrer">
                        <a href={company.website} target="_blank" rel="noreferrer">
                            <BtnWithArrow text={t("btn.visit_site")}/>
                        </a>
                    </div>}
                </div>
            </OurWorkStyled>
        </CompanyWrap>
    );
};

export default OurWorkPortfolio;
