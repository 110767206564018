import React from 'react';
import styled from "styled-components";

const CardStyled = styled.div`
    width: ${({page}) => page === "services" ? "41%" : "calc((100% - 105px) / 4)"};
    margin-bottom: 50px;
    .icon-wrap {
      background: #F7F7F7;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 102px;
      aspect-ratio: 1 / 1;
      border-radius: 50%;
      margin-bottom: 10px;
    }
    img { 
        width: 60px;
        aspect-ratio: 1 / 1;
    }
    h3 { 
        font-weight: bold;
        font-size: 1.375rem;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: var(--purple1);
    }
    p {
        font-size: 1rem;
        line-height: 140%;
        color: var(--black);
    }
  
  @media screen and (max-width: 960px) {
    width: 41%;
  }
  
  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h3 {
      font-size: 16px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
    }
    .icon-wrap {
      width: 70px;
      //margin:  0 auto 10px;
      margin-bottom: 10px;
    }
    img {
      width: 35px;
    }
  }
 
`;

const Card = ({ card, page }) => {
    return (
        <CardStyled page={page}>
            <div className="icon-wrap">
                <img src={card.img} alt="" />
            </div>
            <h3>{card.title}</h3>
            <p>{card.description}</p>
        </CardStyled>
    )
}

export default Card
