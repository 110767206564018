import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {HeadlineP} from '../Fonts.Style';
import ServiceSideMenu from './ServiceSideMenu';
import imgRight from '../../assets/img/ServicesRight.png';
import imgLeft from '../../assets/img/servicesLeft.png';
import Center from './Center';
import useWindowSize from "../../hooks/useWindowSize";
import productIcon from "../../assets/img/productIcon.svg";
import teamIcon from "../../assets/img/teamIcon.svg";
import {useTranslation} from "react-i18next";
import Swipe from "react-easy-swipe";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import useDirection from "../../hooks/useDirection";
import {AnimationOnScroll} from 'react-animation-on-scroll';

const Wrapper = styled.section`
  min-height: calc(100vh - 100px);
  width: 100%;
  //max-width: 1700px;
  margin: 0 auto;
  padding: 20px 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 961px) {
    //animation: .5s linear 0s normal scrollServices;
  }
  @media screen and (max-width: 960px) {
    padding: 0 30px;
  }
  @media screen and (max-width: 500px) {
    min-height: calc(100vh - 80px)
  }
  @keyframes scrollServices {
    0% {
      padding-top: 100%;
    }
    100% {
      padding-top: 0;
    }
  }
`

const OurServicesStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    flex-wrap: wrap;
    .imgWrap {
      width: 100%;
    }
  }
`;

const OurServices = ({scrollUp, scrollDown, dir}) => {
    const screenSize = useWindowSize().width;
    const [activeSide, setActiveSide] = useState('left');
    const animation = dir ? "animate__fadeInUp" : "animate__fadeInDown";
    const handleActive = (side) => {
        setActiveSide(side);
    }
    const wrapRef = useRef();
    const {t} = useTranslation();

    useEffect(() => {
        setTimeout(() => window.scrollTo(0, 0), 200);
    }, [])

    const handleScroll = (isDown) => {

        if (wrapRef.current) {
            const {scrollHeight} = wrapRef.current;
            const bottom = (window.scrollY + window.innerHeight) > scrollHeight;
            if (isDown && bottom) {
                activeSide === "right" || screenSize > 960 ? scrollDown() : setActiveSide("right");
                setTimeout(() => window.scrollTo(0, 0), 200)
            }
            if (!isDown && window.scrollY <= 0) {
                activeSide === "left" || screenSize > 960 ? scrollUp() : setActiveSide("left");
                setTimeout(() => window.scrollTo(0, 0), 200)
            }
        }
    }

    return (

        <Wrapper ref={wrapRef} id="services">
            <ReactScrollWheelHandler upHandler={() => handleScroll(false)} downHandler={() => handleScroll(true)}>
                <Swipe onSwipeUp={() => handleScroll(true)} onSwipeDown={() => handleScroll(false)}>
                    {
                        screenSize > 960 ?
                            (
                                <AnimationOnScroll animateOnce={true} animateIn={animation} duration={.5}>
                                    <HeadlineP style={{margin: screenSize > 1500 ? '0 0 4rem' : '0 0 1rem'}}>{t("build.title")}</HeadlineP>
                                    <OurServicesStyled className="flex align-stretch">
                                        <ServiceSideMenu img={imgLeft} side="left" icon={productIcon}
                                                         returnActive={(s) => handleActive(s)}/>
                                        <Center activeSide={activeSide}/>
                                        <ServiceSideMenu img={imgRight} side="right" icon={teamIcon} alignItems='flex-end'
                                                         returnActive={(s) => handleActive(s)}/>
                                    </OurServicesStyled>
                                </AnimationOnScroll>
                            ) : (
                                <OurServicesStyled className="flex">
                                    {activeSide === "left" &&
                                        <ServiceSideMenu img={imgLeft} side="left" icon={productIcon}
                                                         returnActive={(s) => handleActive(s)}/>
                                    }
                                    {activeSide === "right" &&
                                        <ServiceSideMenu img={imgRight} side="right" icon={teamIcon} alignItems='flex-end'
                                                         returnActive={(s) => handleActive(s)}/>
                                    }
                                </OurServicesStyled>
                            )
                    }
                </Swipe>
            </ReactScrollWheelHandler>
        </Wrapper>
    )
}

export default OurServices
