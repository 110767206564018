import React from 'react';
import styled from "styled-components";

import CeoQuote from './CeoQuote';
import ButtonMain from '../Buttons/ButtonMain';
import {InnerLayout} from '../../styles/layouts';
import {HeadlineP, Heading} from "../Fonts.Style";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useDirection from "../../hooks/useDirection";

const OurWorkStyled = styled.section`
  //height: calc(100vh - 9rem) /* 1080px -> 67.5rem */;
  /* margin-bottom:15.25rem ; */
  height: calc(100vh - 100px);
  font-style: normal;
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
  justify-content: flex-start;
  align-items: center;
  direction: ${({isRtl}) => isRtl ? 'rtl' : 'ltr'};

  .left {
    width: 40%;
    max-width: 400px;
    justify-content: flex-end;
    height: 100%;

    img {
      position: absolute;
      max-height: 560px;
      z-index: 2;
      transform: translateY(-50%);
      top: 50%;
      max-width: 100%;
      left: ${({isRtl}) => isRtl ? '235px' : '0'};
      right: ${({isRtl}) => isRtl ? '0' : '235px'};
    }
  }

  .right {
    width: 50%;
    height: 100%;
    padding: 20px 0;
    max-width: 450px;
    margin-left: ${({isRtl}) => isRtl ? 'unset' : '10%'};
    margin-right: ${({isRtl}) => isRtl ? '10%' : 'unset'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .tags {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      ${({isRtl}) => isRtl ? 'margin-right' : 'margin-left'}: -4px;
    }
  }
`;

const CircleStyled = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1 ;
  border-radius: 50%;
  border: 4px solid var(--purple1);
  background: var(--grey5);
  align-self: center;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  z-index: 1;
  display: flex;
`;

const LinkStyled = styled.a`
  color: var(--purple1);
  text-transform: capitalize;
  line-height: 23px;
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
`;

const TagStyled = styled.p`
  color: var(--purple1);
  line-height: 21px;
  font-size: 14px;
  border: 1px solid var(--grey2);
  border-radius: 30px;
  padding: 5px 16px;
  margin: 8px 4px;
`;

const LoremStyled = styled.div`
  color: black;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 80px;
  p {
    margin-bottom: 10px;
  }
`;

const OurWork = ({company, page}) => {

    const {t} = useTranslation();
    const {isRtl} = useDirection();

    return (
        <InnerLayout>
            <OurWorkStyled isRtl={isRtl} className={"flex"}>
                <div className="left flex relative">
                    <img src={company.imgL || company.img} alt="" className={"img-left"}/>
                    <CircleStyled />
                </div>

                <div className="right">
                    <HeadlineP style={{marginBottom: '80px', textAlign: isRtl ? 'right' : 'left'}}>
                        {t("our_work")}
                    </HeadlineP>
                    <div className="content">
                        <div className="flex flex-start align-center" style={{marginBottom: '10px'}}>
                            <Heading style={{fontSize: '48px'}}>{company.companyName}</Heading>
                            <LinkStyled href={company.website} style={{margin: '16px 16px 0'}}
                                        target="_blank" rel="noopener noreferrer">
                                {t("btn.visit_site")}
                            </LinkStyled>
                        </div>
                        <div className="tags">
                            {company.projectTypes.map((tag, indx) => (<TagStyled key={indx}>{tag}</TagStyled>))}
                        </div>
                        <LoremStyled dangerouslySetInnerHTML={{__html: company.description}}></LoremStyled>
                    </div>
                    <Link to="/portfolio">
                        <ButtonMain title={t("btn.see_portfolio")} fontSize={"18px"} notBold={true} height={'48px'} width={'300px'}/>
                    </Link>
                </div>
            </OurWorkStyled>
        </InnerLayout>
    );
};

export default OurWork;
