import React from 'react';
import styled from "styled-components";

import { technologies } from '../../data/technologies';
import { GreyHeadline } from '../Fonts.Style';
import {useTranslation} from "react-i18next";

const CareersInfoStyled = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 9rem;
  .tech-list {
    width: 100%;
    max-width: 1320px;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const TechCard = styled.section`
    width: 103px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 138%;
    color: var(--black);
    margin: 0 26px 52px;
    text-align: center;

    .img-wrapper {
        width: 100%;
        aspect-ratio: 1 / 1;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F7F7F7;
        margin-bottom: 20px;
        img{
            width: 60%;
            aspect-ratio: 1 / 1;          
        }
    }
  
    @media screen and (max-width: 960px) {
      width: 82px;
      margin: 0 21px 42px;
      font-size: 16px;
    }
`;

const Technologies = () => {
    const {t} = useTranslation();
    return (
        <CareersInfoStyled>
            <GreyHeadline style={{ marginBottom: '4.75rem' }}>{t("technologies")}</GreyHeadline>
            <div className="flex tech-list">
                {technologies.map((tech, index) => (
                    <TechCard key={index}>
                        <div className="img-wrapper">
                            <img src={tech.img} alt="" />
                        </div>
                        <p>{tech.title}</p>
                    </TechCard >
                ))}
            </div>
        </CareersInfoStyled>
    )
}

export default Technologies;
