import React from 'react';
import styled from "styled-components";
import useDirection from "../../hooks/useDirection";

const CeoQuoteStyled = styled.div`
    display: flex;
    background: #F7F7F7;
    box-shadow: 0px 10px 40px -10px rgba(51, 51, 51, 0.24);
    border-radius: 24px;
    align-items: center;
    padding: 25px;
    width: 100%;
    margin: 2rem auto 0;
    position: relative;
    justify-content: space-between;
  
    .ceo-img{
        width: 75px;
        min-width: 75px;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        border: 3px solid var(--purple1);
        padding: 15px;
        background: white;
        img {            
            width: 100%;
            aspect-ratio: 1 / 1;
            object-fit: contain;
        }
    }

    .ceo-info{
        width: calc(100% - 125px);
        h2{
            font-weight: bold;
            font-size: 32px;
            ${({isRtl}) => isRtl ? "margin-left" : "margin-right"}: 15px;
        }
        p{
            font-size: 18px;
            color: var(--purple1);
        }
        .quote{
            padding-top:16px ;
            color: var(--black);
            font-weight: normal;
            font-size: 18px;
            line-height: 140%;
            letter-spacing: -0.02em
        }
    }
  
    .heading-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @media screen and (max-width: 1280px) {
      .heading-wrap {
        flex-direction: column;
      }
    }
`;

const TestimonialPortfolio = ({ company }) => {

    const {isRtl} = useDirection();


    return (
        <CeoQuoteStyled className="company-card" isRtl={isRtl}>
            <div className="ceo-img flex">
                <img src={company.logo} alt="" />
            </div>
            <div className="ceo-info flex column">
                <div className="heading-wrap">
                    <h2>{company.testimonial.name}</h2>
                    <p>{company.testimonial.position} - {company.companyName}</p>
                </div>
                <p className='quote'>{company.testimonial.text}</p>
            </div>
        </CeoQuoteStyled>
    );
};

export default TestimonialPortfolio;
