import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {clients} from "../../data/clientsData";
import {mondayProcessSteps} from "../../data/mondayProcessSteps";
import {salesforceProcessSteps} from "../../data/salesforceProcessSteps";
import React, {useEffect} from "react";
import useWindowSize from "../../hooks/useWindowSize";
import {useParams} from "react-router-dom";
import {SectionTitle} from "./index";

export default function Process() {
    const { t, i18n } = useTranslation();
    useEffect(() => window.scrollTo(0, 0), []);
    const screenSize = useWindowSize().width;
    const {subpage} = useParams();
    const stepName = subpage === 'monday' ? mondayProcessSteps : salesforceProcessSteps;
    const renderSteps = stepName[i18n.language.substring(0,2)] || clients.en;

    const ProcessContainer = styled.div`
      display: flex;
      padding-top: 65px;
      flex-direction: column;
      align-items: center;
      background: #FFFFFF;
      @media screen and (max-width: 960px) {
        padding-top: 40px;
      }
    `

    const ProcessSteps = styled.div`
      margin-top: 65px;
      width: 100%;
      max-width: 90%;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 960px) {
        margin-top: 50px;
      }
    `

    const ProcessStep = styled.div`
      :nth-child(even) {
        .text-container {
          order: 1;
        }

        .img-container {
          justify-content: flex-start;
        }
      }
      display: flex;
      margin-bottom: 200px;
      justify-content: space-between;
      .text-container {
        width: 100%;
        max-width: 48%;
        display: flex;
        text-transform: capitalize;
        color: #333333;
        flex-direction: column;
        align-items: flex-start;
        .step-number {
          background: #9146FF;
          border-radius: 7px;
          padding: 5px 22px;
          gap: 10px;
          text-transform: uppercase;
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
          color : #FFFFFF;
        }
        .title {
          color: #333333;
          margin-bottom: 12px;
          font-weight: 700;
          font-size: 40px;
          line-height: 56px;
          text-transform: capitalize;
          margin-top: 24px;
        } 
        .desc {
          font-size: 24px;
          font-weight: 300;
          line-height: 34px;
          text-transform: capitalize;
          color: #000000; 
          p:first-of-type {
            margin-bottom: 1rem;
          } 
        } 
        .read-more {
          font-weight: 700;
          font-size: 16px;
          line-height: 23px;
          text-decoration-line: underline;
          text-transform: capitalize;
          color: #9146FF;
          text-align: center;
          cursor: pointer;
        } 
      }
      .img-container {
        width: 100%;
        max-width: 48%;
        display: flex;
        justify-content: flex-end;
      }
      @media screen and (max-width: 960px) {
        flex-direction: column;
        margin-bottom: 78px;
        .text-container {
          max-width: 100%;
          .step-number {
            font-size: 14px;
            line-height: 20px;
            padding: 4px 16px;
          }
          .title {
            margin-bottom: 24px;
            font-size: 20px;
            line-height: 28px;
          } 
          .desc { 
            p {
              font-size: 16px;
              line-height: 22px;
            } 
            p:nth-of-type(2) {
              display: none;
            } 
          }
        }
        .img-container {
          max-width: 100%;
          margin-top: 35px;
          justify-content: center;
          order: 1;
        }
        :nth-child(even) {
          .img-container {
            justify-content: center;
          }
        }
      }
    `
    return <ProcessContainer>
        <SectionTitle title={t("our_process")}/>
        <ProcessSteps className="steps">
            {renderSteps.map((step) => (
                <ProcessStep key={step.number} className="step">
                    <div className="text-container">
                        <div className="step-number">{step.number}</div>
                        <div className="title">{step.title}</div>
                        <div className="desc">
                            <p>{step.description}</p>
                        </div>
                    </div>
                    <div className="img-container">
                        <img src={step.img} alt="Step Img"/>
                    </div>
                </ProcessStep>
            ))}
        </ProcessSteps>
    </ProcessContainer>
}