import React, {useEffect} from 'react';
import styled from "styled-components";
import {team} from "../../data/TeamData";
import {useTranslation} from "react-i18next";
import useDirection from "../../hooks/useDirection";

const TheTeamContainer = styled.section`
  width: 100%;
  .top, 
  .bottom {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 220px;
    &:before {
      content: '';
      width: 84%;
      aspect-ratio: 1 / 1;
      border: 3px solid #9146FF;
      border-radius: 50%;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      z-index: -1;
    }
  }
  .left, 
  .right {
    position: relative;
    overflow: hidden;
  }
  .top {
    .left {
      width: 136px;
      min-width: 136px;
      ${({isRtl}) => isRtl ? 'margin-left' : 'margin-right'}: 20px;
      height: 100%;
      .member {
        ${({isRtl}) => isRtl ? 'right' : 'left'}: 14px;
        &.one {
          animation: 10s linear 0s infinite normal top-one-member;
        }
        &.two {
          animation: 10s linear 0s infinite normal top-two-member;
        }
        &:before {
          content: '';
          position: absolute;
          background: white;
          width: 20px;
          height: 100%;
          ${({isRtl}) => isRtl ? 'right' : 'left'}: -20px;
          z-index: 2;
        }
      }
    }
    .right {
      display: flex;
      width: calc((100% - 156px) * 2);
      min-width: calc((100% - 156px) * 2);
      position: absolute;
      z-index: -1;
      transform: ${({isRtl}) => isRtl ? 'unset' : 'translateX(-50%)'};
      animation: 10s linear 0s infinite normal slide-top-name;
    }
    .member-info {
      text-align: ${({isRtl}) => isRtl ? 'right' : 'left'};
      width: 50%;
    }
    :before {
      ${({isRtl}) => isRtl ? 'right' : 'left'}: -65%;
    }
  }
  .bottom {
    justify-content: flex-end;
    .left {
      display: flex;
      width: calc((100% - 178px) * 2);
      min-width: calc((100% - 178px) * 2);
      transform: translateX(-50%);
      left: ${({isRtl}) => isRtl ? 'unset' : 'calc(100% - 178px)'};
      flex-direction: ${({isRtl}) => isRtl ? 'row-reverse' : 'row'};
      z-index: -1;
      animation: 10s linear 0s infinite normal slide-bottom-name;
    }
    .right {
      width: 158px;
      min-width: 158px;
      position: relative;
      ${({isRtl}) => isRtl ? 'margin-right' : 'margin-left'}: 20px;
      height: 100%;
      .member {
        &:before {
          content: '';
          position: absolute;
          background: white;
          width: 36px;
          height: 100%;
          ${({isRtl}) => isRtl ? 'left' : 'right'}: -36px;
        }
        &.one {
          animation: 10s linear 2.5s infinite normal bottom-one-member;
        }
        &.two {
          animation: 10s linear 2.5s infinite normal bottom-two-member;
        }
      }
    }
    .member-info {
      text-align: ${({isRtl}) => isRtl ? 'left' : 'right'};
    }
    :before {
      ${({isRtl}) => isRtl ? 'left' : 'right'}: -60%;
    }
  }
  
  .member {
    width: 122px;
    aspect-ratio: 1 / 1;
    background: white;
    display: flex;
    position: absolute;
    top: 50%;
    opacity: 0;
    transform: translateY(-50%) scale(.75);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 7px solid #9146FF;
      border-radius: 50%;
    }
  }
  
  .member-info {
    width: 50%;
    .name {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 120%;
      color: #333333;
    }
    .position {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      color: #000000;
    }
  }
  
  @keyframes slide-top-name {
    0% {
      left: ${({isRtl}) => isRtl ? 'calc(-100vw + 156px)' : '100%'};
    }
    46% {
      left: ${({isRtl}) => isRtl ? 'calc(-100vw + 156px)' : '100%'};
    }
    50% {
      left: ${({isRtl}) => isRtl ? '0' : '156px'};
    }
    96% {
      left: ${({isRtl}) => isRtl ? '0' : '156px'};
    }
    100% {
      left: ${({isRtl}) => isRtl ? 'calc(-100vw + 156px)' : '100%'};
    }
  }

  @keyframes slide-bottom-name {
    21% {
      transform: translateX(-50%);
      left: ${({isRtl}) => isRtl ? 'unset' : 'calc(100% - 178px)'};
    }
    25% {
      transform: translateX(50%);
      left: ${({isRtl}) => isRtl ? 'calc(-100% + 178px)' : 'unset'};
    }
    71% {
      transform: translateX(50%);
      left: ${({isRtl}) => isRtl ? 'calc(-100% + 178px)' : 'unset'};
    }
    75% {
      transform: translateX(-50%);
      left: ${({isRtl}) => isRtl ? 'unset' : 'calc(100% - 178px)'};
    }
  }

  @keyframes top-one-member {
    0% {
      top: 50%;
      opacity: 1;
      transform: translateY(-50%) scale(1);
      left: ${({isRtl}) => isRtl ? 'unset' : '14px'};
      right: ${({isRtl}) => isRtl ? '14px' : 'unset'};
    }
    46% {
      top: 50%;
      opacity: 1;
      transform: translateY(-50%) scale(1);
      left: ${({isRtl}) => isRtl ? 'unset' : '14px'};
      right: ${({isRtl}) => isRtl ? '14px' : 'unset'};
    }
    50% {
      top: -50%;
      opacity: 0;
      transform: translateY(-50%) scale(.75);
      left: ${({isRtl}) => isRtl ? 'unset' : '-50%'};
      right: ${({isRtl}) => isRtl ? '-50%' : 'unset'};
    }
    96% {
      top: 150%;
      opacity: 0;
      transform: translateY(-50%) scale(.75);
      left: ${({isRtl}) => isRtl ? 'unset' : '-50%'};
      right: ${({isRtl}) => isRtl ? '-50%' : 'unset'};
    }
    100% {
      top: 50%;
      top: 50%;
      opacity: 1;
      transform: translateY(-50%) scale(1);
      left: ${({isRtl}) => isRtl ? 'unset' : '14px'};
      right: ${({isRtl}) => isRtl ? '14px' : 'unset'};
    }
  }

  @keyframes top-two-member {
    0% {
      top: 150%;
      opacity: 0;
      transform: translateY(-50%) scale(.75);
      left: ${({isRtl}) => isRtl ? 'unset' : '-50%'};
      right: ${({isRtl}) => isRtl ? '-50%' : 'unset'};
    }
    46% {
      top: 150%;
      opacity: 0;
      transform: translateY(-50%) scale(.75);
      left: ${({isRtl}) => isRtl ? 'unset' : '-50%'};
      right: ${({isRtl}) => isRtl ? '-50%' : 'unset'};
    }
    50% {
      top: 50%;
      opacity: 1;
      transform: translateY(-50%) scale(1);
      left: ${({isRtl}) => isRtl ? 'unset' : '14px'};
      right: ${({isRtl}) => isRtl ? '14px' : 'unset'};
    }
    96% {
      top: 50%;
      opacity: 1;
      transform: translateY(-50%) scale(1);
      left: ${({isRtl}) => isRtl ? 'unset' : '14px'};
      right: ${({isRtl}) => isRtl ? '14px' : 'unset'};
    }
    100% {
      top: -50%;
      opacity: 0;
      transform: translateY(-50%) scale(.75);
      left: ${({isRtl}) => isRtl ? 'unset' : '-50%'};
      right: ${({isRtl}) => isRtl ? '-50%' : 'unset'};
    }
  }

  @keyframes bottom-one-member {
    0% {
      top: 50%;
      opacity: 1;
      transform: translateY(-50%) scale(1);
      left: ${({isRtl}) => isRtl ? 'unset' : 'calc(50% - 72px)'};
      right: ${({isRtl}) => isRtl ? 'calc(50% - 72px)' : 'unset'};
    }
    46% {
      top: 50%;
      opacity: 1;
      transform: translateY(-50%) scale(1);
      left: ${({isRtl}) => isRtl ? 'unset' : 'calc(50% - 72px)'};
      right: ${({isRtl}) => isRtl ? 'calc(50% - 72px)' : 'unset'};
    }
    50% {
      top: -50%;
      opacity: 0;
      transform: translateY(-50%) scale(.75);
      left: ${({isRtl}) => isRtl ? 'unset' : '100%'};
      right: ${({isRtl}) => isRtl ? '100%' : 'unset'};
    }
    96% {
      top: 150%;
      opacity: 0;
      transform: translateY(-50%) scale(.75);
      left: ${({isRtl}) => isRtl ? 'unset' : '100%'};
      right: ${({isRtl}) => isRtl ? '100%' : 'unset'};
    }
    100% {
      top: 50%;
      opacity: 1;
      transform: translateY(-50%) scale(1);
      left: ${({isRtl}) => isRtl ? 'unset' : 'calc(50% - 72px)'};
      right: ${({isRtl}) => isRtl ? 'calc(50% - 72px)' : 'unset'};
    }
  }

  @keyframes bottom-two-member {
    0% {
      top: 150%;
      opacity: 0;
      transform: translateY(-50%) scale(.75);
      left: ${({isRtl}) => isRtl ? 'unset' : '100%'};
      right: ${({isRtl}) => isRtl ? '100%' : 'unset'};
    }
    46% {
      top: 150%;
      opacity: 0;
      transform: translateY(-50%) scale(.75);
      left: ${({isRtl}) => isRtl ? 'unset' : '100%'};
      right: ${({isRtl}) => isRtl ? '100%' : 'unset'};
    }
    50% {
      top: 50%;
      opacity: 1;
      transform: translateY(-50%) scale(1);
      left: ${({isRtl}) => isRtl ? 'unset' : 'calc(50% - 72px)'};
      right: ${({isRtl}) => isRtl ? 'calc(50% - 72px)' : 'unset'};
    }
    96% {
      top: 50%;
      opacity: 1;
      transform: translateY(-50%) scale(1);
      left: ${({isRtl}) => isRtl ? 'unset' : 'calc(50% - 72px)'};
      right: ${({isRtl}) => isRtl ? 'calc(50% - 72px)' : 'unset'};
    }
    100% {
      top: -50%;
      opacity: 0;
      transform: translateY(-50%) scale(.75);
      left: ${({isRtl}) => isRtl ? 'unset' : '100%'};
      right: ${({isRtl}) => isRtl ? '100%' : 'unset'};
    }
  }
`;

const TheTeamMobile = () => {

    const{i18n} = useTranslation();
    const renderTeam = team[i18n.language.substring(0, 2)] || team.en;
    const {isRtl} = useDirection();

    return (
        <TheTeamContainer isRtl={isRtl}>
            <div className="top">
                <div className="left">
                    <div className="member one">
                        <img src={renderTeam.israel.team[0].img} alt=""/>
                    </div>
                    <div className="member two">
                        <img src={renderTeam.israel.team[5].img} alt=""/>
                    </div>
                </div>
                <div className="right">
                    <div className="member-info">
                        <p className="name">{renderTeam.israel.team[0].teamMember}</p>
                        <p className="position">{renderTeam.israel.team[0].position}</p>
                    </div>
                    <div className="member-info">
                        <p className="name">{renderTeam.israel.team[5].teamMember}</p>
                        <p className="position">{renderTeam.israel.team[5 ].position}</p>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="left">
                    <div className="member-info">
                        <p className="name">{renderTeam.israel.team[1].teamMember}</p>
                        <p className="position">{renderTeam.israel.team[1].position}</p>
                    </div>
                    <div className="member-info">
                        <p className="name">{renderTeam.israel.team[3].teamMember}</p>
                        <p className="position">{renderTeam.israel.team[3].position}</p>
                    </div>
                </div>
                <div className="right">
                    <div className="member one">
                        <img src={renderTeam.israel.team[1].img} alt=""/>
                    </div>
                    <div className="member two">
                        <img src={renderTeam.israel.team[3].img} alt=""/>
                    </div>
                </div>
            </div>
        </TheTeamContainer>
    )
}

export default TheTeamMobile
