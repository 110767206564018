import React from 'react';
import styled from "styled-components";

import teamImg from "../../assets/img/ServicesRight.png";
import productImg from "../../assets/img/servicesLeft.png";

import {useTranslation} from "react-i18next";
import useWindowSize from "../../hooks/useWindowSize";

const BuildInfoStyled = styled.main`
    display: flex;
    flex-direction: column;
    width: 40%;
    max-width: 400px;
    h1{
        font-weight: bold;
        font-size: 2.625rem;
        line-height: 105%;
        color: var(--black);
        margin-bottom: 1.5rem /* 24px -> 1.5rem */ ;
    }
    p{
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 144%;
        /* font-family: 'sofia Pro'; */
        margin-bottom: 40px /* 55px -> 3.4375rem */;
    }
    .pics-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        img:nth-child(1) {
          width: 100%;
        }
    }
      @media screen and (max-width: 960px) {
        width: 100%;
        max-width: unset;
        h1 {
          font-size: 32px;
        }
      }

`;

const BuildInfo = ({isTeam}) => {

    const {t} = useTranslation();
    const screenSize = useWindowSize().width;

    return (
        <BuildInfoStyled>
            <h1>{isTeam ? t(`build.team.servicesTitle`) : t(`build.product.servicesTitle`)}</h1>
            <p>{isTeam ? t(`build.team.servicesDescription`) : t(`build.product.servicesDescription`)}</p>
            {screenSize > 960 && <div className="pics-container">
                <img src={isTeam ? teamImg : productImg} alt="" />
            </div>}
        </BuildInfoStyled>
    )
}

export default BuildInfo
