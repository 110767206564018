import React from 'react';
import styled from "styled-components";
import { Link } from 'react-router-dom';

import Maps from './Maps';
import OfficeGallery from './OfficeGallery';
import ButtonMain from '../Buttons/ButtonMain';
import { HeadlineP, HeadlineH1 } from '../Fonts.Style';
import {useTranslation} from "react-i18next";
import ContactForm from "../ContactForm/ContactForm";
import useWindowSize from "../../hooks/useWindowSize";

const OurOfficeStyled = styled.section`
    width: 100%;
    max-width: 1320px;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 8.5rem;
    .contac-form {
      padding: 0;
    }
`;


const OurOffice = () => {

    const {t} = useTranslation();
    const screenSize = useWindowSize().width;

    return (
        <OurOfficeStyled>
            <HeadlineP style={{ marginBottom: screenSize > 500 ? '5.5rem' : '45px' }}>{t("our_offices")}</HeadlineP>
            <OfficeGallery />
            <Maps />
            {
                screenSize > 960
                    ? (
                        <>
                            <HeadlineH1 comp="ourOffice">{t("contact_form.productive_together")}</HeadlineH1>
                            {/*<Link to="/positions">
                                <ButtonMain title={t("btn.see_openings")} fontSize='18px' width="596px" height="64px" />
                            </Link>*/}
                        </>
                    )
                    : (
                        <ContactForm/>
                    )
            }
        </OurOfficeStyled>
    )
}

export default OurOffice;
