import styled from "styled-components";

export const HireMain = styled.form`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  max-width: 500px;
  margin: 0 auto;
  .hidden {
    display: none;
  }
  .no-step {
    flex: 1;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      h2 {
        font-size: 32px;
        font-weight: 700;
        color: #9146ff;
        span {
          color: #333333;
        }
      }
      p {
        font-size: 20px;
        color: black;
        margin-bottom: 70px;
        &.time {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          margin-top: 30px;
          img {
            ${({isRtl}) => isRtl ? "margin-left" : "margin-right" }: 8px;
          }
        }
      }
    }
    .bottom {
      flex: 1;
      display: flex;
      flex-direction: column;
      .content {
        margin-top: auto;
        font-size: 14px;
        padding: 0 10px;
        text-align: center;
      }
    }
  }
  .steps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .step {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    .step-name {
      font-weight: bold;
      font-size: 20px;
      color: black;
      display: flex;
      width: 100%;
      position: relative;
      ${({isRtl}) => isRtl ? "padding-right" : "padding-left" }: 38px;
      margin-bottom: 40px;
    }
    .step-number {
      border-radius: 50%;
      width: 30px;
      aspect-ratio: 1/1;
      background: #9146ff;
      color: white;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      ${({isRtl}) => isRtl ? "right" : "left" }: 0;
      top: 0;
    }
    .error {
      font-weight: 500;
      font-size: 14px;
      line-height: 121%;
      letter-spacing: 0.01em;
      color: rgba(95, 33, 32, 0.85);
      background: #FDEDED;
      border-radius: 4px;
      padding: 4px 8px 4px 4px
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      align-self: flex-start;
      margin-top: 20px;
      img {
        margin: 0 4px;
      }
    }
    .options {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .multiple {
      font-weight: 500;
      font-size: 14px;
      line-height: 121%;
      letter-spacing: 0.01em;
      color: #5B5B5B;
      margin-bottom: 10px;
    }
    .option {
      cursor: pointer;
    }
    .option, input {
      width: 100%;
      padding: 12px 14px;
      border: 1px solid #9146FF;
      border-radius: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: #9146FF;
      transition: a .25s;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;
      .radio {
        margin: 0 10px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #9146FF;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          transform: translate(-50%,-50%);
          top: 50%;
          left: 50%;
          width: 16px;
          background: white;
          height: 16px;
          border-radius: 50%;
        }
      }
      &.active {
        background: #9146ff;
        color: white;
        .radio {
          border-color: white;
        }
      }
    }
    input {
      padding: 12px 24px;
    }
    .MuiInput-root {
      border: none;
      &:before, &:after {
        display: none;
      }
    }
  }
  .doneWrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: stretch;
    background: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(2px);
    .doneContent {
      background: white;
      border-radius: 12px 12px 0px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .top {
        padding-top: 100px;
        border-bottom: 1px solid #F0F0F0;
        width: 100%;
        margin-bottom: 40px;
        position: relative;
        img  {
          position: absolute;
          background: white;
          transform: translate(-50%, -50%);
          top: 100%;
          left: 50%;
        }
      }
      .doneHeading {
        font-weight: 700;
        font-size: 38px;
        line-height: 50px;
        color: #444446;
      }
      
      .doneSubHeading {
        font-weight: 400;
        font-size: 16px;
        line-height: 121%;
        letter-spacing: 0.01em;
        color: #7A7A7A;
      }
      
      .doneText {
        font-weight: 400;
        font-size: 18px;
        line-height: 133%;
        text-align: center;
        letter-spacing: 0.01em;
        color: #1A1A1A;
        margin-top: 40px;
        margin-bottom: 24px;
      }
      a {
        margin-bottom: 80px;
      }
    }
  }
  @media screen and (max-width: 960px) {
    
  }
`