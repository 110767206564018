import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import logoHero from '../../assets/img/hero-logo.svg';
import Burger from './Burger';
import { InnerLayout } from '../../styles/layouts';
import ButtonNavbar from '../Buttons/ButtonNavbar';
import NavList from './NavList';
import RightNav from "./RightNav";
import {useTranslation} from "react-i18next";

const Nav = styled.nav`
	height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: rgba(255,255,255,.8);
	backdrop-filter: blur(10px);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 0 30px;
	z-index: 20;

	.grow {
		flex-grow: 1;
		margin-left: 20px;
		justify-content: flex-end;
		align-items: center;
	}
	
	.nav-right {
		width: 100%;
		align-items: center;
		padding: 0 80px;
	}
	
	.logoImg {
		object-fit: cover;
		//width: 168px;
		height: 40px;
		display: flex;
	}

	.navBurger{
		width: 40px;
		align-self: center;
	}
	
	.space {
		flex-grow: 5;
	}

	@media screen and (max-width: 960px){
		//padding: 0 7%;
		.nav-right {
			display: none;
		}
	}

	@media screen and (max-width: 500px) {
		.logoImg {
			height: 22px;
		}
		height: 5rem;
		background: #FFF;
		backdrop-filter: none;
	}
`;

const Navbar = () => {
	const [sideNav, setSideNav] = useState(false);
	const {t} = useTranslation();

	return (
		<InnerLayout>
			<Nav>
				<div className="logo flex">
					<Link   to="/">
						<img src={logoHero} alt="" className="logoImg" />
					</Link>
				</div>
				<div className="flex grow">
					<div className="nav-right flex">
						<div className="space"></div>
						<ButtonNavbar link={"/contacts"} text={t("nav.get_in_touch")} padding={"50px"} />
						<NavList />
					</div>
				</div>
			</Nav>
			<RightNav open={sideNav} toggleMenu={(state) => setSideNav(state)}/>
			<Burger open={sideNav} toggleMenu={(state) => setSideNav(state)}/>
		</InnerLayout>
	);
};

export default Navbar;
