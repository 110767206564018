import React, {useRef, useState} from 'react';
import styled from "styled-components";
import { companies } from "../../data/companies";
import OurWork from "../OurWork/OurWork";
import Slider from "react-slick";
import arrow from "../../assets/img/Arrow - Right Circle.svg";
import arrowHover from "../../assets/img/Arrow - Right CircleHover.svg";
import {useTranslation} from "react-i18next";
import Swipe from "react-easy-swipe";
import useDirection from "../../hooks/useDirection";

const SliderStyled = styled.section`
  position: relative;
  height: calc(100vh - 100px);
  .slick-slider {
    position: relative;
    height: 100%;
  }
  .arrows {
    display: flex;
    width: 90%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }
  .arrow-wrap {
    width: 96px;
    height: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover .arrow {
      //opacity: 1;
      //width: 90%;
      //height: 90%;
    }
  }
  .arrow {
    width: 100%;
    height: 100%;
    //opacity: .5;
    transition: all .25s;
    transform: ${({isRtl}) => isRtl ? 'rotate(180deg)' : 'unset'};
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .arrow-prev {
    left: 50px;
    transform: ${({isRtl}) => isRtl ? 'unset' : 'rotate(180deg)'};
  }

  .arrow-next {
    right: 50px;
  }
  
  .slick-dots {
    bottom: 4%;
    ${({isRtl}) => isRtl ? "right" : "left"}: 25%;
    display: flex !important;
    width: fit-content;
    li {
      margin: 0 3px ;
      width: 70px;
      button {
        width: 100%;
        height: 10px;
        background: #D9D9D9;
        border-radius: 20px;  
        &:before {
          display: none;
        }
      }
      
      &.slick-active button{
        background: #9146FF;
      }
    }
  }

  @media screen and (min-width: 961px) {
    animation: .7s linear 0s normal scrollWorkSlider;
  }

  @keyframes scrollWorkSlider {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const OurWorkSlider = ({scrollUp, scrollDown}) => {

    const { i18n } = useTranslation();
    const renderCompanies = companies[i18n.language.substring(0, 2)] || companies.en;
    renderCompanies.length = 4;
    const wrapperRef = useRef();
    const {isRtl} = useDirection();
    const [hoverPrev, setHoverPrev] = useState(false);
    const [hoverNext, setHoverNext] = useState(false);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrow: false,
        rtl: isRtl
    };

    const sliderRef = useRef(null);

    const handleScroll = (isDown) => {
        if (wrapperRef.current) {
            const {scrollHeight} = wrapperRef.current;
            const bottom = (window.scrollY + window.innerHeight) > scrollHeight;
            isDown && bottom && scrollDown();
            !isDown && window.scrollY <= 0 && scrollUp();
        }
    }

    const prev = () => sliderRef.current.slickPrev();

    const next = () => sliderRef.current.slickNext();

    return (
        <section ref={wrapperRef}>
            {/*<ReactScrollWheelHandler*/}
            {/*    upHandler={() => handleScroll(false)}*/}
            {/*    downHandler={() => handleScroll(true)}*/}
            {/*    preventScroll={false}*/}
            {/*>*/}
                <Swipe onSwipeUp={() => handleScroll(true)} onSwipeDown={() => handleScroll(false)}>
                    <SliderStyled isRtl={isRtl}>
                        <Slider {...settings} ref={sliderRef}>
                            {
                                renderCompanies.map((company, i) => (
                                    <OurWork company={company} page="home" key={i}/>
                                ))
                            }
                        </Slider>
                        <div className="arrows">
                            <div className="arrow-wrap" onClick={() => isRtl ? next() : prev()}>
                                <div className="arrow arrow-prev"
                                     onMouseEnter={() => setHoverPrev(true)}
                                     onMouseLeave={() => setHoverPrev(false)}
                                >
                                    <img src={hoverPrev ? arrowHover : arrow} alt="prev"/>
                                </div>
                            </div>
                            <div className="arrow-wrap" onClick={() => isRtl ? prev() : next()}>
                                <div className="arrow arrow-next"
                                     onMouseEnter={() => setHoverNext(true)}
                                     onMouseLeave={() => setHoverNext(false)}
                                >
                                    <img src={hoverNext ? arrowHover : arrow} alt="next"/>
                                </div>
                            </div>
                        </div>
                    </SliderStyled>
                </Swipe>
            {/*</ReactScrollWheelHandler>*/}
        </section>
    )
}

export default OurWorkSlider
