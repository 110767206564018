import Hero from '../components/Hero/Hero';
import OurProcess from '../components/OurProcess/OurProcess';
import ContactForm from '../components/ContactForm/ContactForm';
import OurServices from '../components/OurServices/OurServices';
import Testimonials from '../components/Testimonials/Testimonials';
import MeetTheTeam from '../components/MeetTheTeam/MeetTheTeam';
import React, {createRef, useEffect, useRef, useState} from "react";
import OurWorkSlider from "../components/OurWorkSlider/OurWorkSlider";
import useWindowSize from "../hooks/useWindowSize";
import OurWorkMobile from "../components/OurWorkMobile/OurWorkMobile";
import {useTranslation} from "react-i18next";
import { useInView } from 'react-hook-inview';
import Swipe from "react-easy-swipe";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import useScrollBlock from "../hooks/useScrollBlock";

export function Home() {
	const screenSize = useWindowSize().width;
	const sectionsCount = 7;
	const [activeSection, setActiveSection] = useState(1);
	const [progressTitle, setProgressTitle] = useState('');
	const [isScrolling, setIsScrolling] = useState(null);
	const [dir, setDir] = useState(true);
	const {t} = useTranslation();
	const [refTestimonials, inViewTestimonials] = useInView();
	const [refTeam, inViewTeam] = useInView();
	const [refContact, inViewContact] = useInView();
	const lastRef= createRef();
	const wrapperRef = useRef();
	const contacts = useRef();
	const [blockScroll, allowScroll] = useScrollBlock();

	const scrollDown = () => {
		if(!isScrolling) {
			setDir(true);
			activeSection < sectionsCount && setActiveSection(activeSection + 1);
			setIsScrolling(500);
		}
	}

	const scrollUp = () => {
		if(!isScrolling) {
			setDir(false);
			activeSection > 1 && setActiveSection(activeSection - 1);
			setIsScrolling(500);
		}

	}

	const handleScroll = (e, isDown) => {
		if (!isScrolling && wrapperRef.current && activeSection !== 4 && e && !e.target.closest("#services") ) {
			setIsScrolling(500);
			const {scrollHeight} = wrapperRef.current;
			const bottom = (window.scrollY + window.innerHeight) >= scrollHeight;
			if (isDown && bottom) {
				scrollDown();
			}
			if (!isDown && window.scrollY <= 0) {
				scrollUp();
			}
		}
	}

	const handleLastScrollUp = () => lastRef.current && window.scrollY <= 0 && scrollUp();

	const scrollToContacts = () => {
		setActiveSection(7);
		setTimeout(() => {
			window.scrollTo(0, contacts.current.offsetTop - 50)
		}, 500)
	}

	useEffect(() => window.scrollTo(0, 0), []);

	useEffect(() => {
		setProgressTitle(t(`progress.${activeSection}`));
		document.getElementById("footer").style.display = activeSection >= 5 ? "flex" : "none";
		return () => document.getElementById("footer").style.display = "flex";
	}, [activeSection]);

	useEffect(() => {
		if(isScrolling) {
			blockScroll();
			setTimeout(() => setIsScrolling(null), isScrolling);
		} else {
			allowScroll();
		}

	}, [isScrolling]);

	useEffect(() => inViewTestimonials && setActiveSection(5), [inViewTestimonials]);
	useEffect(() => inViewTeam && setActiveSection(6), [inViewTeam]);
	useEffect(() => inViewContact && setActiveSection(7), [inViewContact]);

	return (
		<main style={{paddingTop: screenSize > 500 ? "100px" : "80px", msOverflowStyle: 'none', scrollbarWidth: "0"}} ref={wrapperRef}>
			<ReactScrollWheelHandler
			    upHandler={(e) => handleScroll(e, false)}
			    downHandler={(e) => handleScroll(e, true)}
			    preventScroll={false}
			>
				{activeSection === 1 && <Hero scrollDown={scrollDown} handleToContacts={scrollToContacts} dir={dir}/>}
				{activeSection === 2 && <OurServices scrollDown={scrollDown} scrollUp={scrollUp} dir={dir}/>}
				{activeSection === 3 &&
					<>
						{screenSize > 960
							? <AnimationOnScroll animateOnce={true} animateIn={dir ? "animate__fadeInUp" : "animate__fadeInDown"} duration={.5}>
								<OurWorkSlider scrollDown={scrollDown} scrollUp={scrollUp} />
							</AnimationOnScroll>
							: <OurWorkMobile scrollDown={scrollDown} scrollUp={scrollUp} />
						}
					</>
				}
				{activeSection === 4 &&
					<>
						{
							screenSize > 960
							? <AnimationOnScroll animateOnce={true} animateIn={dir ? "animate__fadeInUp" : "animate__fadeInDown"} duration={.5}>
								<OurProcess scrollDown={scrollDown} scrollUp={scrollUp} handleToContacts={scrollToContacts} />
							</AnimationOnScroll>
							:
							<AnimationOnScroll animateOnce={true} animateIn={"animate__fadeInLeft"} duration={.5}>
								<OurProcess scrollDown={scrollDown} scrollUp={scrollUp} handleToContacts={scrollToContacts} />
							</AnimationOnScroll>
						}
					</>
				}
				{(activeSection >= 5 && activeSection <= 7) && (
					<AnimationOnScroll animateOnce={true} animateIn={"animate__fadeInUp"} duration={.5}>
						<ReactScrollWheelHandler upHandler={(e) => handleLastScrollUp(e)} ref={lastRef}>
							<Swipe onSwipeDown={() => handleLastScrollUp()}>
								<div ref={refTestimonials}><Testimonials /></div>
								<div ref={refTeam}><MeetTheTeam /></div>
								<div ref={refContact}><div ref={contacts}><ContactForm page="home" /></div></div>
							</Swipe>
						</ReactScrollWheelHandler>
					</AnimationOnScroll>
				)}

				{/*<AppProgressBar width={`${100 / sectionsCount * activeSection}%`} title={progressTitle} />*/}
			</ReactScrollWheelHandler>
		</main>
	);
}
