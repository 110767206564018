import Services from "./Services";
import Customers from "./Customers";
import ContactForm from "../../components/ContactForm/ContactForm";
import React, {useEffect} from "react";
import useWindowSize from "../../hooks/useWindowSize";
import AboutUs from "./AboutUs";
import WelcomeTop from "./WelcomeTop";
import styled from "styled-components";

export default function InformationSystem() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const screenSize = useWindowSize().width;
  const handleScrollToElement = () => {
    window.scrollTo(0, (document.getElementById("contact").offsetTop)-185);
  }
  return <div>
    <WelcomeTop handleScrollToElement={handleScrollToElement}/>
    <Services />
    <AboutUs />
    {screenSize > 500 &&  <Customers page="InformationSystem"/>}
    {screenSize > 500 && <ContactForm />}
  </div>
}

export const SectionTitle = ({ title }) => {
  const SectionTitleStyled = styled.div`
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      line-height: 16px;
      color: #BFBFBF;
      @media screen and (max-width: 960px) {
        font-size: 16px;
      }
    `
  return (
      <SectionTitleStyled>
        {title}
      </SectionTitleStyled>
  );
};