import React, {useEffect} from 'react';
import ContactForm from '../components/ContactForm/ContactForm';
import styled from 'styled-components';

const Wrap = styled.section`
    min-height: 100vh;
    display: flex;
    padding-top: 100px;
    align-items: center;
    > div {
      margin: 0 auto;
    }
`

const Contacts = () => {

    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <Wrap>
            <ContactForm />
        </Wrap>
    )
}

export default Contacts

