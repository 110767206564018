import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import frame32 from "../../assets/img/Frame32.svg"
import frame32w from "../../assets/img/frame32W.svg"
import category from "../../assets/img/Category.svg"
import categoryA from "../../assets/img/CategoryA.svg"
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import useDirection from "../../hooks/useDirection";

const SwitchButton = styled.div`
  width: 60%;
  height: 4rem;
  display: flex;
  background-color: var(--grey4);
  border-radius: 100px;
  align-items: center;
  margin-bottom: 60px;
  justify-content: center;
  position: relative;
  .team, .product {
    position: relative;
    z-index: 2;
    transition: 0.6s;
    width: calc(50% - 5px);
    height: 87%;
    border-radius: 50px;
    //background-color: ${props => props.isTeam ? 'var(--purple1)' : 'var(--grey4)'};
    background: transparent;
    color: ${props => props.isTeam ? 'var(--white)' : 'var(--purple1)'};
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.5rem;
    cursor: pointer;
    opacity: ${props => props.isTeam ? '1' : '0.6'};
    transition: all .3s;

    .img-wrap {
      margin: 0 .5rem;
      background-color: ${props => props.isTeam ? 'var(--white)' : 'var(--purple1)'};
      border-radius: 50px;
      width: 40px;
      aspect-ratio: 1 / 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 100%;
    }
  }
  
  .active {
    position: absolute;
    z-index: 1;
    width: calc(50% - 5px);
    height: 87%;
    background: var(--purple1);
    ${({isRtl}) => isRtl ? "left" : "right"}: 5px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50px;
    transition: all .3s;
    &.team {
      ${({isRtl}) => isRtl ? "margin-left" : "margin-right"}: calc(50% - 5px);
    }
  }

  .product {
    color: ${props => !props.isTeam ? 'var(--white)' : 'var(--purple1)'};
    //background-color: ${props => !props.isTeam ? 'var(--purple1)' : 'var(--grey4)'};
    opacity: ${props => props.isTeam ? '.6' : '1'};

    .img-wrap {
      background-color: ${props => props.isTeam ? 'var(--purple1)' : 'var(--white)'};
    }
      img {
      width: 60%;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    margin-bottom: 48px;
    .team, .product {
      font-size: 22px;
    } 
  }
`;

const SwitchBtn = ({isTeam, setIsTeam}) => {

    const {t} = useTranslation();
    const [teamActive, setTeamActive] = useState(isTeam);
    useEffect(() => setTeamActive(isTeam), [isTeam])
    const {isRtl} = useDirection();

    return (
        <SwitchButton isTeam={isTeam} isRtl={isRtl}>
            <Link to="/services/team" className="team flex justify-center align-center">
                <div className="img-wrap">
                    <img src={isTeam ? frame32 : frame32w} alt=""/>
                </div>
                <span>{t("services.team")}</span>
            </Link>
            <Link to="/services/product" className="product flex justify-center align-center">
                <div className="img-wrap">
                    <img src={isTeam ? category : categoryA} alt=""/>
                </div>
                <span>{t("services.product")}</span>
            </Link>
            <span className={teamActive ? "active team" : "active"}/>
        </SwitchButton>

    )
}

export default SwitchBtn
