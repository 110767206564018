import Step1 from "../assets/img/InformationSystem/sub/Saleforce/step1.png";
import Step2 from "../assets/img/InformationSystem/sub/Saleforce/step2.png";
import Step3 from "../assets/img/InformationSystem/sub/Saleforce/step3.png";
import Step4 from "../assets/img/InformationSystem/sub/Saleforce/step4.png";

export const salesforceProcessSteps = {
    en: [
        {
            number: "Step 1",
            img: Step1,
            title: "Specification",
            description: "At this stage we will invest the maximum effort in defining the work processes, characterizing and learning the customer's needs. \n" +
                "As part of the process, a detailed characterization document will be built with descriptions and detailed flow charts that will allow you and us to understand what the required achievement of the process is. \n" +
                "We believe that with the help of a thorough characterization process we can ensure that the end result is exactly what your organization needs."
        },
        {
            number: "Step 2",
            img: Step2,
            title: "Development and implementation",
            description: "We will build weekly development sprints, each sprint contains a clear set of tasks aimed at achieving the goals we defined together in the characterization document. After each milestone - the project manager reviews the tasks and provides a regular update to receive general feedback. That way you can know that everything is going according to plan and meeting expectations."
        },
        {
            number: "Step 3",
            img: Step3,
            title: "Testing",
            description: "The testing period is 7-14 days, this period is designed to ensure that the system we built for you was best adapted to the needs of your company/organization. During this period - you can check every aspect of the management processes and update on any problem/change you want to solve before the final delivery."
        },
        {
            number: "Step 4",
            img: Step4,
            title: "Launch and support",
            description: "We did it! \n" +
                "In the final and final stage, we will implement the system for you in accordance with the unique work methods and the nature of the company's management, we will characterize your unique needs and enjoy the work process in the best and most efficient way for you."
        },
    ],
    he: [
        {
            img: Step1,
            "number": "שלב 1",
            "title": "איפיון",
            "description": "בשלב זה נשקיע את המקסימום בהגדרת תהליכי העבודה, אפיון ולמידה של צרכי הלקוח. במסגרת התהליך ייבנה מסמך אפיון מפורט עם תיאורים ותרשימי זרימה מפורטים שיאפשרו לך ולנו להבין מהו ההישג הנדרש של התהליך. אנו מאמינים כי בעזרת תהליך אפיון יסודי נוכל להבטיח כי התוצאה הסופית היא בדיוק מה שהארגון שלך צריך."
        },
        {
            img: Step2,
            "number": "שלב 2",
            "title": "פיתוח והטמעה",
            "description": "אנו נבנה ספרינטים לפיתוח שבועי, כל ספרינט מכיל סט ברור של משימות שמטרתן להשיג את המטרות שהגדרנו יחד במסמך האפיון. לאחר כל אבן דרך - מנהל הפרוייקט סוקר את הפעילויות ומספק עדכון קבוע לקבלת משוב כללי. כך תוכלו לדעת שהכל הולך לפי התוכנית ועומד בציפיות."
        },
        {
            img: Step3,
            "number": "שלב 3",
            "title": "בדיקות",
            "description": "תקופת הבדיקות היא 7-14 ימים, תקופה זו נועדה להבטיח שהמערכת שבנינו עבורך הותאמה בצורה הטובה ביותר לצרכים של החברה/הארגון שלך. במהלך תקופה זו - אתה יכול לבדוק כל היבט של תהליכי הניהול ולעדכן על כל בעיה / שינוי שאתה רוצה לפתור לפני המסירה הסופית."
        },
        {
            img: Step4,
            "number": "שלב 4",
            "title": "השקה ותמיכה",
            "description": "עשינו את זה! בשלב הסופי נבצע עבורכם את המערכת בהתאם לשיטות העבודה הייחודיות ולאופי הנהלת החברה, נאפיין את צרכיכם הייחודיים ונהנה מתהליך העבודה בצורה הטובה והיעילה ביותר עבורכם."
        },
    ],
    ru: [
        {
            number: "Шаг 1",
            img: Step1,
            title: "Технические характеристики",
            description: "На этом этапе мы вложим максимум усилий в определение рабочих процессов, характеристику и изучение потребностей клиента. \n" +
                "В рамках процесса будет создан документ с подробной характеристикой, с описаниями и подробными блок-схемами, которые позволят Вам и нам понять, в чем заключается требуемое достижение процесса. \n" +
                "Мы считаем, что с помощью тщательного процесса определения характеристик мы можем гарантировать, что конечный результат будет именно таким, который нужен Вашей организации."
        },
        {
            number: "Шаг 2",
            img: Step2,
            title: "Развитие и реализация",
            description: "Мы будем строить еженедельные спринты разработки, каждый спринт содержит четкий набор задач, направленных на достижение целей, которые мы определили вместе в документе характеристики. После каждого этапа менеджер проекта просматривает задачи и предоставляет регулярные обновления для получения общей обратной связи. Таким образом, Вы можете быть уверены, что все идет по плану и соответствует ожиданиям."
        },
        {
            number: "Шаг 3",
            img: Step3,
            title: "Тестирование",
            description: "Период тестирования составляет 7-14 дней, этот период предназначен для того, чтобы система, которую мы построили для Вас, была максимально адаптирована к потребностям Вашей компании/организации. В течение этого периода Вы можете проверить каждый аспект процессов управления и обновить информацию о любой проблеме / изменении, которое Вы хотите решить, до окончательной сдачи."
        },
        {
            number: "Шаг 4",
            img: Step4,
            title: "Запуск и поддержка",
            description: "Мы сделали это! \n" +
                "На заключительном и завершающем этапе мы внедрим для Вас систему в соответствии с уникальными методами работы и характером управления компанией, охарактеризуем Ваши уникальные потребности и получим удовольствие от рабочего процесса наилучшим и наиболее эффективным для Вас способом."
        },
    ]
};
