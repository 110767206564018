import WelcomeTop from "../WelcomeTop";
import React, {useEffect} from "react";
import Services from "../Services";
import Process from "../Process";
import Testimonials from "../../../components/Testimonials/Testimonials";
import ContactForm from "../../../components/ContactForm/ContactForm";
import useWindowSize from "../../../hooks/useWindowSize";
import {useParams} from "react-router-dom";
import Customers from "../Customers";
import SalesforceProducts from "./SalesforceProducts";


export default function MondaySalesforce() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const screenSize = useWindowSize().width;
  const handleScrollToElement = () => {
    window.scrollTo(0, (document.getElementById("contact").offsetTop)-185);
  }
  const {subpage} = useParams();
  return <div>
    <WelcomeTop handleScrollToElement={handleScrollToElement}/>
    <Services />
    {subpage ==="salesforce" && <SalesforceProducts />}
    <Process />
    <Testimonials />
    {screenSize > 500 &&  <Customers page="MondaySalesforce"/>}
    {screenSize > 500 && <ContactForm />}
  </div>
}