import React, {useEffect} from 'react';

import Careers from '../components/Careers/Careers';
import OurOffice from '../components/Careers/OurOffice';
import TeamCareers from '../components/Careers/TeamCareers';
import Technologies from '../components/Careers/Technologies';

const CareersPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById("root").style.overflowX = "hidden";
        return () => document.getElementById("root").style.overflowX = "unset";
    }, []);

    return (
        <main>
            <Careers />
            <Technologies />
            <TeamCareers />
            <OurOffice />
        </main>
    )
}

export default CareersPage
